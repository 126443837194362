import { FormGroup, AbstractControl } from "@angular/forms";

// To validate password and confirm password
export function Amountvalidate(
  controlName: string
) {
  return (formGroup: FormGroup) => {
    const control = formGroup.controls[controlName];
   
    if (control.errors && !control.errors.mustMatch) {
        return;
      }
    if (control.value % 100 !=0) {
        control.setErrors({ mustMatch: true });
    } else {
        control.setErrors(null);
    }
  };
}