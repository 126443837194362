import { Component, OnInit, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { SwiperOptions } from 'swiper';
import { Observable } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Router, ActivatedRoute } from '@angular/router';
import { DataService } from '../../services/user-data/data.service';
import { ConfirmationDialogService } from '../../services/ub-misc/confirmation-dialog/confirmation-dialog.service';
declare var $: any;

@Component({
  selector: 'app-service-request-list',
  templateUrl: './service-request-list.component.html',
  styleUrls: ['./service-request-list.component.css'],
})
export class ServiceRequestListComponent implements OnInit {
  // @Output() serviceDetailsViewEvent: EventEmitter<number> = new EventEmitter();
  // @ViewChild('requestDetails', { static: true }) public requestDetails: ElementRef;
  UBUrequestList: any;
  otherUBURequestList: any;
  myUBURequestList: any;
  requestTransactionDetails: any
  request_id: number;
  auth_user_id:number


  constructor(
    private dataService: DataService,
    private ngxUiLoaderService: NgxUiLoaderService,
    private toastrService: ToastrService,
    private router: Router,
    private confirmationDialogService: ConfirmationDialogService
  ) { }

  ngOnInit() { 
    this.auth_user_id = JSON.parse(localStorage.getItem('ubu_auth_user')).user_id;   
    this.getRequestList();
    this.getOtherRequestList();
  
  }

  getMyRequestList() {
    this.ngxUiLoaderService.start();
    const myRequestDataFilter = { "action": "self_opening",
                                  "page": 1,
                                  "per_page":100  };
    this.dataService.getRequestList(myRequestDataFilter)
      .subscribe(
        dataList => {
          this.myUBURequestList = dataList.data.requests;
          this.ngxUiLoaderService.stop();
        });
  }

  getRequestList() {
    this.ngxUiLoaderService.start();
    const requestDataFilter = { "action": "others_compatible",
                                "page": 1,
                                "per_page":100  };
    this.dataService.getRequestList(requestDataFilter)
      .subscribe(
        dataList => {
          this.UBUrequestList = dataList.data.requests;
          
          this.ngxUiLoaderService.stop();
        });
  }

  getOtherRequestList() {
    this.ngxUiLoaderService.start();
    const otherRequestDataFilter = { "action": "others" };
    this.dataService.getRequestList(otherRequestDataFilter)
      .subscribe(
        dataList => {
          this.otherUBURequestList = dataList.data.requests;
          this.ngxUiLoaderService.stop();
        });
  }

  getRequestTransactionDetails(request_id: number) {
    this.ngxUiLoaderService.start();
    const transactionFilter = { "request_id": request_id };
    this.dataService.getRequestTransaction(transactionFilter).subscribe(
      (filterDataList: any) => {
        if (filterDataList.data.length == 0) {
        }
        this.requestTransactionDetails = filterDataList.data;
        this.ngxUiLoaderService.stop();
      },
      (err) => {
        console.log(err)
        this.ngxUiLoaderService.stop();
      });
  }


  createBloodRequest() {
    $('#ubuBloodRequestFormModal').modal('show');
  }

  confirmAcceptBloodRequest(request_id: number){    
    this.acceptBloodRequest(request_id);
  /*  this.confirmationDialogService.confirm('Accept Blood Request', 'Are you sure you want to accept this blood request?','Confirm','Decline')
    .then((confirmed) => {if(confirmed){ this.acceptBloodRequest(request_id)}})
    .catch(() => console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)'));   */
  }
  /**
   * 
   * @param request_id 
   */
  acceptBloodRequest(request_id: number) {
    let _self =this;
    this.ngxUiLoaderService.start();
    const dataFilterAction = { "action": "donor_is_accept", "request_id": request_id }
    this.dataService.requestActionService(dataFilterAction).subscribe(
      response => {
        if (response.status_code == "200") {
          _self.getRequestList();
          this.ngxUiLoaderService.stop();
          this.toastrService.success('', response['success']['message']);
        }
        if (response.status_code == "201") {
          this.ngxUiLoaderService.stop();
          this.toastrService.info('', response['error']['message']);
        }

      }, error => {
        this.ngxUiLoaderService.stop();
        this.toastrService.info('', "Some thing went wrong");
      });

  }

  isDonorAcceptedRequest(acceptedDonors:any){
    let auth_user_id = JSON.parse(localStorage.getItem('ubu_auth_user')).user_id;
    if (!acceptedDonors.some((user_id:number) => user_id == auth_user_id)) {
      console.log(auth_user_id);
      return true;
    }else{
      return false;
    }

  }
  /**
   * 
   * @param request_id 
   */
  donorCancelBloodRequest(request_id: number){
    let _self = this;
    this.ngxUiLoaderService.start();
    let auth_user_id = JSON.parse(localStorage.getItem('ubu_auth_user')).user_id;
    const donorAction = {"action":"donor_is_cancelled","user_id":auth_user_id,"request_id":request_id,"reason":1,"comment":""} ;
    this.dataService.requestActionService(donorAction).subscribe(
      response => {
        if (response.status_code == "200") {
          _self.getRequestList();
          this.ngxUiLoaderService.stop();
          this.toastrService.success('', response['success']['message']);
        }
        if (response.status_code == "201") {
          this.ngxUiLoaderService.stop();
          this.toastrService.info('', response['error']['message']);
        }

      }, error => {
        this.ngxUiLoaderService.stop();
        this.toastrService.info('', "Some thing went wrong");
      });
    
  }

  declineBloodRequest(request_id: number) {

  }

  trackTransactionOnMap(request_id:number){
    this.router.navigate(["/request-map-view","OAR_"+request_id]);   
  }
  
  /**
   * 
   * @param request_id 
   */
  viewRequestDetails(request_id:number) {
   this.router.navigate(["/request-details",request_id]);   
  }

}
