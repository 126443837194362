import { Pipe, PipeTransform, LOCALE_ID, Inject, NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { SharedModule } from '../shared/shared.module';
import { AgmCoreModule } from '@agm/core';
import { AgmDirectionModule } from 'agm-direction';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { Ng2FlatpickrModule } from 'ng2-flatpickr';
import { UiSwitchModule } from 'ngx-toggle-switch';
import { NgbModule,NgbPaginationModule, NgbAlertModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxUsefulSwiperModule } from 'ngx-useful-swiper';
import { UserRoutingModule } from './user-routing.module';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { UserLayoutComponent } from './components/user-layout/user-layout.component';
import { LeftSidebarComponent } from './components/sidebar/left-sidebar/left-sidebar.component';
import { UserHeaderComponent } from './components/user-header/user-header.component';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { BloodDonationComponent } from './components/blood-donation/blood-donation.component';
import { ServiceMenuComponent } from './components/services/service-menu/service-menu.component';
import { ServiceRequestListComponent } from './components/service-request-list/service-request-list.component';
import { ReferADonorComponent } from './components/forms/refer-a-donor/refer-a-donor.component';
import { ServiceMapViewComponent } from './components/service-map-view/service-map-view.component';
import { ServiceFilterComponent } from './components/forms/service-filter/service-filter.component';
import { ServiceRequestComponent } from './components/forms/service-request/service-request.component';
import { MyServiceRequestListComponent } from './components/my-service-request-list/my-service-request-list.component';
import { ManageProfileComponent } from './components/manage-profile/manage-profile.component';
import { BasicInfoComponent } from './components/forms/profile/donor/basic-info/basic-info.component';
import { PersonalInfoComponent } from './components/forms/profile/donor/personal-info/personal-info.component';
import { LocationInfoComponent } from './components/forms/profile/donor/location-info/location-info.component';
import { PictureComponent } from './components/forms/profile/donor/picture/picture.component';
import { ReferAFriendComponent } from './components/refer-a-friend/refer-a-friend.component';
import { ServiceRequestViewComponent } from './components/service-request-view/service-request-view.component';
import { MapViewLayoutComponent } from './components/map-view-layout/map-view-layout.component';
import { ServiceRequestMapViewComponent } from './components/service-request-map-view/service-request-map-view.component';
import { RequestCancelFormComponent } from './components/forms/request-cancel-form/request-cancel-form.component';
import { NotificationComponent } from './components/notification/notification.component';
import { ConfirmationDialogComponent } from './components/misc/confirmation-dialog/confirmation-dialog.component';
import { ConfirmationDialogService } from './services/ub-misc/confirmation-dialog/confirmation-dialog.service';
import { ContactInfoComponent } from './components/forms/profile/donor/contact-info/contact-info.component';
import { HospitalListComponent } from './pages/services/hospital-list/hospital-list.component';
import { BloodbankListComponent } from './pages/services/bloodbank-list/bloodbank-list.component';
import { AmbulanceComponent } from './pages/services/ambulance/ambulance.component';
import { CowinLoginComponent } from './cowin/cowin-login/cowin-login.component';
import { LoginVerifyComponent } from './cowin/login-verify/login-verify.component';
import { RegisterVaccinationComponent } from './cowin/register-vaccination/register-vaccination.component';
import { AccountDetailsComponent } from './cowin/account-details/account-details.component';
import { BookAppointmentComponent } from './cowin/book-appointment/book-appointment.component';
import { AppointmentConfirmationComponent } from './cowin/appointment-confirmation/appointment-confirmation.component';
import { AppointmentSuccessfulComponent } from './cowin/appointment-successful/appointment-successful.component';
import { DontationsComponent } from './components/dontations/dontations.component';
//import { LayoutComponent } from '../shared/components/layout/layout.component';

@NgModule({
  declarations: [DashboardComponent, UserLayoutComponent, LeftSidebarComponent, UserHeaderComponent, PageNotFoundComponent, BloodDonationComponent, ServiceMenuComponent, ServiceRequestListComponent, ReferADonorComponent, ServiceMapViewComponent, ServiceFilterComponent, ServiceRequestComponent, MyServiceRequestListComponent, ManageProfileComponent, BasicInfoComponent, PersonalInfoComponent, LocationInfoComponent, PictureComponent, ReferAFriendComponent, ServiceRequestViewComponent, MapViewLayoutComponent, ServiceRequestMapViewComponent, RequestCancelFormComponent, NotificationComponent,ConfirmationDialogComponent, ContactInfoComponent, HospitalListComponent, BloodbankListComponent, AmbulanceComponent, CowinLoginComponent, LoginVerifyComponent, RegisterVaccinationComponent, AccountDetailsComponent, BookAppointmentComponent, AppointmentConfirmationComponent, AppointmentSuccessfulComponent, DontationsComponent],
  imports: [
    CommonModule,
    SharedModule,
    UserRoutingModule,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    UiSwitchModule,
    Ng2FlatpickrModule,
    NgbModule,
    NgxUsefulSwiperModule,
    NgbPaginationModule,
    NgbAlertModule, 
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyDfUl7G2CIfkJdCRwakYUQeen2o5cCzcVE',
      libraries: ['places', 'geometry']
    }),
    AgmDirectionModule
  ],
  providers: [
    DatePipe,
    ConfirmationDialogService
  ],
  entryComponents: [ ConfirmationDialogComponent ],
})
export class UserModule { }
