import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';

const routes: Routes = [
//  { path: 'page-not-found', component: PageNotFoundComponent },
 // { path: '**',  redirectTo: '/page-not-found',  pathMatch: 'full'}
];


@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: false,enableTracing: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
