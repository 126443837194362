import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { SwiperOptions } from 'swiper';
import { Observable } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Routes, Router, RouterModule, ActivatedRoute } from '@angular/router';
import { DataService } from '../../services/user-data/data.service';
import { DatePipe } from '@angular/common';
declare var $: any;


@Component({
  selector: 'app-service-request-view',
  templateUrl: './service-request-view.component.html',
  styleUrls: ['./service-request-view.component.css']
})
export class ServiceRequestViewComponent implements OnInit {
  @Input() request_id: number
  auth_user_id: number
  requestDetails: any;
  previousUrl: string;
  auth_user: any;
  fitBounds: boolean = false;
  is_donated: boolean;
  public origin: any;
  public destination: any;
  public renderOptions: any;
  public markerOptions: any
  public directionData: any = [];
  public donatedDonors: any = [];
  requestUserData: any;
  acceptedDonorsData: any
  requestViewSourceNavBtn: boolean;

  transactionDonorsList: any = [];
  constructor(
    private dataService: DataService,
    private toastrService: ToastrService,
    private ngxUiLoaderService: NgxUiLoaderService,
    private router: Router,
    private route: ActivatedRoute,
    public datepipe: DatePipe
  ) { }

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.ngxUiLoaderService.start();
      this.getRequestDetails(params.requestId);
      this.getDonorTransactionDetails(params.requestId);
      this.getRequestTransactionDetails(params.requestId);
      this.ngxUiLoaderService.stop();
    });
    this.auth_user_id = JSON.parse(localStorage.getItem('ubu_auth_user')).user_id;
    console.log(this.auth_user_id);
    this.auth_user = JSON.parse(localStorage.getItem('ubu_auth_user'));
  }



  getRequestDetails(request_id: number) {
    this.ngxUiLoaderService.start();
    const otherRequestDataFilter = { "request_id": request_id };
    this.dataService.getRequestDetails(otherRequestDataFilter)
      .subscribe(
        dataList => {
          this.requestDetails = dataList.data;
          this.donatedDonors = dataList.data.donated_donors;
          // console.log(this.requestDetails);
          let auth_user_id = JSON.parse(localStorage.getItem('ubu_auth_user')).user_id;
          this.is_donated = this.donatedDonors.includes(auth_user_id);
          // console.log(this.is_donated);
          $('#manageMyServiceRequest').modal('show');
          this.ngxUiLoaderService.stop();
        });

  }

  getDonorTransactionDetails(request_id: number) {
    console.log(request_id);
    this.ngxUiLoaderService.start();
    const transactionFilter = { "request_id": request_id };
    this.dataService.getDonorTransaction(transactionFilter).subscribe(
      (donorTransactionData: any) => {
        if (donorTransactionData.data.length > 0) {
          //  console.log(donorTransactionData);
          this.transactionDonorsList = donorTransactionData.data[0].accepted_donors;
          console.log(this.transactionDonorsList);
          this.requestUserData = donorTransactionData.data[0];
          this.acceptedDonorsData = this.requestUserData.accepted_donors;
          this.origin = { lat: this.requestUserData.latitude, lng: this.requestUserData.longitude };
          this.renderOptions = { polylineOptions: { strokeColor: '#f00' }, suppressMarkers: true };
          if (this.acceptedDonorsData.length > 0) {
            this.acceptedDonorsData.forEach((donorData, index) => {
              if (donorData.user_id == this.auth_user_id) {

                let donordirectionData = [];
                donordirectionData['destination'] = { lat: donorData.latitude, lng: donorData.longitude };
                donordirectionData['markerOptions'] = {
                  origin: {
                    infoWindow: '<div class="ub-info-window-wrapper"><div class="donor-profile-content"><div class="donor-info-window-pic"><img src="' + this.requestUserData.profile_pic + '" class="info-window-profile-pic" alt="" width="50">' +
                      '</div><div class="donor-info-window-data"><div class="infowin-ub-name"><i class="fa fa-user" aria-hidden="true"></i><span style="font-size: 15px;margin-bottom:5px;padding-left: 6px;font-weight:500">' + this.requestUserData.contact_name + '</span></div><div class="infowin-ub-name  phone-icon"><a href="tel:' + this.requestUserData.contact_number + '">' + this.requestUserData.contact_number + '</a></div><div class="infowin-ub-name  address-icon text-ellipsis">' + this.requestUserData.address + '</div></div></div></div>',
                    icon: this.requestUserData.ublood_icon,
                    draggable: false,
                  },
                  destination: {
                    infoWindow: '<div class="ub-info-window-wrapper"><div class="donor-profile-content"><div class="donor-info-window-pic"><img src="' + donorData.profile_pic + '" class="info-window-profile-pic"  alt="" width="50">' +
                      '</div><div class="donor-info-window-data"><div class="infowin-ub-name"><i class="fa fa-user" aria-hidden="true"></i><span style="font-size: 15px;margin-bottom: 5px;padding-left: 6px;font-weight:500;">' + donorData.name + '</span></div><div class="infowin-ub-name  phone-icon"><a href="tel:' + donorData.phone + '">' + donorData.phone + '</a></div><div class="infowin-ub-name  address-icon text-ellipsis">' + donorData.address + '</div></div></div></div>',
                    icon: donorData.ublood_icon,
                    draggable: false,
                  },
                }
                this.directionData.push(donordirectionData);

              }

            })
          }
          this.requestViewSourceNavBtn = false;
        }
        this.ngxUiLoaderService.stop();
      },
      (err) => {
        console.log(err)
        this.ngxUiLoaderService.stop();
      });
  }

  getRequestTransactionDetails(request_id: number) {
    this.ngxUiLoaderService.start();
    const transactionFilter = { "request_id": request_id };
    this.dataService.getRequestTransaction(transactionFilter).subscribe(
      (requestTransactionData: any) => {
        if (requestTransactionData.data.length > 0) {
          this.transactionDonorsList = requestTransactionData.data[0].accepted_donors;
          this.requestUserData = requestTransactionData.data[0];
          this.acceptedDonorsData = this.requestUserData.accepted_donors;
          this.origin = { lat: this.requestUserData.latitude, lng: this.requestUserData.longitude };
          this.renderOptions = { polylineOptions: { strokeColor: '#f00' }, suppressMarkers: true };
          if (this.acceptedDonorsData.length > 0) {
            this.acceptedDonorsData.forEach((donorData, index) => {
              let donordirectionData = [];
              donordirectionData['destination'] = { lat: donorData.latitude, lng: donorData.longitude };
              donordirectionData['markerOptions'] = {
                origin: {
                  infoWindow: '<div class="ub-info-window-wrapper"><div class="donor-profile-content"><div class="donor-info-window-pic"><img src="' + this.requestUserData.profile_pic + '" class="info-window-profile-pic" alt="" width="50">' +
                    '</div><div class="donor-info-window-data"><div class="infowin-ub-name"><i class="fa fa-user" aria-hidden="true"></i><span style="font-size: 15px;margin-bottom:5px;padding-left: 6px;font-weight:500">' + this.requestUserData.contact_name + '</span></div><div class="infowin-ub-name  phone-icon"><a href="tel:' + this.requestUserData.contact_number + '">' + this.requestUserData.contact_number + '</a></div><div class="infowin-ub-name  address-icon text-ellipsis">' + this.requestUserData.address + '</div></div></div></div>',
                  icon: this.requestUserData.ublood_icon,
                  draggable: false,
                },
                destination: {
                  infoWindow: '<div class="ub-info-window-wrapper"><div class="donor-profile-content"><div class="donor-info-window-pic"><img src="' + donorData.profile_pic + '" class="info-window-profile-pic"  alt="" width="50">' +
                    '</div><div class="donor-info-window-data"><div class="infowin-ub-name"><i class="fa fa-user" aria-hidden="true"></i><span style="font-size: 15px;margin-bottom: 5px;padding-left: 6px;font-weight:500;">' + donorData.name + '</span></div><div class="infowin-ub-name  phone-icon"><a href="tel:' + donorData.phone + '">' + donorData.phone + '</a></div><div class="infowin-ub-name  address-icon text-ellipsis">' + donorData.address + '</div></div></div></div>',
                  icon: donorData.ublood_icon,
                  draggable: false,
                },
              }
              this.directionData.push(donordirectionData);
            })
          }
          this.requestViewSourceNavBtn = true;
        }
        this.ngxUiLoaderService.stop();
      },
      (err) => {
        console.log(err)
        this.ngxUiLoaderService.stop();
      });
  }

  createBloodRequest() {
    $('#ubuBloodRequestFormModal').modal('show');
  }

  /**
  * 
  * @param request_id 
  */
  acceptBloodRequest(request_id: number) {
    let _self = this;
    this.ngxUiLoaderService.start();
    const dataFilterAction = { "action": "donor_is_accept", "request_id": request_id }
    console.log(dataFilterAction);
    this.dataService.requestActionService(dataFilterAction).subscribe(
      response => {
        if (response.status_code == "200") {
          _self.getRequestDetails(request_id);
          this.ngxUiLoaderService.stop();
          this.toastrService.success('', response['success']['message']);
        }
        if (response.status_code == "201") {
          this.ngxUiLoaderService.stop();
          this.toastrService.info('', response['error']['message']);
        }

      }, error => {
        this.ngxUiLoaderService.stop();
        this.toastrService.info('', "Some thing went wrong");
      });

  }

  /**
  * 
  * @param req_id 
  */
  DonateBloodRequest(req_id: number) {
    let _self = this;
    let auth_user_id = JSON.parse(localStorage.getItem('ubu_auth_user')).user_id;
    this.ngxUiLoaderService.start();
    var todayDate = new Date();
    //console.log(todayDate);
    var toDate = this.datepipe.transform(todayDate, 'yyyy-MM-dd')
    //console.log(toDate);
    //console.log(this.requestDetails.required_date);
    let newDate = new Date(toDate);
    let lastDate = new Date(this.requestDetails.required_date);
    var diff = Math.abs(lastDate.getTime() - newDate.getTime());
    var diffDays = Math.ceil(diff / (1000 * 3600 * 24));
    // return diffDays;
    // console.log(diffDays); 
    //  return false;

    // if(diffDays>0){
    //   this.toastrService.info('', "Update the donation status after blood requested date");
    //   this.ngxUiLoaderService.stop();
    //   return false;
    // }
    const dataFilterAction = { "action": "donor_is_donate", "user_id": auth_user_id, "request_id": req_id, "status": 1 }

    //console.log(dataFilterAction);
    this.dataService.requestActionService(dataFilterAction).subscribe(
      response => {
        if (response.status_code == "200") {
          _self.getRequestDetails(req_id);
          console.log();
          this.ngxUiLoaderService.stop();
          this.toastrService.success('', response['success']['message']);
        }
        if (response.status_code == "201") {
          this.ngxUiLoaderService.stop();
          this.toastrService.info('', response['error']['message']);
        }

      }, error => {
        this.ngxUiLoaderService.stop();
        this.toastrService.info('', "Some thing went wrong");
      });

  }

  isDonorAcceptedRequest(acceptedDonors: any) {
    let auth_user_id = JSON.parse(localStorage.getItem('ubu_auth_user')).user_id;
    if (!acceptedDonors.some((user_id: number) => user_id == auth_user_id)) {
      console.log(auth_user_id);
      return true;
    } else {
      return false;
    }

  }
  /**
   * 
   * @param request_id 
   */
  donorCancelBloodRequest(request_id: number) {
    let _self = this;
    this.ngxUiLoaderService.start();
    let auth_user_id = JSON.parse(localStorage.getItem('ubu_auth_user')).user_id;
    const donorAction = { "action": "donor_is_cancelled", "user_id": auth_user_id, "request_id": request_id, "reason": 1, "comment": "" };
    this.dataService.requestActionService(donorAction).subscribe(
      response => {
        if (response.status_code == "200") {
          _self.getRequestDetails(request_id);
          this.ngxUiLoaderService.stop();
          this.toastrService.success('', response['success']['message']);
        }
        if (response.status_code == "201") {
          this.ngxUiLoaderService.stop();
          this.toastrService.info('', response['error']['message']);
        }

      }, error => {
        this.ngxUiLoaderService.stop();
        this.toastrService.info('', "Some thing went wrong");
      });

  }

  mapReady(event: any) {

  }

  backToSourcePage() {
    /*if(this.dataService.getPreviousUrl()){
      this.previousUrl = this.dataService.getPreviousUrl();
      this.router.navigate([this.previousUrl]);
    }*/
    if (this.requestViewSourceNavBtn) {
      this.router.navigate(["/my-request-list"]);
    } else {
      this.router.navigate(["/request-list"]);
    }

  }



}
