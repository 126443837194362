import { Component, OnInit, Input, Output, ViewChild, OnChanges, SimpleChanges, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { BehaviorSubject, interval, of, timer } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
// import { Ubrequest } from '../../models/request.model';
// import { AuthenticationService } from '../../services/authentication/authentication.service';

import { Ubrequest } from '../../../../app/shared/models/request.model';
import { AuthenticationService } from '../../../../app/shared/services/authentication/authentication.service';

declare var $: any;

@Component({
  selector: 'app-login-verify',
  templateUrl: './login-verify.component.html',
  styleUrls: ['./login-verify.component.css']
})
export class LoginVerifyComponent implements OnChanges {

  @Input() phone_number: number;
  @Input() otp_timer_start: boolean;
  @Input() request_data: Ubrequest;
  @Output() numberChanged: EventEmitter<boolean> = new EventEmitter();
  @ViewChild('ngOtpInput', { static: true }) ngOtpInput: any;
  ubOTPForm: FormGroup;
  isOTPFormSubmitted: boolean = false;
  verified_user: number = 0;
  isOTPtimerOn: boolean = false;
  isResendBtnEnabled: boolean = false;
  otpTimeCountinSec: number = 50;
  maskPhoneNumber: any;

  otpConfig = {
    allowNumbersOnly: true,
    length: 4,
    isPasswordInput: false,
    disableAutoFocus: false,
    placeholder: '*',
    inputStyles: {
      'width': '50px',
      'height': '40px',
      'display': 'inline',
      'margin-right': '10px'
    }
  };

  constructor(
    private formBuilder: FormBuilder,
    private authentication: AuthenticationService,
    private toastrService: ToastrService,
    private ngxUIService: NgxUiLoaderService,
    private router: Router
  ) {
    this.ubOTPForm = this.formBuilder.group({
      uba_otp_number: ['', [Validators.required, Validators.pattern(/\d{4}/)]],
    });

  }

  ngOnChanges(timeOptionStart: SimpleChanges) {
    if (this.otp_timer_start) {
      if (this.request_data) {
        this.maskPhoneNumber = this.request_data.request_phone;
        this.maskPhoneNumber = this.maskPhoneNumber.substring(0, 2) + 'xxxxx' + this.maskPhoneNumber.substring(this.maskPhoneNumber.length - 3);
      }

      this.isOTPtimerOn = true;
      this.otpTimerOn(this.otpTimeCountinSec);

    }

  }

  get ubOTPFormControl() { return this.ubOTPForm.controls; }

  /**
   * 
   * @param post 
   */
  verifyUser(post: any) {
    this.isOTPFormSubmitted = true;
    if (this.ubOTPForm.invalid) {
      this.toastrService.info('', "Please enter valid OTP",{
        tapToDismiss: false,
        positionClass:'toast-top-right',
      
      });
      return false;
    }
    if (post) {
      const authUserData = {
        "action": "verify",
        "phone": this.request_data.request_phone,
        "otp": post['uba_otp_number']        
      };
      this.ngxUIService.start();
      this.authentication.ubuAuthenticate(authUserData)
        .subscribe(
          response => {
            if (response['status_code'] == 200) {
              localStorage.setItem("ubu_auth_token", response['data']['access_token']);
              localStorage.setItem("ubu_auth_user", JSON.stringify(response['data']));                    
              $('#otpModalForm').modal('hide');
              this.ngxUIService.stop();
              this.router.navigate(["/dashboard"]);

           /*   this.toastrService.success('', response['success']['message']);
              this.request_data.request_user = response['data']['user_id'];
              this.ngxUIService.stop();
              
              if (response['is_plasma_donor']) {
                localStorage.setItem("ub-c19-plasma", response['is_plasma_donor']);
                localStorage.setItem("ub-c19-plasma-donor", response['is_plasma_donor']);
                localStorage.setItem("ub-c19-userid", response['data']['user_id']);
                this.router.navigate(["/covid/donor"]);
              }*/

            }
            if (response['status_code'] == 201) {
              this.ngxUIService.stop();
              this.toastrService.info('', response['error']['message'],{
                tapToDismiss: false,
                positionClass:'toast-top-right',
              
              });
            }

          }, error => {
            this.ngxUIService.stop();
            this.toastrService.info('', "Some thing went wrong",{
              tapToDismiss: false,
              positionClass:'toast-top-right',
            
            });
          });
    } else {
      this.ngxUIService.stop();
      this.toastrService.info('', "Please enter mobile number",{
        tapToDismiss: false,
        positionClass:'toast-top-right',
      
      });
    }

  }

  /**
   * 
   */
  resendOTP() {
    this.otp_timer_start = true;
    this.isOTPtimerOn = true;
    this.ngOtpInput.setValue('');
    const authUserData = {
      "action": "sendotp",
      "std_code": "+91",
      "phone": this.request_data.request_phone,
      "is_plasma_donor": true,
    };
    this.ngxUIService.start();
    this.authentication.ubuAuthenticate(authUserData)
      .subscribe(
        response => {
          if (response['status_code'] == 200) {
            this.ngxUIService.stop();
            this.otpTimerOn(this.otpTimeCountinSec);
            this.toastrService.success('', response['success']['message'],{
              tapToDismiss: false,
              positionClass:'toast-top-right',
            
            });
          }
        }, error => {
          this.ngxUIService.stop();
          this.toastrService.info('', "Some thing went wrong",{
            tapToDismiss: false,
            positionClass:'toast-top-right',
          
          });
        });
  }

  /**
   * 
   * @param timeLeft 
   */
  otpTimerOn(timeLeft: number) {
    let minute: any = Math.floor(timeLeft / 60);
    let second: any = timeLeft % 60;
    let _self = this;
    minute = minute < 10 ? '0' + minute : minute;
    second = second < 10 ? '0' + second : second;
    $('#otp_timer').html(minute + ':' + second);
    timeLeft -= 1;

    if (timeLeft >= 0 && this.isOTPtimerOn) {
      this.isResendBtnEnabled = false;
      setTimeout(function () {
        _self.otpTimerOn(timeLeft);
      }, 1000);
      return;
    } else {
      this.isResendBtnEnabled = true;
      $('#otp_timer').html('');
    }

    if (!this.isOTPtimerOn) {
      // Do validate stuff here
      return;
    }


  }
  /**
   * 
   * @param event 
   */
  isValidOtp(event: any) {
    this.ubOTPForm.controls['uba_otp_number'].setValue(event);
    /* const charCode = (event.which) ? event.which : event.keyCode;
     if (charCode > 31 && (charCode < 48 || charCode > 57)) {
       return false;
     }
     return true;*/

  }

  changeNumber() {
    this.otp_timer_start = false;
    this.numberChanged.emit(this.otp_timer_start);
  }


}
