import { Component, OnInit, ViewChild, ElementRef, NgZone, Output, Input } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { MapsAPILoader, GoogleMapsAPIWrapper, AgmMap, LatLngBounds, LatLngBoundsLiteral } from '@agm/core';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { BehaviorSubject } from 'rxjs';
import { DataService } from '../../services/dataService/data.service';
import { DatePipe } from '@angular/common'
import { FlatpickrOptions } from 'ng2-flatpickr';
import { Ubrequest } from '../../models/request.model';
import { AuthenticationService } from '../../services/authentication/authentication.service';
declare var $: any;

@Component({
  selector: 'app-covid-plasma',
  templateUrl: './covid-plasma.component.html',
  styleUrls: ['./covid-plasma.component.css']
})
export class CovidPlasmaComponent implements OnInit {
  @Input() request_data: Ubrequest;
  ubPlasmDonorForm: FormGroup;
  ubPlasmaRequestForm: FormGroup;
  isAuthFormSubmitted: boolean = false;
  phone_number: number;
  otp_timer_start: boolean = false;
  isPlasmaDonorSubmit:boolean=false;
  isPlasmaRequestSubmit: boolean=false;
  bloodGroupDataList:any=[];
  @ViewChild('ubd_location', { static: true }) public searchElementRef: ElementRef;
  @ViewChild('ubr_location', { static: true }) public searchRequestElement: ElementRef;
  map: google.maps.Map;
  fitBounds: boolean = false;
  dlatitude: number;
  dlongitude: number;
  rlatitude: number;
  rlongitude: number;
  Donorlocation:any={}
  DonorCity:string;
  DonorState:string;
  DonorCountry:string;
  DonorZip:string;
  Requestlocation: string;
  rcity :string;
  rstate:string;
  //public makeDate = new Date();
  dateTimeFormatConfig: any={ }
  dateTimeFormatConfigAge:any ={ }
  public imgPath;
  imgURL:any;
  //makeDate.setMonth(makeDate.getMonth() - 1);
  // dateTimeFormatConfig: FlatpickrOptions = {
  //   mode:'single',
  //   enableTime: false,
  // //  dateFormat: "Y-m-d H:i",
  //   dateFormat: "Y-m-d",
  //   minDate: new Date()
  // };
  private geoCoder;
  constructor( 
    private formBuilder: FormBuilder,
    private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone,
    private ngZoneR: NgZone,
    private router : Router,
    private dataService: DataService,
    private toastrService: ToastrService,
    private ngxUiLoaderService: NgxUiLoaderService,
    private datePipe: DatePipe,
    private auth : AuthenticationService) { 

    }

  ngOnInit() {

    this.addPlasmaDonorForm();
    this.addPlasmaRequestForm();
    this.getBloodGroups();
    var makeDate = new Date();
    makeDate.setFullYear(makeDate.getFullYear() - 18);
    var newDate=  makeDate;
   // console.log(makeDate);
    this.dateTimeFormatConfigAge = {
      mode:'single',
      enableTime: false,
    //  dateFormat: "Y-m-d H:i",
      dateFormat: "Y-m-d",
      maxDate: newDate
    };
    this.dateTimeFormatConfig = {
      mode:'single',
      enableTime: false,
    //  dateFormat: "Y-m-d H:i",
      dateFormat: "Y-m-d",
     // minDate: new Date()
    }
    this.mapsAPILoader.load().then(() => {
      this.geoCoder = new google.maps.Geocoder;
      let autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement);
      let autocomplete1 = new google.maps.places.Autocomplete(this.searchRequestElement.nativeElement);
      autocomplete.addListener("place_changed", () => {
        this.ngZone.run(() => {
          //get the place result
          let place: google.maps.places.PlaceResult = autocomplete.getPlace();
          if (place.geometry === undefined || place.geometry === null) {
            return;
          }
          //set latitude, longitude and zoom
          this.Donorlocation = place.name;
          this.dlatitude = place.geometry.location.lat();
          this.dlongitude = place.geometry.location.lng();
        });
      });
      autocomplete1.addListener("place_changed", () => {

        this.ngZoneR.run(() => {
          let placeR: google.maps.places.PlaceResult = autocomplete1.getPlace();
          if (placeR.geometry === undefined || placeR.geometry === null) {
            return;
          }
          this.Requestlocation = placeR.name;
        //  console.log(this.Requestlocation);
          this.rlatitude = placeR.geometry.location.lat();
          this.rlongitude = placeR.geometry.location.lng();
          //console.log(this.rlongitude);
       });
      });
      
    });

  }

  /***
   * 
   */
  addPlasmaDonorForm() {
    this.ubPlasmDonorForm = this.formBuilder.group({
      ubd_positive_datetime: ['', [Validators.required]],
      ubd_negative_datetime : ['', [Validators.required]],
      ubd_name: ['', [Validators.required]],
      ubd_lname:['', [Validators.required]],
      ubd_email: ['', [
        Validators.required,
        Validators.email,
        Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$')]],
      ubd_age: ['', [Validators.required]],
      isCheck: ['1'],
      ubd_blood_group: ['', [Validators.required]],
      ubd_location: ['', [Validators.required]]
    });
  }

  addPlasmaRequestForm() {
    this.ubPlasmaRequestForm = this.formBuilder.group({
      ubr_name: ['', [Validators.required]],
      ubr_attend_name: ['', [Validators.required]],
      ubr_attend_mobile: ['', [Validators.required]],
      ubr_blood_group: ['', [Validators.required]],
      ubr_request_age : ['', [Validators.required]],
      ubr_request_date: ['', [Validators.required]],
      ubr_donate_loc : ['', [Validators.required]],
      ubr_request_doc : ['', [Validators.required]],
      ubr_location: ['', [Validators.required]]
      
    });
  }

  getBloodGroups(){
    return this.bloodGroupDataList = [
      { id: 1, name: 'A+' },
      { id: 2, name: 'A-' },
      { id: 3, name: 'B+' },
      { id: 4, name: 'B-' },
      { id: 5, name: 'AB+' },
      { id: 6, name: 'AB-' },
      { id: 7, name: 'O+' },
      { id: 8, name: 'O-' },
      { id: 9, name: 'A1+' },
      { id: 10, name: 'A1-' },
      { id: 11, name: 'A2+' },
      { id: 12, name: 'A2-' },
      { id: 13, name: 'A1B+' },
      { id: 14, name: 'A1B-' },
      { id: 15, name: 'A2B+' },
      { id: 16, name: 'A2B-' },
      { id: 17, name: 'Bombay Blood Group' },
      { id: 18, name: 'INRA' }
    ];
  
  }

  docForm(event) {
    let files = event.target.files;
   // console.log(files); //return false;
   // console.log(files.file.name); return false;
    if (files.length === 0){
     return;
     }
    var sFileName =files[0].name;
    var sFileExtension = sFileName.split('.')[sFileName.split('.').length - 1].toLowerCase();
    var iFileSize = files[0].size;
    var iConvert = (iFileSize / 1048576).toFixed(2);
    if (!(sFileExtension === "png" ||
        sFileExtension === "jpeg" ||
        sFileExtension === "jpg") || iFileSize > 1048576) { /// 1 mb
        var txt = "File type : " + sFileExtension + "\n\n";
        txt += "Size: " + iConvert + " MB \n\n";
        txt += "Please make sure your file is in image format and less than 1 MB.\n\n";
         this.toastrService.warning('',txt);
      
    }else{
      var reader = new FileReader();
      this.imgPath = files;
      reader.readAsDataURL(files[0]); 
      reader.onload = (_event) => { 
      this.imgURL = reader.result; 
    //  console.log(this.imgURL); 
    }
   
   }
  }
  
  get ubrFormControl() { return this.ubPlasmaRequestForm.controls;}

  covidPlasmaRequest(postRequest:any) {
    this.isPlasmaRequestSubmit=true;
    if (this.ubPlasmaRequestForm.invalid) {
      console.log("invalid");
      return false;
    }
    //console.log(postRequest);
    if(this.imgURL == undefined){
      this.imgURL='';
    }
    const plasmaRequest = {
        action:"create_service_request",
        user_id: 358,
        is_request_for_other:0,
        service_type: 3,
        blood_group: postRequest["ubr_blood_group"],
        contact_name: postRequest["ubr_attend_name"],
        contact_number: postRequest["ubr_attend_mobile"],
        units : 1,
        required_date:this.datePipe.transform(postRequest["ubr_request_date"][0], "yyyy-MM-dd hh:mm"),
        location_for_donation: postRequest["ubr_donate_loc"],
        address: this.Requestlocation,
        latitude: this.rlatitude,
        longitude: this.rlongitude,
        is_emergency:true,
        is_share_on_sm:true,
        doctor_requisition_form: this.imgURL,
        patient_name:postRequest["ubr_name"],
        patient_age: this.datePipe.transform(postRequest["ubr_request_age"][0], "yyyy-MM-dd hh:mm"), 
        is_plasma_request:1,
        city:"",
        state:"",
        country:"",
        zipcode:"",
        comment:""
      }
     //console.log(plasmaRequest); //return false;
      this.dataService.createRequest(plasmaRequest).subscribe(
        response => {
          if (response['status_code'] == 200) {
          //  _self.sendNotification(response['request_id'], _self.request_data.request_user);
            //$('#ubuBloodRequestFormModal').modal('hide');
            this.toastrService.success('', response['success']['message']);            
            this.ngxUiLoaderService.stop();
         //   this.router.navigate(["/donor-map-view"]);
          }
          if (response['status_code'] == 201) {
            this.ngxUiLoaderService.stop();
            this.toastrService.info('', response['error']['message']);
          }
        }, error => {
          this.ngxUiLoaderService.stop();
          this.toastrService.info('', "Some thing went wrong");
        });

  }
  /**
   * 
   */

  get ubdFormControl() { 
    
    const invalid = [];
    const controls = this.ubPlasmDonorForm.controls;
    for (const name in controls) {
         //console.log("controler"+name);
    }
    // return invalid;
    return this.ubPlasmDonorForm.controls;
   }

  covidPlasmaDonor(postDonor: any) {
    this.isPlasmaDonorSubmit=true;
    if (this.ubPlasmDonorForm.invalid) {
      console.log("invalid data");
      return false;
    }
   // console.log(postDonor); 
    if ($("#ubpd_termsAndCondition").prop("checked") !== true) {
      this.toastrService.info("Please accept the terms and Conditions");
      return false;
    }
    this.ngxUiLoaderService.start();
    if (postDonor) {
     const donorData = {
      action:"donor",
      user_id:358,
      first_name: postDonor["ubd_name"],
      last_name:postDonor["ubd_lname"],
      email: postDonor["ubd_email"],
      blood_group: postDonor["ubd_blood_group"],
      address: this.Donorlocation,
      locality:this.Donorlocation,
      latitude:this.dlatitude,
      longitude:this.dlongitude,
      age: this.datePipe.transform(postDonor["ubd_age"][0], "yyyy-MM-dd"),
      is_plasma_donor:1,
      last_covid_positive_test_date: this.datePipe.transform(postDonor["ubd_positive_datetime"][0], "yyyy-MM-dd"),
      date_of_recovery: this.datePipe.transform(postDonor["ubd_negative_datetime"][0], "yyyy-MM-dd"),
      have_covid_symptoms: postDonor["isCheck"]
    }
   // console.log(donorData); return false;
      this.auth.ubuAuthenticate(donorData).subscribe(
        response => { 
          if (response['status_code'] == 200) {
          //  _self.sendNotification(response['request_id'], _self.request_data.request_user);
           // $('#ubuBloodRequestFormModal').modal('hide');
            this.toastrService.success('', response['success']['message']);            
            this.ngxUiLoaderService.stop();
            this.router.navigate(["/donor-map-view"]);
          }
          if (response['status_code'] == 201) {
            this.ngxUiLoaderService.stop();
            this.toastrService.info('', response['error']['message']);
          }
        }, error => {
          this.ngxUiLoaderService.stop();
          this.toastrService.info('', "Some thing went wrong");
        });
    }
    this.ngxUiLoaderService.stop();


  }

}
