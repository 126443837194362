import { Component, OnInit } from '@angular/core';
import { DataService } from '../../services/dataService/data.service';
import { Observable } from 'rxjs';
import { AppApiEndpoints } from 'src/app/app.api-endpoints';
import { AppSettings } from 'src/app/app.settings';
import { HttpClient } from '@angular/common/http';

interface State {
  name: string;
  percentage: string;
}

@Component({
  selector: 'app-india-map',
  templateUrl: './india-map.component.html',
  styleUrls: ['./india-map.component.css']
})
export class IndiaMapComponent implements OnInit {

  top5States: State[] = [];
  states: any;
  apiUrl: string;

  constructor(private http: HttpClient,private dataService: DataService, private appSettings: AppSettings,private appapiEndpoint: AppApiEndpoints) { 
    this.apiUrl = appSettings.API_NAMESPACE;
  }

  ngOnInit(): void {
    this.getStateList1();
  }


 

   getStateList1(): void {
    this.dataService.getMetStateList1().subscribe(
      data => {
        console.log(data.data.states_wise_donors)
     
        this.states = data.data.states_wise_donors;
        // if (data.data.status_code === 200) {
        //   this.states = data.data.states_wise_donors;
        //   console.log(this.states)
        // }
      },
      error => {
        console.error('Error fetching donors data', error);
      }
    );

  //     console.log('Fetched data 123');
  //     debugger;
  //   this.dataService.getMetStateList1().subscribe(
  //      (data: any) => {
       
  //       console.log('Fetched data:', data.states_wise_donors.state); // Log the entire data object
  //       if (data && Array.isArray(data.states)) {
  //         this.states = data.states; // Use data.states if it's an array of states
  //         this.getTop5States();
  //         console.log('Fetched States:', this.states); // Log the fetched states to the console
  //       } else {
  //         console.error('Invalid data format:', data);
  //       }
  //     },
  //     error => {
  //       console.error('Error fetching states', error);
  //     }
  //   );
    }
  
  

  getTop5States(): void {
    // Ensure percentage is treated as a number for accurate sorting
    this.states.sort((a, b) => parseFloat(b.percentage) - parseFloat(a.percentage));
    this.top5States = this.states.slice(0, 5);
    console.log('Top 5 States:', this.top5States); // Log the top 5 states to the console
  }
}
