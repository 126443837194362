import { NgModule } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule } from '@angular/forms';
import { Ng2FlatpickrModule } from 'ng2-flatpickr';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { SharedRoutingModule } from './shared-routing.module';
import { AgmCoreModule } from '@agm/core';
import { UiCarouselModule } from 'ngx-ui-carousel';
import { LandingPageComponent } from './components/landing-page/landing-page.component';
import { LayoutComponent } from './components/layout/layout.component';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { FooterComponent } from './components/footer/footer.component';
import { HeaderComponent } from './components/header/header.component';
import { MarkerInfoWindowComponent } from './components/map/marker-info-window/marker-info-window.component';
import { MapMarkerComponent } from './components/map/map-marker/map-marker.component';
import { AuthenticateFormComponent } from './components/forms/authenticate-form/authenticate-form.component';
import { OtpFormComponent } from './components/forms/otp-form/otp-form.component';
import { SignupFormComponent } from './components/forms/signup-form/signup-form.component';
import { ServiceFilterFormComponent } from './components/forms/service-filter-form/service-filter-form.component';
import { BloodRequestFormComponent } from './components/forms/request-forms/blood-request-form/blood-request-form.component';
import { UbuRequestComponent } from './components/forms/ubu-request/ubu-request.component';
import { AboutUsComponent } from './pages/misc/about-us/about-us.component';
import { ContactUsComponent } from './pages/misc/contact-us/contact-us.component';
import { FeedbackComponent } from './pages/misc/feedback/feedback.component';
import { PrivacyPolicyComponent } from './pages/misc/privacy-policy/privacy-policy.component';
import { TermsOfServiceComponent } from './pages/misc/terms-of-service/terms-of-service.component';
import { LayoutWithHeaderComponent } from './components/layout-with-header/layout-with-header.component';
import { LayoutMobileHeaderComponent } from './components/layout-mobile-header/layout-mobile-header.component';
import { MapComponent } from './components/map/map/map.component';
import { NgxUsefulSwiperModule } from 'ngx-useful-swiper';
import { TourGuideComponent } from './pages/misc/tour-guide/tour-guide.component';
import { NgOtpInputModule } from 'ng-otp-input';
import { MapPageComponent } from './components/map-page/map-page.component';
import { Covid19StatsComponent } from './components/covid19-stats/covid19-stats.component';
import { VerifyUserComponent } from './components/verify-user/verify-user.component';
import { CovidPlasmaComponent } from './components/covid-plasma/covid-plasma.component';
import { LoginComponent } from './pages/login/login.component';
import { VerifyComponent } from './pages/verify/verify.component';
import { CovidDonorComponent } from './pages/covid/covid-donor/covid-donor.component';
import { CovidRequestComponent } from './pages/covid/covid-request/covid-request.component';
import { MenuHeaderComponent } from './layouts/headers/menu-header/menu-header.component';
import { NoMenuHeaderComponent } from './layouts/headers/no-menu-header/no-menu-header.component';
import { MobileHeaderComponent } from './layouts/headers/mobile-header/mobile-header.component';
import { CopyrightFooterComponent } from './layouts/footer/copyright-footer/copyright-footer.component';
import { HomePageComponent } from './pages/home/home-page/home-page.component';
import { MessageComponent } from './pages/message/message.component';
import { MainFooterComponent } from './layouts/footer/main-footer/main-footer.component';
import { NgImageSliderModule } from 'ng-image-slider';
import { DonorHomeComponent } from './pages/home/blood/donor/donor-home/donor-home.component';
import { RequestHomeComponent } from './pages/home/blood/request/request-home/request-home.component';
import { RequestBloodComponent } from './pages/blood/request/request-blood/request-blood.component';
import { DonorSignUpComponent } from './pages/blood/donor/donor-sign-up/donor-sign-up.component';
import { OtpModalComponent } from './layouts/modal-ui/otp-modal/otp-modal.component';
import { BlogComponent } from './layouts/ui-sections/blog/blog.component';
import { TipsComponent } from './layouts/ui-sections/tips/tips.component';
import { VideoModalComponent } from './layouts/modal-ui/video-modal/video-modal.component';
import { UsaverHomeComponent } from './pages/home/usaver-home/usaver-home.component';
import { CovidInfoComponent } from './pages/mobile/info/covid-info/covid-info.component';
import { InfluencerComponent } from './pages/influencer/influencer.component';
import { VideogalleryComponent } from './pages/videogallery/videogallery.component';
import { ContributionsComponent } from './pages/contributions/contributions.component';
import { ContributionformComponent } from './pages/contributionform/contributionform.component';
import { SharebloodComponent } from './pages/shareblood/shareblood.component';
import { PartnerHomePageComponent } from './pages/home/partner-home-page/partner-home-page.component';
import { ScscFooterComponent } from './layouts/footer/scsc-footer/scsc-footer.component';
import { RequestmapviewComponent } from './pages/requestmapview/requestmapview.component';
import { UsaverFeedbackComponent } from './pages/usaver-feedback/usaver-feedback.component';
import { FounderComponent } from './pages/founder/founder.component';
import { IndiaMapComponent } from './pages/india-map/india-map.component';
import { AdvisorComponent } from './pages/advisor/advisor.component';


@NgModule({
  declarations: [LandingPageComponent, LayoutComponent, PageNotFoundComponent, FooterComponent, HeaderComponent, MarkerInfoWindowComponent, MapMarkerComponent, AuthenticateFormComponent, OtpFormComponent, SignupFormComponent, ServiceFilterFormComponent, BloodRequestFormComponent, UbuRequestComponent, AboutUsComponent, ContactUsComponent, FeedbackComponent, PrivacyPolicyComponent, TermsOfServiceComponent, LayoutWithHeaderComponent, LayoutMobileHeaderComponent, MapComponent, TourGuideComponent, MapPageComponent, Covid19StatsComponent, VerifyUserComponent, CovidPlasmaComponent, LoginComponent, VerifyComponent, CovidDonorComponent, CovidRequestComponent, MenuHeaderComponent, NoMenuHeaderComponent, MobileHeaderComponent, CopyrightFooterComponent, HomePageComponent, MessageComponent, MainFooterComponent, DonorHomeComponent, RequestHomeComponent, RequestBloodComponent, DonorSignUpComponent, OtpModalComponent, BlogComponent, TipsComponent, VideoModalComponent, UsaverHomeComponent, CovidInfoComponent, InfluencerComponent, VideogalleryComponent, ContributionsComponent, ContributionformComponent, SharebloodComponent, PartnerHomePageComponent, ScscFooterComponent, RequestmapviewComponent, UsaverFeedbackComponent, FounderComponent, IndiaMapComponent, AdvisorComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    SharedRoutingModule,
    FormsModule,
    HttpClientModule,
    Ng2FlatpickrModule,
    NgxUsefulSwiperModule,
    BrowserAnimationsModule,
    NgOtpInputModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyDfUl7G2CIfkJdCRwakYUQeen2o5cCzcVE',
      libraries: ['places', 'geometry']
    }),
    NgImageSliderModule,
  ],
  providers: [Title],
  exports: [
    MapMarkerComponent,
    MarkerInfoWindowComponent,
    FooterComponent,
    AboutUsComponent,
    ContactUsComponent,
    PrivacyPolicyComponent,
    TermsOfServiceComponent,
    PageNotFoundComponent,
    VideoModalComponent
  ]
})
export class SharedModule { }
