import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-marker-info-window',
  templateUrl: './marker-info-window.component.html',
  styleUrls: ['./marker-info-window.component.css']
})
export class MarkerInfoWindowComponent implements OnInit {
  @Input() userInfo :any;
  constructor() { }

  ngOnInit() {
  }

}
