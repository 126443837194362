import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { User } from '../../models/user.model';
import { DataService } from '../../services/user-data/data.service';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import {mapData} from '../../models/map-data.model'
declare var $: any;
declare var Swiper: any;

@Component({
  selector: 'app-user-header',
  templateUrl: './user-header.component.html',
  styleUrls: ['./user-header.component.css']
})
export class UserHeaderComponent implements OnInit {
  userData: any;
  availble_for_donate: string;
  is_availability: boolean;
  transactionDetails: any;
  requestList: any;
  page_url: string;
  notifications:any;
  @Input() isSidebarEnabled: boolean = false;
  @Output() toggleChanged: EventEmitter<boolean> = new EventEmitter();
  @Output() donorListChangeEventOnMap = new EventEmitter<mapData>();
  @Input() latitude:number;
  @Input() longitude :number;

  constructor(private router: Router, private dataService: DataService, private toastrService: ToastrService, private ngxUiLoaderService: NgxUiLoaderService) {
    router.events.subscribe((val) => {
      this.page_url = router.url.toString();
    // $('#wrapper').removeClass('toggled');
    });
  }

  ngOnInit() {
    this.userData = JSON.parse(localStorage.getItem("ubu_auth_user"));
    if (this.userData) {
      if (this.userData.is_availble) {
        this.availble_for_donate = "Available";
        this.is_availability = true;
      } else {
        this.availble_for_donate = "Not Available";
        this.is_availability = false;
      }
    }
    
    const data = {
      "action":"toplist",
      "user_id":this.userData.user_id
      };  
       /* this.dataService.getNotifications(data)
        .subscribe(
          response=>{
             this.notifications = response['data']['notifications']; 
            //this.refresh.next()
          },error =>{
            console.log("Some thing went wrong please try again");  
       }); */

  }

  mapDataChangeEventHandler(mapData:mapData){       
   this.donorListChangeEventOnMap.emit(mapData);
  } 
  requestBlood(){
    $('#ubuBloodRequestFormModal').modal('show');
  }

  logOut() {
    // remove user from local storage to log user out
    localStorage.removeItem('ubu_auth_user');
    localStorage.removeItem('ubu_auth_token');
    localStorage.removeItem('is_login_val');
    this.router.navigate(['/']);
  }
  toggleSideMenu() {       
    this.isSidebarEnabled = !this.isSidebarEnabled;
    this.toggleChanged.emit(this.isSidebarEnabled); 
    setTimeout(()=>{  
      window.dispatchEvent(new Event('resize'));
    var swiper = new Swiper('.adds_slide', {
      slidesPerView: 1,

    });
  }, 1000);
  }

}
