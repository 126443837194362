import { Component, OnInit, ViewChild, ElementRef, NgZone,EventEmitter, Output } from '@angular/core';
import { DataService } from './../../services/dataService/data.service';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { MapsAPILoader, GoogleMapsAPIWrapper, AgmMap, LatLngBounds, LatLngBoundsLiteral } from '@agm/core';
import { Observable } from 'rxjs';
import { Routes,Router, RouterModule,ActivatedRoute } from '@angular/router';
import { debounceTime, distinctUntilChanged, filter, map, merge } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { NgbTypeahead } from '@ng-bootstrap/ng-bootstrap';
import { SwiperOptions } from 'swiper';
declare var $: any

@Component({
  selector: 'app-requestmapview',
  templateUrl: './requestmapview.component.html',
  styleUrls: ['./requestmapview.component.css']
})
export class RequestmapviewComponent implements OnInit {
 
  
  requestTransactionData:any;
  requestUserData:any;
  acceptedDonorsData:any
  isSidebarToggled = false;
  isSignUpSubmitted = false;
  latitude: number;
  longitude: number;
  zoom: number =12;
  userLocation: Location;
  mapCenter: any;
  userId: any;
  map: google.maps.Map;
  fitBounds: boolean = false;
  zoomControlOptions: any;
  phonenumber: any;
  noResult = false;
  userData: any;
  user_id: number;
  isToggled:boolean;

  @ViewChild('AgmMap', { static: true }) agmMap: AgmMap;
  @Output() sidebarToggleEvent: EventEmitter<boolean> = new EventEmitter();

  configRecentBloodRequest: SwiperOptions = {
    pagination: { el: '.swiper-pagination', clickable: true },
    slidesPerGroup: 1,
    slidesPerView: 1,
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev'
    },
    spaceBetween: 50
  };

  public origin: any;
  public destination: any;
  public renderOptions:any;
  public markerOptions:any
  public directionData:any=[];

  constructor(
    private dataService: DataService,
    private ngxUiLoaderService: NgxUiLoaderService,
    private toastrService: ToastrService,
    private router :Router ,
    private route: ActivatedRoute,    
    private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone,
    
  ) { }

  ngOnInit() {
    this.mapsAPILoader.load().then(() => {
      this.setCurrentLocation();
      this.zoomControlOptions = { position: google.maps.ControlPosition.RIGHT_CENTER };
    });
    let reqID = this.route.snapshot.params.reqid;
    this.route.params.subscribe(params => {
     this.ngxUiLoaderService.start();
    let userBasicData = {
      "request_id" :  reqID
      }    
      this.dataService.getBloodRequestDataList(userBasicData)
          .subscribe(
           transactionData => {
            if(transactionData.status_code == 200){
             this.requestUserData = transactionData.data; 
             this.latitude= transactionData.data.latitude;
             this.longitude = transactionData.data.longitude;
             this.ngxUiLoaderService.stop();
        }
           });
           this.ngxUiLoaderService.stop();
    });
        
  }

  // Get Current Location Coordinates
  public setCurrentLocation() {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.latitude = position.coords.latitude;
        this.longitude = position.coords.longitude;
        this.zoom = 20;
        //this.getCovid19StatList(this.latitude, this.longitude);
      });
    } else {
      this.toastrService.info("geo Location not enabled");

    }
  }
  mapReady(event: any) {
    this.map = event;
      this.map.controls[google.maps.ControlPosition.BOTTOM_CENTER].push(document.getElementById("requestTransactionOnMap"));
  }

  sideBarToggle(isToggled: boolean) { 
    this.isSidebarToggled = isToggled;
  }

}
