import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { Component, OnInit, ViewChild, ElementRef, NgZone, EventEmitter, Output, Input } from '@angular/core';
import { DatePipe } from '@angular/common'
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { FlatpickrOptions } from 'ng2-flatpickr';
import { DataService } from '../../../../../services/user-data/data.service';
import { ManageProfileService } from '../../../../../services/ub-user/manage-profile/manage-profile.service';
import { Observable, Subject } from 'rxjs';

declare var $: any;

@Component({
  selector: 'app-picture',
  templateUrl: './picture.component.html',
  styleUrls: ['./picture.component.css']
})
export class PictureComponent implements OnInit {

  profilePictureInfoForm: FormGroup;
  @Input() userProfileData: any;
  @Output() pictureInfoUpdated: EventEmitter<boolean> = new EventEmitter();
  isPictureInfoSubmitted: boolean = false;
  profilePicUrl = new Array<string>();

  constructor(private formBuilder: FormBuilder, private dataService: DataService, private toastrService: ToastrService, private ngxUiService: NgxUiLoaderService,
    private ngZone: NgZone, private datePipe: DatePipe, private manageProfileService: ManageProfileService) {

  }

  ngOnInit() {
    this.profilePictureInfoForm = this.formBuilder.group({});
  }


  /***
   * 
   */
  image_urls = new Array<string>();
  profilePicSelection(event: any) {
    this.ngxUiService.start();
    this.profilePicUrl = [];
    let files = event.target.files;
    //  var mimeType = files[0].name;
    //  var mimesize = files[0].size;
    var sFileName = files[0].name;
    var sFileExtension = sFileName.split('.')[sFileName.split('.').length - 1].toLowerCase();
    var iFileSize = files[0].size;
    var iConvert = (iFileSize / 1048576).toFixed(2);
    if (!(sFileExtension === "png" ||
      sFileExtension === "jpeg" ||
      sFileExtension === "jpg") || iFileSize > 1048576) { /// 1 mb
      var txt = "File type : " + sFileExtension + "\n\n";
      txt += "Size: " + iConvert + " MB \n\n";
      txt += "Please make sure your file is in image format and less than 1 MB.\n\n";
      this.toastrService.info('', txt);
      this.ngxUiService.stop();
    }
    else {
      if (files) {
        for (let file of files) {
          let reader = new FileReader();
          reader.onload = (e: any) => {
            this.image_urls.push(e.target.result);
            const profile_data = {
              "action": "profile_pic",
              "profile_pic": e.target.result
            };
            this.manageProfileService.updateProfileData(profile_data)
              .subscribe(
                response => {
                  this.ngxUiService.stop();
                  if (response['status_code'] == 200) {
                    $('.user_profile_pop .close').trigger('click');
                    this.pictureInfoUpdated.emit(response['status_code']);
                    this.toastrService.success('', response['success'].message);
                  } else {
                    this.toastrService.warning('', response['error'].message);
                  }
                }, error => {
                  console.log("Some thing went wrong please try again");
                });
          }
          reader.readAsDataURL(file);
        }
      }

    }
  }


  removeProfilepic() {
    this.ngxUiService.start();
    const removePprofilePic = {
      "action": "profile_pic_drop",
    };
    this.manageProfileService.updateProfileData(removePprofilePic)
      .subscribe(
        response => {
          this.ngxUiService.stop();
          if (response['status_code'] == 200) {
            this.image_urls = [];
            $('.user_profile_pop .close').trigger('click');
            this.pictureInfoUpdated.emit(response['status_code']);
            this.toastrService.success('', response['success'].message);
          } else {
            this.toastrService.warning('', response['error'].message);
          }
        }, error => {
          console.log("Some thing went wrong please try again");
        });
  }
}
