import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { DataService } from '../../services/dataService/data.service';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Ubrequest } from '../../models/request.model';
declare var $: any


@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.css']

})
export class LandingPageComponent implements OnInit {
  @Output() requestTypeEvent = new EventEmitter<any>();
  isSignUpSubmitted = false;
  latitude: number;
  longitude: number;
  zoom: number;
  userLocation: Location;
  userData: any;
  userDataList: any;
  private searchTerm: string;
  mapCenter: any;
  userId: any;
  public geoCoder;
  map: google.maps.Map;
  fitBounds: boolean = false;
  zoomControlOptions: any;
  searchDonorListForm: FormGroup;
  phonenumber: any;
  noResult = false;
  is_mobile_verify: boolean;
  request_data: any;
  ubsp_mobile: number;
  ubsp_user_id: number;
  tourStepOne: boolean = true;
  tourStepTwo: boolean = false;
  tourStepThree: boolean = false;
  tourStep: number = 1;
  homeView: boolean = true;
  mapView: boolean = false;

  public address: string;
  public city: string;
  public state: string;
  public country: string;
  public zipcode: string;

 

  constructor(private router: Router, 
    private  dataService: DataService,
    private toastrService: ToastrService, private ngxUiLoaderService: NgxUiLoaderService,
    private UbSearchfb: FormBuilder, ) { 
      this.request_data = new Ubrequest();
    }

  ngOnInit() {
    this.request_data.request_type = "signup_request";
    $(document).ready(function(){
      $(".banner_content .content ").css({"height":$(".banner_content .content").height()+"!important"});

    });

   
  
  } 
}
