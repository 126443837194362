import { Component, OnInit, Input, SimpleChanges, ViewChild, ElementRef  } from '@angular/core';
import { url } from 'inspector';
//import { DataService } from '../../services/dataService/data.service';
import { DataService } from '../../../services/dataService/data.service';

declare var $: any;
@Component({
  selector: 'ub-video-modal',
  templateUrl: './video-modal.component.html',
  styleUrls: ['./video-modal.component.css']
})
export class VideoModalComponent implements OnInit {
  ubinfluencers=[];
  default_video:any;
  final_videos=[];
  getvType:any;
  video_header_title:any;
  @Input() video_type: number;
  constructor(
    private dataService: DataService,
  ) { 
  //  console.log("construct",this.video_type);
    this.final_videos=[];
     
  }
 

  onPreviousSearchPosition(e:Event){
    $('.thumnail_list').animate({
      scrollLeft: "-=200px"
    }, "slow");
  }
  onNextSearchPosition(e:Event){
    $('.thumnail_list').animate({
      scrollLeft: "+=200px"
    }, "slow");
  }



  ngOnInit() {

//    console.log(this.video_type);
  
      $('#watch_video,#watch_covid_video').on('hidden.bs.modal', function () {
        $('video').get(0).pause(); 
      });

     $('#watch_video,#watch_covid_video').on('show.bs.modal', function () {
      $('video').load();
   });
  


  }


  
  ngOnChanges(timeOptionStart: SimpleChanges) {
     // console.log( "model",this.getvType);
      this.dataService.geInfluencerDataList()
      .subscribe(
        dataList => {
          this.ubinfluencers = dataList.data.influencers;
        //  this.default_video =this.ubinfluencers[0].video_url;
        });
        this.getvType = this.video_type;
        if(this.getvType == 1){
          this.video_header_title = "SOCIAL IMPACT INFLUENCERS";
        }else if(this.getvType==2){
          this.video_header_title = "UBLOOD BENEFICIARIES";
        }else if(this.getvType == 3){
          this.video_header_title = "SOCIAL IMPACT INFLUENCERS";
        }
        this.getvideos(this.ubinfluencers,this.getvType);
  }
 
  getvideos(getVideos:any, vid:any){
    var props=[];
    //console.log(vid);
    getVideos.forEach(function(item) {
      //  let newlabel=item.label;
        //item.label=newlabel.split('-');
       // console.log(this.getvType);
        if(item.cat_type == vid){
          props.push(item);
        }else if(vid == 3){
          props.push(item);
         // this.video_header_title = "SOCIAL IMPACT INFLUENCERS";
        }
      }); 
      this.final_videos=props;
      if(this.final_videos.length>0)
      this.default_video=this.final_videos[0].video_url;
      //console.log(this.final_videos);
      if(this.final_videos.length>=6){
        $('.thum_parent .right_arrow,.thum_parent .left_arrow').show();
      }else{
        $('.thum_parent .right_arrow,.thum_parent .left_arrow').hide();
      }
  }
  row_enable(id:number){
  //  alert(id);
    var abc= $("#"+id).find('img').attr('name');
    $('.big_screen source').load().attr('src', abc);
    $(".big_screen")[0].load(); 
    $('.big_screen').get(0).play()
        
  // alert(abc);
   
}

}
