import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { Ubrequest } from '../../models/request.model';
import { Router } from '@angular/router';
declare var $: any;

@Component({
  selector: 'app-layout-with-header',
  templateUrl: './layout-with-header.component.html',
  styleUrls: ['./layout-with-header.component.css']
})
export class LayoutWithHeaderComponent implements OnInit {
  @Output() requestTypeEvent = new EventEmitter<any>();
  @Input() latitude: number;
  @Input() longitude: number;
  request_data: Ubrequest;
  filteredDonorList: any;
  constructor(private router :Router) {
    this.request_data = new Ubrequest();
  }

  ngOnInit() {
  }

  ubuSignUp() {
    this.request_data.request_type = "signup_request";
    this.requestTypeEvent.emit(this.request_data);
    $('#authenticateModalForm').modal('show');   
  }
  goToMapPage(){
    this.router.navigate(["/map"]);
  }
  
  goToHomePage(){
    this.router.navigate(["/"]);
  }

}