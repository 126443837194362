import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { Component, OnInit, ViewChild, ElementRef, NgZone,EventEmitter,Output,Input } from '@angular/core';
import { DatePipe } from '@angular/common'
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { FlatpickrOptions } from 'ng2-flatpickr';
import { DataService } from '../../../../../services/user-data/data.service';
import { ManageProfileService } from '../../../../../services/ub-user/manage-profile/manage-profile.service';
import { Observable, Subject } from 'rxjs';


declare var $: any;


@Component({
  selector: 'app-personal-info',
  templateUrl: './personal-info.component.html',
  styleUrls: ['./personal-info.component.css']
})
export class PersonalInfoComponent implements OnInit {

  profilePersonalInfoForm: FormGroup;
  @Input() userProfileData:any;
  @Output() personalInfoUpdated: EventEmitter<boolean> = new EventEmitter();
  isPersonalInfoSubmitted:boolean = false;
  birthDate:string;
  HIVstatus:any;
  Tatoostaus:any;
  currentDate = new Date();
  currentYear = this.currentDate.getFullYear();
  currentMonth =this.currentDate.getMonth();
  currentDay = this.currentDate.getDate();
  dateFormatConfig: FlatpickrOptions = {
    mode:'single',
    enableTime: false,
    dateFormat: "d-m-Y",  
    defaultDate: new Date(this.currentYear - 18, this.currentMonth, this.currentDay),
    maxDate: new Date(this.currentYear - 18, this.currentMonth, this.currentDay),
    minDate:new Date(this.currentYear - 99, this.currentMonth, this.currentDay),
  };

  genderOptionList = [
    { value: "1", label: 'Male' },
    { value: "2", label: 'Female' },
    { value: "3", label: 'Other' }
  ]

  constructor(private formBuilder: FormBuilder, private dataService: DataService, private toastrService: ToastrService, private ngxUiService: NgxUiLoaderService,
    private ngZone: NgZone, private datePipe: DatePipe, private manageProfileService: ManageProfileService) {

  }

  ngOnInit() {
    //console.log( this.dateFormatConfig.defaultDate);
    this.profilePersonalInfoForm = this.formBuilder.group({
      ubu_date_of_birth: ['', Validators.required],
      ubu_gender: ['', Validators.required],
      ubu_weight: ['', [Validators.required,Validators.pattern(/^[1-9]\d{1,2}$/), Validators.min(50), Validators.max(150)]]
    }); 
    if(this.userProfileData.more_details.dob) {
    this.dateFormatConfig.defaultDate= this.userProfileData.more_details.dob;
    }
    this.profilePersonalInfoForm.get('ubu_date_of_birth').setValue(this.userProfileData.more_details.dob);
    this.profilePersonalInfoForm.get('ubu_gender').setValue(this.userProfileData.more_details.gender.id);
    this.profilePersonalInfoForm.get('ubu_weight').setValue(this.userProfileData.more_details.weight); 
    this.HIVstatus=  this.userProfileData.have_hiv_tested_positive;
    this.Tatoostaus= this.userProfileData.is_tattoo_in_past;
  }

  get ubuPersonalProfileInfo() { return this.profilePersonalInfoForm.controls; }

  updateHiv(valData: any) {
    this.HIVstatus='';
    this.HIVstatus = valData;
   // console.log('hiv',this.HIVstatus);
  }

  updateTatoo(tatooData: any) {
    this.Tatoostaus='';
    this.Tatoostaus= tatooData;
    //console.log("tatoo",this.Tatoostaus);
  }

  /**
   * 
   * @param post Update Profile Personal Info
   */
  updatePersonalProfileInfo(post: any) {
    this.isPersonalInfoSubmitted= true;   
    if (this.profilePersonalInfoForm.invalid) {
      this.toastrService.info('', 'Please enter valid required Information');
      return false;
    }
    this.ngxUiService.start();
    if (post) { 
       if(Array.isArray(post['ubu_date_of_birth'])){
        this.birthDate = this.datePipe.transform(post['ubu_date_of_birth'][0], 'dd-MM-yyyy', 'es-ES');
       }else{
        this.birthDate = post['ubu_date_of_birth'];
       }     
      let userPersonalData = {
        "action": "personal",
        "weight": post['ubu_weight'],
        "gender": post['ubu_gender'],
        "dob":this.birthDate
      }
      let additionalData={
        action : "update_donor_info",
        is_tattoo_in_past :  this.Tatoostaus,
        have_hiv_tested_positive : this.HIVstatus,
        is_provide_transportation : 0
      }

      this.manageProfileService.updateProfileData(additionalData)
      .subscribe(
        response => {
          if (response['status_code'] == 200) {
           // this.toastrService.success('', response['success'].message);
           // $('#profilePersonalInfoModal .close').trigger('click');
            //this.personalInfoUpdated.emit(response['status_code']);
          } else {
            this.toastrService.warning('', response['error'].message);
          }
          //this.ngxUiService.stop();
        }, error => {
          this.toastrService.info('', "Some thing went wrong");
          //this.ngxUiService.stop();
        });
            
      this.manageProfileService.updateProfileData(userPersonalData)
        .subscribe(
          response => {
            if (response['status_code'] == 200) {
              this.toastrService.success('', response['success'].message);
              $('#profilePersonalInfoModal .close').trigger('click');
              this.personalInfoUpdated.emit(response['status_code']);
            } else {
              this.toastrService.warning('', response['error'].message);
            }
            this.ngxUiService.stop();
          }, error => {
            this.toastrService.info('', "Some thing went wrong");
            this.ngxUiService.stop();
          });

    }
  }
  clearVal(){
    this.HIVstatus=  this.userProfileData.have_hiv_tested_positive;
    this.Tatoostaus= this.userProfileData.is_tattoo_in_past;
  }

  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;

  }
}
