
export class ImageProcessor {

    imgOver(element:any,imgFor:string){
      
      if(imgFor =="bloodList"){
        element.setAttribute('src', 'assets/images/icon-3.png');
      }

       
   }

    imgOut(element:any,imgFor:string){

    if(imgFor =="bloodList"){
        element.setAttribute('src', 'assets/images/menu_icons/icon-8.png');
      }

   }
    
}