import { Injectable } from '@angular/core';
import { HttpClientModule, HttpParams,HttpClient,HttpHeaders} from '@angular/common/http';
import { Observable } from 'rxjs';
import { AppSettings } from '../../../../app.settings';
import { AppApiEndpoints } from '../../../../app.api-endpoints';

@Injectable({
  providedIn: 'root'
})
export class ManageProfileService {
  reqHeader:any;
  constructor(private httpClient: HttpClient, private _appSettings: AppSettings, private appapiEndpoint: AppApiEndpoints) { 
    this.reqHeader = new HttpHeaders({ 
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + localStorage.getItem('ubu_auth_token')
    });
  }

  updateProfileData(updateData:any) : Observable<any>{
    let user_id = JSON.parse(localStorage.getItem('ubu_auth_user')).user_id;
    updateData['user_id'] = user_id;
    let apiUrlGetuserProfileDetails = this._appSettings.API_NAMESPACE + this.appapiEndpoint.updateUserDetails;
    return this.httpClient.post(apiUrlGetuserProfileDetails, JSON.stringify(updateData), { headers: this.reqHeader });
  }
}
