import { Component, OnInit } from '@angular/core';
import { connectableObservableDescriptor } from 'rxjs/internal/observable/ConnectableObservable';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Router, ActivatedRoute } from '@angular/router';
import { DataService } from '../../services/user-data/data.service';
///services/dataService/data.service';
import { ConfirmationDialogService } from '../../services/ub-misc/confirmation-dialog/confirmation-dialog.service';
import { FlatpickrOptions } from 'ng2-flatpickr';
declare var $: any;
declare var Swiper: any;


@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
  public userDetails: any;
  public UBURequestList: any = [];
  public ubBanners: any;
  select_video: any;
  public ubinfluencers: any;
  public ubTweets: any;
  public BloodGroup: string;
  public reqCnt: any;
  constructor(
    private dataService: DataService,
    private toastrService: ToastrService,
    private ngxUiLoaderService: NgxUiLoaderService,
    private router: Router,
    private route: ActivatedRoute
  ) {
    this.getBanners();
    this.getMyRequestList();
    this.getTweets();
    this.userDetails = JSON.parse(localStorage.getItem('ubu_auth_user'));
    localStorage.setItem("bloodGroup", this.userDetails.blood_group.blood_group);
    console.log(this.userDetails);
    this.BloodGroup = this.userDetails.blood_group.blood_group;
    console.log(this.BloodGroup + "before");
    this.dataService.currentBloodGroup.subscribe(BloodGroup => this.BloodGroup = BloodGroup);
    console.log(this.BloodGroup + "after");
  }
  currentDate = new Date();
  currentYear = this.currentDate.getFullYear();
  currentMonth = this.currentDate.getMonth();
  currentDay = this.currentDate.getDate();
  dateFormatConfig: FlatpickrOptions = {
    mode: 'single',
    enableTime: false,
    dateFormat: "d-m-Y",
    defaultDate: new Date(this.currentYear - 18, this.currentMonth, this.currentDay),
    maxDate: new Date(this.currentYear - 18, this.currentMonth, this.currentDay),
    minDate: new Date(this.currentYear - 99, this.currentMonth, this.currentDay),
  };

  ngOnInit() {
    this.getUserDetails();
    setTimeout(() => {
      window.dispatchEvent(new Event('resize'));
    }, 3000);

    // scroll events
    var scrolled = 0;

    $(document).ready(function () {


      $("#downClick").on("click", function () {
        scrolled = scrolled + 100;
        $(".cover").animate({
          scrollTop: scrolled
        });
      });


      $("#upClick").on("click", function () {
        scrolled = scrolled - 100;

        $(".cover").animate({
          scrollTop: scrolled
        });

      });

    });

    this.userDetails = JSON.parse(localStorage.getItem('ubu_auth_user'));
    //console.log(this.userDetails);

    setTimeout(function () {

      var swiper = new Swiper('.blood_req', {
        slidesPerView: 1,
        spaceBetween: 5,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
        breakpoints: {

          768: {
            slidesPerView: 2,

          },
          991: {
            slidesPerView: 4,

          },
        }
        // autoplay: {
        //   delay: 2000,
        // },
      });

      var swiper = new Swiper('.adds_slide', {
        autoplay: {
          delay: 5000,
        },

      });



    }, 1000);

    setTimeout(function () {
      var swiper = new Swiper('.blood_donation_slid', {
        autoplay: {
          delay: 6000,
        },

      });


      var swiper = new Swiper('.titwer', {

        direction: 'vertical',
        mousewheelControl: true,
        slidesPerView: 3,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
        // freeMode: true,
        // freeModeSticky: true
        // autoplay: {
        //   delay: 2000,
        // },
      });

    }, 1000);


    //   setTimeout(function(){ 
    //   var swiper2 = new Swiper('.adds_slide', {
    //     slidesPerView: 1,
    //     spaceBetween: 25,
    //     navigation: {
    //       nextEl: '.swiper-button-next',
    //       prevEl: '.swiper-button-prev',
    //     },
    //     breakpoints: {

    //       768: {
    //         slidesPerView: 2,

    //       },
    //       991: {
    //         slidesPerView:4,

    //       },
    //     }
    //     // autoplay: {
    //     //   delay: 2000,
    //     // },
    //   });
    // }, 3000);

    //  this.getMyRequestList();
    // this.getInfluencers();
  }

  getUserDetails() {
    this.ngxUiLoaderService.start();
    this.dataService.getUserData(JSON.parse(localStorage.getItem('ubu_auth_user')).user_id)
      .subscribe(
        (userProfileData: any) => {
          this.userDetails = userProfileData.data;
          //this.reqCnt= (userProfileData.data).length;

          console.log(this.userDetails);
          this.ngxUiLoaderService.stop();
        });
  }

  stories_pop(e: Event) {
    this.select_video = 1;
    //   console.log(this.select_video);
    $('#watch_video').modal('show');


  }
  socail_impact(e: Event) {
    this.select_video = 2;
    //  console.log(this.select_video);
    $('#watch_video').modal('show');

  }



  getMyRequestList() {
    this.ngxUiLoaderService.start();
    const myRequestDataFilter = { "action": "others_compatible" };
    this.dataService.getRequestList(myRequestDataFilter)
      .subscribe(
        dataList => {
          this.UBURequestList = dataList.data.requests;
          this.reqCnt = Object.keys(dataList.data.requests).length;
          //  this.reqCnt=0;
          console.log(this.reqCnt);
          this.ngxUiLoaderService.stop();
        });
  }

  getBanners() {
    this.ngxUiLoaderService.start();
    this.dataService.getMetaDataBannerList()
      .subscribe(
        dataList => {
          this.ubBanners = dataList.data.social_banners;
          // console.log(this.ubBanners);
          this.ngxUiLoaderService.stop();
        });

  }

  getTweets() {
    this.ngxUiLoaderService.start();
    this.dataService.getTweetList()
      .subscribe(
        dataList => {
          this.ubTweets = dataList.data.tweets;
          // console.log(this.ubTweets);
          this.ngxUiLoaderService.stop();
        });

    setTimeout(() => {
      var swiper = new Swiper('.titwer', {
        direction: 'vertical',
        mousewheelControl: true,
        slidesPerView: 3,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },

      });

    }, 2000);

  }

  viewRequestDetails(request_id: number) {
    this.router.navigate(["/request-details", request_id]);
  }
  getInfluencers() {
    this.dataService.getInfluencerDataList()
      .subscribe(
        dataList => {
          this.ubinfluencers = dataList.data.influencers;
          //console.log(this.ubinfluencers);
          //this.getInfluencers(this.ubinfluencers);
          this.ngxUiLoaderService.stop();


        });

  }


  //  $('#nav_up').click(
  // 	function (e) {
  // 		$('html, body').animate({scrollTop: '0px'}, 800);
  // 	}
  // );

  top_arrow(e: Event) {

    // $('.slide_m7 ul').animate({scrollTop: '50px'}, 800);


  }

}
