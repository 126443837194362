import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DataService } from '../../services/dataService/data.service';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-usaver-feedback',
  templateUrl: './usaver-feedback.component.html',
  styleUrls: ['./usaver-feedback.component.css']
})
export class UsaverFeedbackComponent implements OnInit {
  ubrBloodRequestForm: FormGroup;
  old_user_form: FormGroup;
  mobile_vali: FormGroup;
  rating3: number;
  new_user_form = false;
  old_user_set = false;
  very_mobile_num: string = '';
  db_mobile_num: string = '9999999999';
  constructor(private fb: FormBuilder) {
    this.rating3 = 0;
    this.ubrBloodRequestForm = this.fb.group({
      ubr_name: ['', [Validators.required, Validators.minLength(1)]],
      ubr_feedback: ['', [Validators.required, Validators.minLength(1)]],
      ubsp_email: [
        '',
        [
          Validators.required,
          Validators.email,
          Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,3}$'),
        ],
      ],
      // ubr_phone: [
      //   '',
      //   [Validators.required, Validators.pattern(/^[6-9]\d{9}$/)],
      // ],
      rating: ['', Validators.required],
    });

    this.old_user_form = this.fb.group({
      old_ubr_feedback: ['', [Validators.required, Validators.minLength(1)]],
      old_rating: ['', Validators.required],
    });

    this.mobile_vali = this.fb.group({
      very_mobile_num: [
        '',
        [Validators.required, Validators.pattern(/^[6-9]\d{9}$/)],
      ],
    });
  }
  ngOnInit() { }
  check_mobile_number() {
    console.log(this.db_mobile_num);
    if (this.db_mobile_num === this.very_mobile_num) {
      this.old_user_set = true;
      this.new_user_form = false;
    } else {
      this.new_user_form = true;
      this.old_user_set = false;
    }
  }
  onRate($event: {
    oldValue: number;
    newValue: number;
    //  starRating: StarRatingComponent;
  }) {
    /* alert(`Old Value:${$event.oldValue}, 
       New Value: ${$event.newValue}, 
       Checked Color: ${$event.starRating.checkedcolor}, 
       Unchecked Color: ${$event.starRating.uncheckedcolor}`);*/
  }

  request_mobile_vali() {
    if (this.mobile_vali.valid) {
      // Perform actions with the form data
    }
  }
  requestService() {
    if (this.ubrBloodRequestForm.valid) {
      // Perform actions with the form data
    }
  }
  old_user_form_requestService() {
    if (this.old_user_form.valid) {
      // Perform actions with the form data
    }
  }
}
