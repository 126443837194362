import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { Component, OnInit, ViewChild, ElementRef, NgZone } from '@angular/core';
import { DatePipe } from '@angular/common'
import { MapsAPILoader, MouseEvent } from '@agm/core';
import { ToastrService } from 'ngx-toastr';
import { FlatpickrOptions } from 'ng2-flatpickr';
import { NgxUiLoaderService } from 'ngx-ui-loader';

@Component({
  selector: 'app-service-menu',
  templateUrl: './service-menu.component.html',
  styleUrls: ['./service-menu.component.css']
})
export class ServiceMenuComponent implements OnInit {

  constructor(private toastrService: ToastrService, private ngxUIServices: NgxUiLoaderService ) { }

  ngOnInit() {
  }
  addAmbulance(){
    this.toastrService.info("Coming Soon");
  }
  addHospital() {
    this.toastrService.info("Coming Soon");
  }
  addBloodBank() {
    this.toastrService.info("Coming Soon");
  }
}
