import { Injectable } from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router, Route  } from '@angular/router';
import { AuthenticationService } from '../../shared/services/authentication/authentication.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class AuthGuard implements CanActivate {
    constructor(
        private router: Router,
        private authenticationService: AuthenticationService
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        // when the user is logged in and just navigated to another route...
        console.log(this.authenticationService.isUserLoggedIn())
        if (this.authenticationService.isUserLoggedIn()) { return true; } 
      
        // proceeds if not loggedIn or F5/page refresh 
      
        // Store the attempted URL for redirecting later
        this.authenticationService.redirectUrl = state.url;
      
        // go login page
        this.router.navigate(['/']);
        return false;
      }
}
