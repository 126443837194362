import { Component, OnInit } from '@angular/core';
declare var Swiper: any;

@Component({
  selector: 'app-donor-home',
  templateUrl: './donor-home.component.html',
  styleUrls: ['./donor-home.component.css']
})
export class DonorHomeComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    var swiper1 = new Swiper('.slider_m6', {
      slidesPerView: 1,
      spaceBetween: 1,
      pagination: {
        el: '.swiper-pagination',
        dynamicBullets: true,
      },
      autoplay: {
        delay: 2000,
      },
   
    });
  }

}
