import { Component, OnInit, ViewChild, ElementRef, NgZone, Input, Output, EventEmitter} from '@angular/core';
import { DataService } from '../../../services/user-data/data.service';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { MapsAPILoader, GoogleMapsAPIWrapper, AgmMap, LatLngBounds, LatLngBoundsLiteral } from '@agm/core';
import { Observable } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import {mapData} from '../../../models/map-data.model'
import {ImageProcessor} from '../../../../helpers/image-processor';
declare var $: any


@Component({
  selector: 'app-service-filter',
  templateUrl: './service-filter.component.html',
  styleUrls: ['./service-filter.component.css']
})
export class ServiceFilterComponent implements OnInit {
 //@ViewChild("distance") private parentRef: ElementRef<HTMLElement>;
 @Output() mapDataChangeEvent = new EventEmitter<mapData>();
 @Output() toggleSideMenu = new EventEmitter<any>();
 @Input() latitude:number;
 @Input() longitude :number;
 donorList: any;
 mapData: mapData;
 selectedBloodGroupList:any=[];
 serviceDataList: any;
 private searchTerm: string;
 address: string;
 private geoCoder;
 map: google.maps.Map;
 fitBounds: boolean = false;
 serviceFilterForm: FormGroup;
 @ViewChild("filterBloodGroupList", { static: true }) private bloodGroupListRef: ElementRef<HTMLElement>;
 @ViewChild('ubSearch', { static: true }) public searchElementRef: ElementRef;

 constructor(private router: Router, private mapsAPILoader: MapsAPILoader,
   private ngZone: NgZone, private dataService: DataService, private toastrService: ToastrService, private ngxUiLoaderService: NgxUiLoaderService, private UbFilterfb: FormBuilder, private imageProcessor:ImageProcessor) {

   this.serviceFilterForm = this.UbFilterfb.group({
     'search_location': ['',Validators.required]   
   });
   this.mapData = new mapData();
 }

 ngOnInit() {
   $(document).ready(function () {
    $('.avoid_inside .blood_list1,.avoid_inside h4, .btn_m1').on('click', function (event: any) {
      event.stopPropagation();
    });
    $('.blood_list1 li').on('click', function (event: any) {
      $(this).toggleClass('active')
    });
   });

   this.mapsAPILoader.load().then(() => {

     this.geoCoder = new google.maps.Geocoder;
     let autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement);
     autocomplete.addListener("place_changed", () => {
       this.ngZone.run(() => {
         //get the place result
         let place: google.maps.places.PlaceResult = autocomplete.getPlace();
         //verify result
         if (place.geometry === undefined || place.geometry === null) {
           return;
         }
         this.clearBloodGroupFilter(); 
         //set latitude, longitude and zoom
         this.latitude = place.geometry.location.lat();
         this.longitude = place.geometry.location.lng();  
         this.getDonorList(this.latitude, this.longitude, 0, 0, 0);
       });
     });
   });
 }

 searchDonor(post: any) {      
   this.getDonorList(this.latitude, this.longitude, 0, 0, 0);
 }

 setFilterBloodgroup() {
  let _self = this;
  this.selectedBloodGroupList = [];
  const bloodGroupParentRef = this.bloodGroupListRef.nativeElement;
  const selectedbloodGroupElementList = bloodGroupParentRef.querySelectorAll(".active");
  selectedbloodGroupElementList.forEach(function (el) {
    _self.selectedBloodGroupList.push(el.id);
  });
  
  let distance: number = ($("#filterDistanceValue").val()) ? $("#filterDistanceValue").val() : 0;
  let measure: number = ($("#filterDistanceUnit").val()) ? $("#filterDistanceUnit").val() : 1;
  if (this.selectedBloodGroupList.length>0) {
    this.getDonorList(this.latitude, this.longitude, this.selectedBloodGroupList, distance, measure);
  } else {
    this.toastrService.info("Please select the blood group");
    return false;
  }
}

/**
 * 
 */
setFilterDistance() {
  let distance: number = ($("#filterDistanceValue").val()) ? $("#filterDistanceValue").val() : 0;
  let measure: number = ($("#filterDistanceUnit").val()) ? $("#filterDistanceUnit").val() : 1;
  this.getDonorList(this.latitude, this.longitude, this.selectedBloodGroupList, distance, measure);
}

   /***
   * 
   */
  clearBloodGroupFilter() {
    let _self = this;
    this.selectedBloodGroupList = [];
    const bloodGroupParentRef = this.bloodGroupListRef.nativeElement;
    const selectedbloodGroupElementList = bloodGroupParentRef.querySelectorAll(".active");
    selectedbloodGroupElementList.forEach(function (el) {
      el.classList.remove('active');
    });
    this.getDonorList(this.latitude, this.longitude, this.selectedBloodGroupList, 0, 0);
  }

  /**
   * 
   */
  clearDistanceFilter() {
    $("#filterDistanceValue").val("");
    $("#filterDistanceUnit").val(1);
    this.getDonorList(this.latitude, this.longitude, this.selectedBloodGroupList, 0, 0);
  }


 getDonorList(latitude: number, longitude: number, blood_group: any, distance: number, measure: number) { 

   if (latitude && longitude) {
    //console.log(latitude);
     this.ngxUiLoaderService.start();
     let filterData = { "latitude": latitude, "longitude": longitude };
     if (blood_group) {
       filterData["blood_groups"] = blood_group;
     }
     if (distance && measure) {
       filterData["distance"] = distance;
       filterData["measure"] = measure;
     }    
     this.dataService.filterUserDataList(filterData).subscribe(
       (filterDataList: any) => {
         if (filterDataList.data.length == 0) {
           this.toastrService.info("No Donor Found");
         }
         this.mapData.latitude = this.latitude;
         this.mapData.longitude = this.longitude
         this.mapData.donorList = filterDataList.data
         this.mapData.fitBounds= true;          
         this.mapDataChangeEvent.emit(this.mapData);
         this.dataService.setMapData(this.mapData);
         this.ngxUiLoaderService.stop();                  
       },
       (err) => {
         console.log(err)
         this.ngxUiLoaderService.start();
       });
     this.ngxUiLoaderService.stop();
   }
 }

 openSideMenu(){   
  this.toggleSideMenu.emit();
 }
}
