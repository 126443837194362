import { Component, OnInit, ViewChild } from '@angular/core';
declare var Swiper: any;
declare var $: any;
import { NgxUiLoaderService } from 'ngx-ui-loader';
//import { DataService } from '../../services/user-data/data.service';
import { DataService } from '../../../services/dataService/data.service'
import { NgImageSliderComponent } from 'ng-image-slider';

@Component({
  selector: 'app-partner-home-page',
  templateUrl: './partner-home-page.component.html',
  styleUrls: ['./partner-home-page.component.css']
})
export class PartnerHomePageComponent implements OnInit {

  select_video:any;
  public ubTweets :any;
  constructor(
    private dataService: DataService,
    private ngxUiLoaderService: NgxUiLoaderService,

  ) { 
    this.getTweets();
  }
  @ViewChild("nav", { static: false }) slider: NgImageSliderComponent;
  



// New Landing page slider Start here
imageObject = [{
  video: 'https://api.ublood.com/ub_influencers/raimamondal.mp4',
  thumbImage: 'https://api.ublood.com/ub_influencers/Naman%20Bhatia.png',
  title: ''
}, {
  video: 'https://api.ublood.com/ub_influencers/raimamondal.mp4',
  thumbImage: 'https://api.ublood.com/ub_influencers/Naman%20Bhatia.png',
},
{
  video: 'https://api.ublood.com/ub_influencers/raimamondal.mp4',
  thumbImage: 'https://api.ublood.com/ub_influencers/Naman%20Bhatia.png',
}, 
{
  
  video: 'https://api.ublood.com/ub_influencers/raimamondal.mp4',
  thumbImage: 'https://api.ublood.com/ub_influencers/Naman%20Bhatia.png',
  title: 'Example with title.'
},
{
  
  video: 'https://api.ublood.com/ub_influencers/raimamondal.mp4',
  thumbImage: 'https://api.ublood.com/ub_influencers/Naman%20Bhatia.png',
  title: 'Example with title.'
},{
  video: 'https://api.ublood.com/ub_influencers/raimamondal.mp4',
  thumbImage: 'https://api.ublood.com/ub_influencers/Naman%20Bhatia.png',
  title: 'Hummingbirds are amazing creatures'
},
 ];
 prevImageClick() {
  this.slider.prev();
}

nextImageClick() {
  this.slider.next();
}
// New Landing page slider end here


  ngOnInit() {
    
    // this.select_video=3;
    var swiper1 = new Swiper('.slider_m6', {
      slidesPerView: 1,
      spaceBetween: 1,
      pagination: {
        el: '.swiper-pagination',
        dynamicBullets: true,
      },
      autoplay: {
        delay: 2000,
      },

    });
    $(".covid_section").click(function (event) {
      event.preventDefault();
      $("html, body").animate({ scrollTop: $($(this).attr("href")).offset().top }, 600);
    });

    // label animations start here

    (function ($) {
      $.fn.extend({
        rotaterator: function (options) {

          var defaults = {
            fadeSpeed: 500,
            pauseSpeed: 500,
            child: null
          };

          var options = $.extend(defaults, options);

          return this.each(function () {
            var o = options;
            var obj = $(this);
            var items = $(obj.children(), obj);
            items.each(function () { $(this).hide(); })
            if (!o.child) {
              var next = $(obj).children(':first');
            } else {
              var next = o.child;
            }
            $(next).fadeIn(o.fadeSpeed, function () {
              $(next).delay(o.pauseSpeed).fadeOut(o.fadeSpeed, function () {
                var next = $(this).next();
                if (next.length == 0) {
                  next = $(obj).children(':first');
                }
                $(obj).rotaterator({ child: next, fadeSpeed: o.fadeSpeed, pauseSpeed: o.pauseSpeed });
              })
            });
          });
        }
      });
    })($);

    $(document).ready(function () {
      $('#rotate1').rotaterator({ fadeSpeed: 200, pauseSpeed: 2000 });
      // $('#rotate2').rotaterator({fadeSpeed:200, pauseSpeed:3000});
    });

// label animations start here

(function($){
  $.fn.extend({ 
      rotaterator: function(options) {

          var defaults = {
              fadeSpeed: 500,
              pauseSpeed: 500,
      child:null
          };
           
          var options = $.extend(defaults, options);
       
          return this.each(function() {
                var o =options;
                var obj = $(this);                
                var items = $(obj.children(), obj);
        items.each(function() {$(this).hide();})
        if(!o.child){var next = $(obj).children(':first');
        }else{var next = o.child;
        }
        $(next).fadeIn(o.fadeSpeed, function() {
          $(next).delay(o.pauseSpeed).fadeOut(o.fadeSpeed, function() {
            var next = $(this).next();
            if (next.length == 0){
                next = $(obj).children(':first');
            }
            $(obj).rotaterator({child : next, fadeSpeed : o.fadeSpeed, pauseSpeed : o.pauseSpeed});
          })
        });
          });
      }
  });
})($);

$(document).ready(function() {
      $('#rotate1').rotaterator({fadeSpeed:200, pauseSpeed:2000});
 // $('#rotate2').rotaterator({fadeSpeed:200, pauseSpeed:3000});
});

var swiper = new Swiper('.titwer', {

  direction: 'vertical',
  mousewheelControl: true,
  slidesPerView: 3,
  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev',
  },
  // freeMode: true,
  // freeModeSticky: true
  // autoplay: {
  //   delay: 2000,
  // },
});
setTimeout(() => {

  $(function () {
    $(window).resize();
    window.dispatchEvent(new Event('resize'));

  });
}, 2000); 

  }

  left_arrow(e:Event){
    $(window).resize();
    window.dispatchEvent(new Event('resize'));
  
  }
  socail_impact(e:Event){
    this.select_video = 3;
    //  console.log(this.select_video);
    $('#watch_video').modal('show');

  }
  
  getTweets(){
    this.ngxUiLoaderService.start();
    this.dataService.getTweetList()
      .subscribe(
        dataList => {
          this.ubTweets = dataList.data.tweets;
          this.ngxUiLoaderService.stop();
        });

        setTimeout(() => {
       var swiper = new Swiper('.titwer', {
    direction: 'vertical',
    mousewheelControl: true,
    slidesPerView: 3,
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
  
  });

 },2000);

  }

  linkify(tweetText:string) {
    var replacedText:any, replacePatternOne:any, replacePatternTwo:any, replacePatternThree:any;

    //URLs starting with http://, https://, or ftp://
    replacePatternOne = /(\b(https?|ftp):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gim;
    replacedText = tweetText.replace(replacePatternOne, '<a href="$1" target="_blank">$1</a>');

    //URLs starting with "www." (without // before it, or it'd re-link the ones done above).
    replacePatternTwo = /(^|[^\/])(www\.[\S]+(\b|$))/gim;
    replacedText = replacedText.replace(replacePatternTwo, '$1<a href="http://$2" target="_blank">$2</a>');

    //Change email addresses to mailto:: links.
    replacePatternThree = /(([a-zA-Z0-9\-\_\.])+@[a-zA-Z\_]+?(\.[a-zA-Z]{2,6})+)/gim;
    replacedText = replacedText.replace(replacePatternThree, '<a href="mailto:$1">$1</a>');

    return replacedText.replace(/\n/g, "<br />");
}



}