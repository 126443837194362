import { Component, OnInit } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import { DataService } from './../../services/dataService/data.service';
import { DatePipe } from '@angular/common';
import { DOCUMENT } from '@angular/common';
import { Inject, AfterViewInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
@Component({
  selector: 'app-shareblood',
  templateUrl: './shareblood.component.html',
  styleUrls: ['./shareblood.component.css']
})
export class SharebloodComponent implements OnInit {
  // Search the tags in the DOM
  bodyTag: HTMLBodyElement = document.getElementsByTagName('body')[0];
  htmlTag: HTMLElement = document.getElementsByTagName('html')[0];
  reqBloodData: any;
  constructor(
    private dataService: DataService,
    private datePipe: DatePipe,
    private route: ActivatedRoute,
    private toastrService: ToastrService,
    private ngxUiService: NgxUiLoaderService,
  ) {

  }

  ngOnInit() {
    this.bodyTag.classList.add('share_body');
    this.htmlTag.classList.add('share_body');

    let reqID = this.route.snapshot.params.ubr;
    console.log(reqID);
    this.ngxUiService.start();
    let userBasicData = {
      "request_id": reqID
    }
    this.dataService.getBloodRequestDataList(userBasicData)
      .subscribe(
        response => {
          if (response['status_code'] == 200) {
            // this.toastrService.success('', response['success'].message);
            this.reqBloodData = response.data;
           // console.log(this.reqBloodData);
          } else {
            // this.toastrService.warning('', response['error'].message);
          }
          this.ngxUiService.stop();
        }, error => {
          this.toastrService.info('', "Some thing went wrong");
          this.ngxUiService.stop();
        });

  }
  ngOnDestroy() {
    // remove the the body classes
    this.bodyTag.classList.remove('share_body');
    this.htmlTag.classList.remove('share_body');
  }

}
