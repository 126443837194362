import { Component, OnInit, ViewChild, ElementRef, NgZone, EventEmitter, Output } from '@angular/core';
import { DataService } from '../../../services/user-data/data.service';
import { HospitalService } from '../../../services/ub-services/hospital/hospital.service';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { MapsAPILoader, GoogleMapsAPIWrapper, AgmMap, LatLngBounds, LatLngBoundsLiteral } from '@agm/core';
import { Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map, merge } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { NgbTypeahead } from '@ng-bootstrap/ng-bootstrap';
declare var $: any;
declare var Swiper: any;


@Component({
  selector: 'app-hospital-list',
  templateUrl: './hospital-list.component.html',
  styleUrls: ['./hospital-list.component.css']
})
export class HospitalListComponent implements OnInit {

  @ViewChild('instance', { static: true }) instance: NgbTypeahead;
  latitude: number;
  longitude: number;
  zoom: number;
  userLocation: Location;
  userData: any;
  hospitalDataList: any;
  mapCenter: any;
  userId: any;
  public geoCoder;
  map: google.maps.Map;
  fitBounds: boolean = false;
  zoomControlOptions: any;
  searchDonorListForm: FormGroup;
  phonenumber: any;
  noResult = false;
  request_data: any;
  ubsp_mobile: number;
  ubsp_user_id: number;
  tourStepOne: boolean = true;
  tourStepTwo: boolean = false;
  tourStepThree: boolean = false;
  tourStep: number = 1;
  homeView: boolean = true;
  mapView: boolean = false;

  public address: string;
  public city: string;
  public state: string;
  public country: string;
  public zipcode: string;
  isToggled: boolean;
  donorList: any;
  mapData: any;
  isDisctanceSelected: boolean = false;
  selectedBloodGroupList: any = [];
  serviceDataList: any;
  previousInfoWindow: any;
  saddresslatitude:number;
  saddresslongitude:number;
  serviceFilterForm: FormGroup;
  public origin: any;
  public renderOptions:any;
  public markerOptions:any
  public directionData:any=[]; 
  public donatedDonors:any=[];

  @ViewChild("filterBloodGroupList", { static: true }) private bloodGroupListRef: ElementRef<HTMLElement>;
  @ViewChild('ubSearch', { static: true }) public searchElementRef: ElementRef;
  @Output() sidebarToggleEvent: EventEmitter<boolean> = new EventEmitter();
  @ViewChild('AgmMap', { static: true }) agmMap: AgmMap;

  constructor(private router: Router, private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone, private dataService: DataService,
    private toastrService: ToastrService, private ngxUiLoaderService: NgxUiLoaderService,
    private UbSearchfb: FormBuilder,
    private hospitalserive:HospitalService ) { }

  ngOnInit() {

    $(document).ready(function () {

      $('.panel-collapse').on('show.bs.collapse', function () {
        $(this).siblings('.panel-heading').addClass('active');
      });

      $('.panel-collapse').on('hide.bs.collapse', function () {
        $(this).siblings('.panel-heading').removeClass('active');
      });


      $('.blood_list1 li').on('click', function (event: any) {
        $(this).toggleClass('active')
      });

      $(".blood_filter_btn").click(function () {
        $(".blood_group_list_wrapper").animate({
          height: "toggle"
        });
      });

      $(document).click(function (e:any) {
        var container = $(".blood_group_list_wrapper");
        // if the target of the click isn't the container nor a descendant of the container
        if (!container.is(e.target) && container.has(e.target).length === 0) {
        //  container.hide();
        }
      });

    });
    $('.slider_outer').hide();
$('.hosp_link').click(function(){
  $('.slider_outer').show();
  var swiper1 = new Swiper('.slider_m1 .swiper-container', {
    slidesPerView: 1,
    spaceBetween: 5,
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
    breakpoints: {
      
      768: {
        slidesPerView: 2,
       
      },
      991: {
        slidesPerView:3,
       
      },
    }
    // autoplay: {
    //   delay: 2000,
    // },
 
  });
});

$('.hide_hosp_slid').click(function(){
  $('.slider_outer').hide();
});

    this.mapsAPILoader.load().then(() => {
      this.setCurrentLocation();
      this.zoomControlOptions = { position: google.maps.ControlPosition.RIGHT_CENTER };
      this.geoCoder = new google.maps.Geocoder;

      let autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement);
      autocomplete.addListener("place_changed", () => {
        this.ngZone.run(() => {
          //get the place result
          let place: google.maps.places.PlaceResult = autocomplete.getPlace();
          //verify result
          if (place.geometry === undefined || place.geometry === null) {
            return;
          }
          //set latitude, longitude and zoom
          this.latitude = place.geometry.location.lat();
          this.longitude = place.geometry.location.lng();

          this.getHospitalList(this.latitude, this.longitude);

        });
      });
    });

    this.serviceFilterForm = this.UbSearchfb.group({
      map_search_location: ''
    });


   

  }

  // Get Current Location Coordinates
  public setCurrentLocation() {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.latitude = position.coords.latitude;
        this.longitude = position.coords.longitude;
        this.saddresslatitude =  position.coords.latitude;
        this.saddresslongitude= position.coords.longitude;
        this.zoom = 20;
       // this.getAddress(this.latitude, this.longitude);
        this.getHospitalList(this.latitude, this.longitude);
        //this.getCovid19StatList(this.latitude, this.longitude);
      });
    } else {
      this.toastrService.info("geo Location not enabled");

    }
  }

  get ubMapFormControl() { return this.serviceFilterForm.controls; }

  requestTypeEventHandler(requestData: any) {
    this.request_data = requestData;
  }
  mapReady(event: any) {
    this.map = event;
    this.map.controls[google.maps.ControlPosition.RIGHT_CENTER].push(document.getElementById("getCurrentLocation"));
    this.map.controls[google.maps.ControlPosition.TOP_LEFT].push(document.getElementById("ubloodFilterSection"));
  }
  clickedMarker(infoWindow: any) {
    if (this.previousInfoWindow) {
      this.previousInfoWindow.close();
    }
    this.previousInfoWindow = infoWindow;
  }

  /***
  * 
  */
  searchHospital(post: any) {
    this.getFilteredHospitalList(this.latitude, this.longitude);
  }

  /***
   * 
   */
  getHospitalList(latitude: number, longitude: number) {
   
    this.toastrService.clear();
    if (latitude && longitude) {
      this.ngxUiLoaderService.start();
      //this.getAddress(this.latitude, this.longitude);
      let filterData = {"action":"hospitals","free_text":"","longitude":longitude,"latitude":latitude,"city_name":""}
      this.hospitalserive.filterHospitalDataList(filterData).subscribe(
        (filterDataList: any) => {
          if (filterDataList.data.length == 0) {
            this.toastrService.info("No Hospitals Found");
          }
          this.hospitalDataList = filterDataList.data;
          this.fitBounds = true;
          this.ngxUiLoaderService.stop();
        },
        (err) => {
          console.log(err)
          this.ngxUiLoaderService.stop();
        });
      this.ngxUiLoaderService.stop();
    }
    else {
      this.setCurrentLocation();
    }
    
   $('.hide_hosp_slid').trigger('click');

  
  }

/**
get navigate directions
 */

 getHospitalRoute(des_latitude:number,des_longitude:number){
  this.origin = { lat:des_latitude, lng: des_longitude };
  this.renderOptions = { polylineOptions: { strokeColor: '#f00' },suppressMarkers: true };

  let hospitaldirectionData =[];
    hospitaldirectionData['destination'] = { lat:this.latitude, lng: this.longitude };           
    hospitaldirectionData['markerOptions'] = {
           origin: {
               infoWindow: '<div class="ub-info-window-wrapper"><div class="donor-profile-content"><div class="donor-info-window-pic"><img src="" class="info-window-profile-pic" alt="" width="50">'+
                       '</div><div class="donor-info-window-data"><div class="infowin-ub-name"><i class="fa fa-user" aria-hidden="true"></i><span style="font-size: 15px;margin-bottom:5px;padding-left: 6px;font-weight:500"></span></div><div class="infowin-ub-name  phone-icon"></div><div class="infowin-ub-name  address-icon text-ellipsis"></div></div></div></div>',
               icon: '',
               draggable: false,
           },
           destination: {
             infoWindow: '<div class="ub-info-window-wrapper"><div class="donor-profile-content"><div class="donor-info-window-pic"><img src="" class="info-window-profile-pic"  alt="" width="50">'+
             '</div><div class="donor-info-window-data"><div class="infowin-ub-name"><i class="fa fa-user" aria-hidden="true"></i><span style="font-size: 15px;margin-bottom: 5px;padding-left: 6px;font-weight:500;"></span></div><div class="infowin-ub-name  phone-icon"><a href="tel:</a></div><div class="infowin-ub-name  address-icon text-ellipsis"></div></div></div></div>',
             icon: '',
             draggable: false,
           },
        }
        this.directionData.push(hospitaldirectionData);

 }

/**
 * get navigation location
 * @param latitude 
 * @param longitude 
 */
getNavigation(des_latitude:number,des_longitude:number){
let navigateUrl = 'http://maps.google.com/maps?saddr='+this.saddresslatitude+','+this.saddresslongitude+'&daddr='+des_latitude+','+des_longitude;
window.open(navigateUrl,'_blank');
}

  /**
  * 
  * @param latitude 
  * @param longitude 
  */
  getFilteredHospitalList(latitude: number, longitude: number) {
    this.toastrService.clear();
    if (latitude && longitude) {
      this.ngxUiLoaderService.start();
      let filterData = {"action":"hospitals","free_text":"","longitude":longitude,"latitude":latitude,"city_name":""}

      this.hospitalserive.filterHospitalDataList(filterData).subscribe(
        (filterDataList: any) => {
          if (filterDataList.data.length == 0) {
            this.toastrService.info("No Hospitals Found");
          }
          this.hospitalDataList = filterDataList.data;
          this.ngxUiLoaderService.stop();
        },
        (err) => {
          console.log(err)
          this.ngxUiLoaderService.start();
        });
      this.ngxUiLoaderService.stop();
    }
  }
}
