import { Component, OnInit } from '@angular/core';
import { Router,NavigationEnd } from '@angular/router';
declare var $:any;
@Component({
  selector: 'app-menu-header',
  templateUrl: './menu-header.component.html',
  styleUrls: ['./menu-header.component.css']
})
export class MenuHeaderComponent implements OnInit {

  constructor(private router: Router,) { }
  bodyTag: HTMLBodyElement = document.getElementsByTagName('body')[0];
    htmlTag: HTMLElement = document.getElementsByTagName('html')[0];
  ngOnInit() {
    if(localStorage.getItem("ubu_auth_token") && localStorage.getItem("ubu_auth_user")) {
     this.router.navigate(["/donor-map-view"]);
    }

    this.bodyTag.classList.add('font-ubuntu');
    this.htmlTag.classList.add('font-ubuntu');

    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
          return;
      }
      window.scrollTo(0, 0)
  });

    $(window).scroll(function() {
      if ($(this).scrollTop() > 120) {
        $('#toTopBtn').fadeIn();
      } else {
        $('#toTopBtn').fadeOut();
      }
    });
  
    $('#toTopBtn').click(function() {
      $("html, body").animate({
        scrollTop: 0
      }, 1000);
      return false;
    });
  }
  ngOnDestroy() {
    // remove the the body classes
    this.bodyTag.classList.remove('font-ubuntu');
    this.htmlTag.classList.remove('font-ubuntu');
  }
  hasRoute(route: string) {
    return this.router.url.includes(route);
  }

}
