import { Injectable } from '@angular/core';
import { HttpClientModule, HttpParams,HttpClient,HttpHeaders} from '@angular/common/http';
import { Observable } from 'rxjs';
import { AppSettings } from '../../../app.settings';
import { AppApiEndpoints } from '../../../app.api-endpoints';
import { mapData } from '../../models/map-data.model';
import { Router, NavigationEnd } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
@Injectable({
  providedIn: 'root'
})

export class DataService {
  reqHeader:any;
  reqHeader1:any;
  mapConfigData:mapData;
  auth_user_id:any;
  private previousUrl: string;
  private currentUrl: string;
  private bloodGroup = new BehaviorSubject('');
  currentBloodGroup= this.bloodGroup.asObservable();
  constructor(private httpClient: HttpClient, private _appSettings: AppSettings, private appapiEndpoint: AppApiEndpoints,private router: Router) { 
    this.reqHeader = new HttpHeaders({ 
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + localStorage.getItem('ubu_auth_token')
    });
  //   dtservice.itemValue.subscribe((nextValue) => {
  //     alert(nextValue);  // this will happen on every change
  //  })
    //console.log("latestID", JSON.parse(localStorage.getItem('ubu_auth_user')).user_id);

   // auth_user_data = (localStorage.getItem("ubu_auth_user"))? JSON.parse(localStorage.getItem("user_id")):0;
   // console.log("freshUID",this.reqHeader1.user_id);
    this.auth_user_id = JSON.parse(localStorage.getItem('ubu_auth_user')).user_id;
    this.currentUrl = this.router.url;
    router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {        
        this.previousUrl = this.currentUrl;
        this.currentUrl = event.url;
        this.auth_user_id = JSON.parse(localStorage.getItem('ubu_auth_user')).user_id;
        //console.log("freshUID",this.auth_user_id);
      };
    });
  }
 
  /**
   * 
   * @param data 
   */
  setMapData(data:mapData){
    this.mapConfigData = data;
  }
  
  /**
   * 
   */
  getMapData(){
   return this.mapConfigData;
  }
  
  getMetaDataEventList(): Observable<any>{
    let apiUrlGetMetadataList = this._appSettings.API_NAMESPACE + this.appapiEndpoint.getMetaDataList;    
    return this.httpClient.post(apiUrlGetMetadataList,{"metaData":["events"]});
    }
  getMetaDataBannerList(): Observable<any>{
    let apiUrlGetMetadataList = this._appSettings.API_NAMESPACE + this.appapiEndpoint.getMetaDataList;    
    return this.httpClient.post(apiUrlGetMetadataList,{"metaData":["social_banners_new"]});
    }
  getInfluencerDataList(): Observable<any>{
      let apiUrlGetMetadataList = this._appSettings.API_NAMESPACE + this.appapiEndpoint.getMetaDataList;    
      return this.httpClient.post(apiUrlGetMetadataList,{"metaData":["influencers"]});
    }

  getTweetList(): Observable<any>{
      let apiUrlGetMetadataList = this._appSettings.API_NAMESPACE + this.appapiEndpoint.getMetaDataList;    
      return this.httpClient.post(apiUrlGetMetadataList,{"metaData":["tweets"]});
  }
  getUserData(userID: any) : Observable<any>{
    const dataOption = {
      user_id: userID
    };
    let apiUrlGetuserProfileDetails = this._appSettings.API_NAMESPACE + this.appapiEndpoint.getUserDetails;
    return this.httpClient.post(apiUrlGetuserProfileDetails, JSON.stringify(dataOption), { headers: this.reqHeader });
  }
 
  filterUserDataList(filterData:any):Observable<any>{
    let apiUserDataList = this._appSettings.API_NAMESPACE + this.appapiEndpoint.serviceSearch;    
    return this.httpClient.post(apiUserDataList,filterData, { headers: this.reqHeader }); 
  }

  getRequestList(filterData:any):Observable<any>{
    filterData['user_id'] =  this.auth_user_id;
    let apiRequestDataList = this._appSettings.API_NAMESPACE + this.appapiEndpoint.getUbList;    
    return this.httpClient.post(apiRequestDataList,filterData, { headers: this.reqHeader }); 
  }

  getMyDonations(filterData:any):Observable<any>{
    filterData['user_id'] =  this.auth_user_id;
    let apiRequestDataList = this._appSettings.API_NAMESPACE + this.appapiEndpoint.getUbList;    
    return this.httpClient.post(apiRequestDataList,filterData, { headers: this.reqHeader }); 
  }


  getRequestTransaction(filterData:any):Observable<any>{
    filterData['user_id'] =  this.auth_user_id;
    let apiRequestTrnsDataList = this._appSettings.API_NAMESPACE + this.appapiEndpoint.getRequestTransaction;    
    return this.httpClient.post(apiRequestTrnsDataList,filterData , { headers: this.reqHeader }); 
  }

  getDonorTransaction(filterData:any):Observable<any>{
    filterData['user_id'] =  this.auth_user_id;
    let apiDonorTrnsDataList = this._appSettings.API_NAMESPACE + this.appapiEndpoint.getDonorTransactions;    
    return this.httpClient.post(apiDonorTrnsDataList,filterData , { headers: this.reqHeader }); 
  }

  getUBUStats():Observable<any>{
    let filterData = {"user_id": this.auth_user_id};
    let apiuserStatsList = this._appSettings.API_NAMESPACE + this.appapiEndpoint.userStatsCount;    
    return this.httpClient.post(apiuserStatsList,filterData, { headers: this.reqHeader }); 
  }
 
  createRequest(requestData:any):Observable<any>{
    let apiRequestService = this._appSettings.API_NAMESPACE + this.appapiEndpoint.requestService;    
    return this.httpClient.post(apiRequestService,requestData, { headers: this.reqHeader }); 
   } 
   getRequestDetails(requestData:any):Observable<any>{
    requestData["user_id"] =  this.auth_user_id;
    let apiRequestService = this._appSettings.API_NAMESPACE + this.appapiEndpoint.getRequestDetails;    
    return this.httpClient.post(apiRequestService,requestData, { headers: this.reqHeader }); 
   }

   requestActionService(requestData:any):Observable<any>{
    requestData["user_id"] =  this.auth_user_id;
    let apiRequestService = this._appSettings.API_NAMESPACE + this.appapiEndpoint.requestService;    
    return this.httpClient.post(apiRequestService,requestData, { headers: this.reqHeader }); 
   } 

   getNotifications(requestData:any):Observable<any>{
    requestData["user_id"] =  this.auth_user_id;
    let apiRequestService = this._appSettings.API_NAMESPACE + this.appapiEndpoint.getUbList;    
    return this.httpClient.post(apiRequestService,requestData, { headers: this.reqHeader }); 
   }

   public getPreviousUrl() {
    return this.previousUrl;
  } 

  saveDonation(requestData:any):Observable<any>{
    requestData["user_id"] =  this.auth_user_id;
    let apiRequestService = this._appSettings.API_NAMESPACE + this.appapiEndpoint.saveDonation;    
    return this.httpClient.post(apiRequestService,requestData, { headers: this.reqHeader }); 
   }

   changeBloodGroup(bloodGroup: string){
    this.bloodGroup.next(bloodGroup);
    
  }
   
}
