import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { Ubrequest } from '../../models/request.model';
import { Title } from '@angular/platform-browser';
declare var $: any;

@Component({
  selector: 'app-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.css']
})
export class PageNotFoundComponent implements OnInit {
  @Output() requestTypeEvent = new EventEmitter<any>();
  request_data: Ubrequest;
  constructor(private titleService :Title) {
    this.request_data = new Ubrequest();
   }

  ngOnInit() {
    this.titleService.setTitle("404 - Page Not Found - UBlood");
  }
  ubuSignUp() {
    this.request_data.request_type = "signup_request";
    this.requestTypeEvent.emit(this.request_data);
    $('#authenticateModalForm').modal('show');   
  }
}
