import { Component, OnInit, Input, ViewChild, ElementRef, NgZone } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { BehaviorSubject } from 'rxjs';
import { Ubrequest } from '../../../models/request.model';
import { AuthenticationService } from '../../../services/authentication/authentication.service';
import { NgbTypeahead } from '@ng-bootstrap/ng-bootstrap';
import { Observable, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map, merge } from 'rxjs/operators';
import { Router, ActivatedRoute } from '@angular/router';
import { MapsAPILoader, GoogleMapsAPIWrapper, AgmMap, LatLngBounds, LatLngBoundsLiteral } from '@agm/core';
declare var $: any

@Component({
  selector: 'app-signup-form',
  templateUrl: './signup-form.component.html',
  styleUrls: ['./signup-form.component.css']
})
export class SignupFormComponent implements OnInit {
  @Input() phone_number: number;
  @Input() request_data: Ubrequest;
  ubSignUpForm: FormGroup;
  isSignUpDone: boolean = false;
  @ViewChild('ubSLocation', { static: true }) public searchElementRef: ElementRef;
  @ViewChild('instance', { static: true }) instance: NgbTypeahead;
  focus$ = new Subject<string>();
  click$ = new Subject<string>();
  public model: any;
  noResult = false;
  public geoCoder;
  ubu_latitude: number;
  ubu_longitude: number;
  ubu_locality: string;
  ubu_address: string;

  bloodGroupListData = [
    { id: 1, name: 'A+' },
    { id: 2, name: 'A-' },
    { id: 3, name: 'B+' },
    { id: 4, name: 'B-' },
    { id: 5, name: 'AB+' },
    { id: 6, name: 'AB-' },
    { id: 7, name: 'O+' },
    { id: 8, name: 'O-' },
    { id: 9, name: 'A1+' },
    { id: 10, name: 'A1-' },
    { id: 11, name: 'A2+' },
    { id: 12, name: 'A2-' },
    { id: 13, name: 'A1B+' },
    { id: 14, name: 'A1B-' },
    { id: 15, name: 'A2B+' },
    { id: 16, name: 'A2B-' },
    { id: 17, name: 'Bombay Blood Group' },
    { id: 18, name: 'INRA' }
  ];
  constructor(
    private formBuilder: FormBuilder,
    private authentication: AuthenticationService,
    private toastrService: ToastrService,
    private ngxUIService: NgxUiLoaderService,
    private router: Router,
    private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone, ) { }

  ngOnInit() {
    this.ubSignUpForm = this.formBuilder.group({
      ubsp_first_name: ['', Validators.required],
      ubsp_last_name: ['', Validators.required],
      ubsp_email: ['', [
        Validators.required,
        Validators.email,
        Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$')]],
      ubsp_blood_group: ['', Validators.required],
      ubsp_location: ['', Validators.required],
    });

    // set Default Location
    this.setCurrentLocation();

    //OnChange Location
    this.mapsAPILoader.load().then(() => {

      this.geoCoder = new google.maps.Geocoder;
      let autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement);
      autocomplete.addListener("place_changed", () => {
        this.ngZone.run(() => {
          //get the place result
          let place: google.maps.places.PlaceResult = autocomplete.getPlace();
          //verify result
          if (place.geometry === undefined || place.geometry === null) {
            return;
          }
          //set latitude, longitude and zoom
          this.ubu_latitude = place.geometry.location.lat();
          this.ubu_longitude = place.geometry.location.lng();
          this.getAddress(this.ubu_latitude, this.ubu_longitude);


        });
      });
    });
  }


  // Get Current Location Coordinates
  private setCurrentLocation() {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.ubu_latitude = position.coords.latitude;
        this.ubu_longitude = position.coords.longitude;
        this.getAddress(this.ubu_latitude, this.ubu_longitude);
      });
    } else {
      this.toastrService.info("geolocation not enabled");
    }
  }

  get ubspFormControl() { return this.ubSignUpForm.controls; }

  /**
   * 
   * @param signUpData 
   */
  signUpUser(signUpData: any) {

    this.isSignUpDone = true;
    if (this.ubSignUpForm.invalid) {
      return false;
    }

    if ($("#termsAndConditionforSignup").prop("checked") !== true) {
      this.toastrService.info("Please accept the terms and Conditions");
      return false;
    }

    if (signUpData) {
      const authUserData = {
        "action": "donor",
        "user_id": this.request_data.request_user,
        "first_name": signUpData['ubsp_first_name'],
        "last_name": signUpData['ubsp_last_name'],
        "email": signUpData['ubsp_email'],
        "blood_group": signUpData['ubsp_blood_group'],
        "address": this.ubu_address,
        "locality": this.ubu_locality,
        "latitude": this.ubu_latitude,
        "longitude": this.ubu_longitude,
      };
      this.ngxUIService.start();
      this.authentication.ubuAuthenticate(authUserData)
        .subscribe(
          response => {
            if (response['status_code'] == 200) {
              this.ngxUIService.stop();
              this.toastrService.success('', "Welcome to Ublood " + response['data']['first_name']);
              localStorage.setItem("ubu_auth_token", response['data']['access_token']);
              localStorage.setItem("ubu_auth_user", JSON.stringify(response['data']));              
              $('#signUpModalForm').modal('hide');
              this.ngxUIService.stop();
              this.router.navigate(["/donor-map-view"]);
            }
            if (response['status_code'] == 201) {
              this.ngxUIService.stop();
              this.toastrService.info('', response['error']['message']);
            }
          }, error => {
            this.ngxUIService.stop();
            this.toastrService.info('', "Some thing went wrong");
          });
    }

  }

  /**
  * 
  * @param latitude 
  * @param longitude 
  */
  getAddress(latitude: number, longitude: number) {
    let _self = this;
    this.mapsAPILoader.load().then(() => {
      _self.geoCoder.geocode({ 'location': { lat: latitude, lng: longitude } }, (results: any, status: any) => {
        if (status === 'OK') {
          if (results[0].formatted_address) {
            _self.getDetailedAddress(results[0].formatted_address);
          } else {
            _self.toastrService.info('', 'No results found');
          }
        } else {
          console.log('', 'Geocoder failed due to: ' + status);
        }

      });
    });

  }

  getDetailedAddress(address: string) {
    let self = this;
    this.geoCoder.geocode({ 'address': address }, (results: any, status: any) => {
      if (status === 'OK') {

        let geo_address = "";
        let geo_locality = "";
        if (results[0].address_components) {

          $.each(results[0].address_components, function (key: any, value: any) {
            if ((value.types[0]) == 'subpremise' || (value.types[0]) == 'premise' || (value.types[0]) == 'route' || (value.types[0]) == 'political' || (value.types[0]) == 'street_number' || (value.types[0]) == 'neighborhood' || (value.types[0]) == 'administrative_area_level_4') {
              if (geo_address) {
                geo_address += ', ' + value.long_name;
              } else {
                geo_address = value.long_name
              }
              //Set Address 
              self.ubu_address = geo_address;
            }
            if ((value.types[0]) == 'locality') {
              if (geo_locality) {
                geo_locality += ', ' + value.long_name;
              } else {
                geo_locality = value.long_name
              }
            }

            if ((value.types[0]) == 'administrative_area_level_1') {
              if (geo_locality) {
                geo_locality += ', ' + value.long_name;
              } else {
                geo_locality = value.long_name
              }
            }

            //set Locality.
            self.ubu_locality = geo_locality;


          });

        } else {
          this.toastrService.info('', "No results found");
        }
      } else {
        // this.toastrService.warning('', "Geocoder failed due to: " + status);

      }

    });
  }
  /**
   * 
   */
  getbloodGroup = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      // merge(this.focus$),
      // merge(this.click$.pipe(filter(() => !this.instance.isPopupOpen()))),
      map((term) => {
       // console.log({ term });
        return term === ''
          ? this.bloodGroupListData
          : this.bloodGroupListData
            .filter(v => v.name.toLowerCase().indexOf(term.toLowerCase()) > -1)
            .slice(0, 10)
      })
    );

  bloodDataformatter = (x: { name: string }) => x.name;
  typeaheadNoResults(event: boolean): void {
    this.noResult = event;
  }

}
