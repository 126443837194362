import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { Ubrequest } from '../../models/request.model';
import { Router } from '@angular/router';

declare var $: any;

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  @Output() donorListChangeEventOnMap = new EventEmitter<any>();
  @Output() requestTypeEvent = new EventEmitter<any>();
  @Output() goToHomeView = new EventEmitter<any>();
  @Input() latitude: number;
  @Input() longitude: number;
  request_data: Ubrequest;
  auth_user_data: any;
  filteredDonorList: any;
  constructor(private router: Router) {
    this.request_data = new Ubrequest();
  }

  ngOnInit() {
    var _self = this;
    if (localStorage.getItem("ubu_auth_token")) {
      if (localStorage.getItem("ubu_auth_user")) {
        this.auth_user_data = JSON.parse(localStorage.getItem("ubu_auth_user"));
        if (this.auth_user_data['is_accept_tc']) {
          this.router.navigate(["/dashboard"]);
        }
      }
    } else {
      setTimeout(function () {
        if (!$("#authenticateModalForm").hasClass('in') && !$("#otpModalForm").hasClass('in') && !$("#signUpModalForm").hasClass('in')) {
          _self.request_data.request_type = "signup_request";
          _self.requestTypeEvent.emit(_self.request_data);
          $('#authenticateModalForm').modal('show');
        }
      }, 10000);
    }
    //load Places Autocomplete
    //this.userData = localStorage.getItem("ubu_auth_token");


  }

  ubuSignUp() {
    this.request_data.request_type = "signup_request";
    this.requestTypeEvent.emit(this.request_data);
    $('#authenticateModalForm').modal('show');
  }

  ubuCovidPlasmaSignUp(){
    this.request_data.request_type = "plasma_signup_request";
    this.requestTypeEvent.emit(this.request_data);
    this.router.navigate(["/login"]);
  }

  /*goToHomePage(){
    this.goToHomeView.emit(true);
   }*/

  goToHomePage() {
    this.router.navigate(["/"]);
  }


  donorListEventHandler(mapData: any) {
   // this.donorListChangeEventOnMap.emit(mapData);
  }
}
