import { Component, OnInit } from '@angular/core';
import { trigger, transition, animate, style, state } from '@angular/animations';
import { Router } from '@angular/router';
declare var $: any

@Component({
  selector: 'app-tour-guide',
  templateUrl: './tour-guide.component.html',
  styleUrls: ['./tour-guide.component.css'],
  animations: [
    trigger('slideInOut', [
      transition(':enter', [
        animate('500ms ease-in', style({ 'transition-timing-function': 'linear', 'transition-duration': '500ms', transform: 'translate3d(0px, 0px, 0px)' }))
      ]),

    ])
  ]
})
export class TourGuideComponent implements OnInit {
  tourStep: number = 1;
  constructor(private router: Router) { }

  ngOnInit() {
    //load Places Autocomplete
    //this.userData = localStorage.getItem("ubu_auth_token");
    if (localStorage.getItem("ubu_auth_token")) {
      this.router.navigate(["/donor-map-view"]);
    } else {
      this.openTourGuide();
    }

  }

  /**
   * 
   */
  openTourGuide() {
    $(document).ready(function () {
      $("#ubloodTourGuide").modal('show');
    });
  }

  /**
   * 
   * @param tourStep 
   */
  openTourGuideStep(tourStep: number) {
    console.log(tourStep);
    this.tourStep = tourStep;
  }

}
