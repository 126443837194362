import { Component, OnInit, Input } from '@angular/core';
declare var $: any;
declare var Swiper: any;
@Component({
  selector: 'app-user-layout',
  templateUrl: './user-layout.component.html',
  styleUrls: ['./user-layout.component.css']
})
export class UserLayoutComponent implements OnInit {
  @Input() sideNavActivated: boolean = false;
  isSidebarToggled: boolean = false;
  constructor() { }

  ngOnInit() {
   
  }
  sideBarToggle(isToggled: boolean) {    
    this.isSidebarToggled = isToggled;
  
    setTimeout(() => {
    var swiper1 = new Swiper('.bottom_view_slid .slider_m1 .swiper-container', {
      slidesPerView: 1,
      spaceBetween: 5,
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
      breakpoints: {
        
        768: {
          slidesPerView: 2,
         
        },
        991: {
          slidesPerView:3,
         
        },
      }
    
    });

    // Dashboard slider
    var swiper2 = new Swiper('.blood_req', {
      slidesPerView: 1,
      spaceBetween: 5,
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
      breakpoints: {
        
        768: {
          slidesPerView: 2,
         
        },
        991: {
          slidesPerView:4,
         
        },
      }
    
    });
    // Dashboard Slider End
  }, 500);
  }
}
