import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { BehaviorSubject } from 'rxjs';
import { Ubrequest } from '../../../../app/shared/models/request.model';
import { AuthenticationService } from '../../../../app/shared/services/authentication/authentication.service';

declare var $: any;
@Component({
  selector: 'app-cowin-login',
  templateUrl: './cowin-login.component.html',
  styleUrls: ['./cowin-login.component.css']
})
export class CowinLoginComponent implements OnInit {

    // Search the tags in the DOM
    bodyTag: HTMLBodyElement = document.getElementsByTagName('body')[0];
    htmlTag: HTMLElement = document.getElementsByTagName('html')[0];

  @Input() request_data: Ubrequest;
  ubAuthForm: FormGroup;
  isAuthFormSubmitted: boolean = false;
  phone_number: number;
  otp_timer_start: boolean = false;



  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private authentication: AuthenticationService,
    private toastrService: ToastrService,
    private ngxUIService: NgxUiLoaderService) { 
      this.request_data = new Ubrequest(); 
    }

  ngOnInit() {
    localStorage.clear();
    this.bodyTag.classList.add('login-page');
    this.htmlTag.classList.add('login-page');
    this.otp_timer_start = false;
    this.ubAuthForm = this.formBuilder.group({
      uba_std_code: [Validators.required, Validators.min(1)],
      //uba_phone_number: ['',[Validators.required, Validators.pattern(/^(?:\d{10}|\w+@\w+\.\w{2,3})$/)]],    
      uba_phone_number: ['', [Validators.required, Validators.pattern(/^[6-9]\d{9}$/)]],

    });
      this.ubAuthForm.controls['uba_std_code'].setValue('+91');
  }
  ngOnDestroy() {
    // remove the the body classes
    this.bodyTag.classList.remove('login-page');
    this.htmlTag.classList.remove('login-page');
  }
  get ubAuthFormControl() { return this.ubAuthForm.controls; }


  authenticateUser(post: any) {
    this.request_data.request_phone = post['uba_phone_number'];
    this.isAuthFormSubmitted = true;
    if (this.ubAuthForm.invalid) {
      this.toastrService.info('', "Please enter valid phone number");
      return false;
    } 
    if (post) {
      this.ngxUIService.start();
      const verifyUser = {
        "action": "verify_user",
       "phone": post['uba_phone_number'],
      };
      const authUserData = {
        "action": "sendotp",
        "std_code": post['uba_std_code'],
        "phone": post['uba_phone_number'],
      };
      this.ngxUIService.start();

     // this.authentication.ubuplasmaAutheticate(authUserData)
     this.authentication.ubuAuthenticate(verifyUser)
        .subscribe(
          response => {
            if (response['status_code'] == 200) {
             this.verifiedUser(authUserData);
            //  this.toastrService.success('', response['success']['message']);
            }else if(response['status_code'] == 201){
              this.toastrService.info('', response['success']['message']);
             // this.router.navigate(["/blood/donor-signup"]);
             this.router.navigate(["/cowin/register"]);
              this.ngxUIService.stop();
            }
          }, error => {
            this.ngxUIService.stop();
            this.toastrService.info('', "Some thing went wrong");
          });
    } else {
      this.toastrService.info('', "Please enter mobile number");
    }

  }

  verifiedUser(authUserData:any){
    this.authentication.ubuAuthenticate(authUserData)
    .subscribe(
      response => {
        if (response['status_code'] == 200){
          this.ngxUIService.stop();
          this.toastrService.success('', response['success']['message']);
          this.otp_timer_start = true;
        }
      }, error => {
        this.ngxUIService.stop();
        this.toastrService.info('', "Some thing went wrong");
      });
  }

  numberChangedHandler(phoneNumberChanged: boolean) {
    this.otp_timer_start = phoneNumberChanged;    
  }

  numberOnly(event:any): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }


}
