import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { User } from '../../models/user.model';
import { AppSettings } from '../../../app.settings';
import { AppApiEndpoints } from '../../../app.api-endpoints';


@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  private currentUserSubject: BehaviorSubject<User>;
  public currentUser: Observable<User>;
  redirectUrl: string;
  reqHeader: any;


  constructor(private http: HttpClient, private appSettings: AppSettings, private appapiEndpoint: AppApiEndpoints) {
    // console.log(localStorage.getItem('jm_auth_user'));
    this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('ubu_auth_user')));
    this.currentUser = this.currentUserSubject.asObservable();
    this.reqHeader = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + localStorage.getItem('ubu_auth_token')
    });
  }
  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }
  
  isAuthUserRole() {
    return !!localStorage.getItem("ubu_auth_token");
  }
  isUserLoggedIn() {
    return !!localStorage.getItem("ubu_auth_token");
  }
 /**
  * 
  * @param data 
  */
 ubuAuthenticate(data: any):Observable<any>{
    let authenticateAPIURL =this.appSettings.API_NAMESPACE + this.appapiEndpoint.userAuthentication;
    return this.http.post(authenticateAPIURL, JSON.stringify(data), { headers: this.reqHeader });
  }
  ubuplasmaAutheticate(data: any):Observable<any>{
    let authenticateAPIURL =this.appSettings.API_NAMESPACE + this.appapiEndpoint.plasmaUserAuth;
    return this.http.post(authenticateAPIURL, JSON.stringify(data), { headers: this.reqHeader });
  }

  /***
   * Verify USaver
   */
  verifyUsaver(data: any):Observable<any>{
    let verifyUsaverAPIURL =this.appSettings.API_NAMESPACE + this.appapiEndpoint.verifyUsaver;
    return this.http.post(verifyUsaverAPIURL, JSON.stringify(data), { headers: this.reqHeader });
  }


  
 

}
