import { Component, OnInit } from '@angular/core';
import { SwiperOptions } from 'swiper';
import { Observable } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Router, ActivatedRoute } from '@angular/router';
import { DataService } from '../../services/user-data/data.service';
declare var $: any;

@Component({
  selector: 'app-my-service-request-list',
  templateUrl: './my-service-request-list.component.html',
  styleUrls: ['./my-service-request-list.component.css']
})
export class MyServiceRequestListComponent implements OnInit {

  isOpenRequestDataExist: boolean = false;
  isClosedRequestDataExist: boolean = false;
  myOpenBloodRequestList: any;
  myClosedBloodRequestList: any;
  currentRequestId: number = 0;
  requestTransactionData: any;
  transactionDonorsList: any;

  constructor(private dataService: DataService,
    private toastrService: ToastrService, private ngxUiLoaderService: NgxUiLoaderService, private router: Router) { }

  ngOnInit() {
    this.getMyOpenRequestList();
    this.getMyClosedRequestList();
  }

  openRequestExpandCollapse(event: any, request_id: number) {
    this.getRequestTransactionDetails(request_id);
    $(event.target).parents('.show_more_set').find('.progress_cont').fadeToggle();
    $(event.target).parents('.show_more_set').find('.show_m_link').toggleClass('active');
  }

  closedRequestExpandCollapse(event: any, request_id: number) {
    this.getRequestTransactionDetails(request_id);
    $(event.target).parents('.show_more_set').find('.progress_cont').fadeToggle();
    $(event.target).parents('.show_more_set').find('.show_m_link').toggleClass('active');
  }
  /**
   * 
   */
  getMyOpenRequestList() {
    const myRequestDataFilter = {
      "action": "self_opening",
      "page": 1,
      "per_page": 100
    };
    this.ngxUiLoaderService.start();
    this.dataService.getRequestList(myRequestDataFilter)
      .subscribe(
        myDataList => {
          if (myDataList.status_code == 200) {
            if (myDataList.data.requests.length > 0) {
              this.isOpenRequestDataExist = true;
            }
            this.myOpenBloodRequestList = myDataList.data.requests;
          } else {
            this.isOpenRequestDataExist = false;
            this.myOpenBloodRequestList = [];
          }
          this.ngxUiLoaderService.stop();
        });
  }
  /***
   * 
   */
  getMyClosedRequestList() {
    const myRequestDataFilter = {
      "action": "self_closed",
      "page": 1,
      "per_page": 100
    };
    this.ngxUiLoaderService.start();
    this.dataService.getRequestList(myRequestDataFilter)
      .subscribe(
        myDataList => {
          if (myDataList.status_code == 200) {
            if (myDataList.data.requests.length > 0) {
              this.isClosedRequestDataExist = true;
            }
            this.myClosedBloodRequestList = myDataList.data.requests;
          } else {
            this.isClosedRequestDataExist = false;
            this.myClosedBloodRequestList = [];
          }
          this.ngxUiLoaderService.stop();
        });
  }

  cancelMyRequest(request_id: number) {
    this.currentRequestId = request_id;
    $("#cancelReasonForm").modal('show');
  }
  /**
     * 
     * @param request_id 
     */
  submitCancelRequest() {
    let _self = this;
    if ($("input[name='cancelreason']:checked").val() == 4 && $("#cancelreasonComments").val().length < 3) {
      this.toastrService.info('', 'Please enter the comments for cancel request');
      return false;
    }
    const reason: number = ($("input[name='cancelreason']:checked").val()) ? $("input[name='cancelreason']:checked").val() : 1;
    const comments: string = ($("#cancelreasonComments").val()) ? $("#cancelreasonComments").val() : "no comments";
    const dataFilterAction = { "action": "receiver_is_cancelled", "request_id": this.currentRequestId, "reason": reason, "comment": comments }
    this.ngxUiLoaderService.start();
    this.dataService.requestActionService(dataFilterAction).subscribe(
      response => {
        if (response.status_code == "200") {
          _self.getMyOpenRequestList();
          _self.getMyClosedRequestList();
          this.ngxUiLoaderService.stop();
          this.toastrService.success('', response['success']['message']);
          $("#cancelReasonForm").modal('hide');
        }
        if (response.status_code == "201") {
          this.ngxUiLoaderService.stop();
          this.toastrService.info('', response['error']['message']);
        }

      }, error => {
        this.ngxUiLoaderService.stop();
        this.toastrService.info('', "Some thing went wrong");
      });

  }

  closeCancelRequest() {
    $("#cancelReasonForm").modal('hide');
  }

  editMyRequest(request_id: number) {
    this.currentRequestId = request_id;
  }

  getRequestTransactionDetails(request_id: number) {
    this.ngxUiLoaderService.start();
    const transactionFilter = { "request_id": request_id };
    this.dataService.getRequestTransaction(transactionFilter).subscribe(
      (filterDataList: any) => {
        if (filterDataList.data.length == 0) {
          this.transactionDonorsList = [];
        } else {
          console.log(filterDataList.data[0].accepted_donors);
          this.transactionDonorsList = filterDataList.data[0].accepted_donors;
        }
        this.ngxUiLoaderService.stop();
      },
      (err) => {
        console.log(err)
        this.ngxUiLoaderService.stop();
      });
  }

  trackTransactionOnMap(request_id: number) {
    this.router.navigate(["/request-map-view", "MOR_" + request_id]);
  }
  tab_recive(id: any) {
    $('#demo' + id).toggle();
  }

  /**
 * 
 * @param req_id 
 */
  isReceived(req_id: number, donorId: number) {

    console.log("request_id", req_id, "donorIDis", donorId);
    // return false;
    let _self = this;
    let auth_user_id = JSON.parse(localStorage.getItem('ubu_auth_user')).user_id;
    this.ngxUiLoaderService.start();

    const dataFilterAction = { "action": "receiver_is_recieved", "user_id": auth_user_id, "request_id": req_id, "donors": [{ "donor_id": donorId, "status": 1 }], "comment": "tested" }
    this.dataService.requestActionService(dataFilterAction).subscribe(
      response => {
        if (response.status_code == "200") {
          //  _self.getRequestDetails(req_id);
          _self.getRequestTransactionDetails(req_id);
          //   console.log();
          $('#' + donorId).text('Received');
          // $('#demo'+donorId).toggle();
          this.ngxUiLoaderService.stop();
          this.toastrService.success('', response['success']['message']);
        }
        if (response.status_code == "201") {
          this.ngxUiLoaderService.stop();
          this.toastrService.info('', response['error']['message']);
        }

      }, error => {
        this.ngxUiLoaderService.stop();
        this.toastrService.info('', "Some thing went wrong");
      });

  }


}
