import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-refer-a-donor',
  templateUrl: './refer-a-donor.component.html',
  styleUrls: ['./refer-a-donor.component.css']
})
export class ReferADonorComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
