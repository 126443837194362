import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-advisor',
  templateUrl: './advisor.component.html',
  styleUrls: ['./advisor.component.css']
})
export class AdvisorComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
