import { Component, OnInit } from '@angular/core';
import { connectableObservableDescriptor } from 'rxjs/internal/observable/ConnectableObservable';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Router, ActivatedRoute } from '@angular/router';
import { DataService } from '../../services/dataService/data.service';
declare var $: any;
@Component({
  selector: 'app-videogallery',
  templateUrl: './videogallery.component.html',
  styleUrls: ['./videogallery.component.css']
})
export class VideogalleryComponent implements OnInit {
  ubinfluencers = [];
  galleryItems: any;
  constructor(
    private dataService: DataService,
    private toastrService: ToastrService,
    private ngxUiLoaderService: NgxUiLoaderService,
    private router: Router,
    private route: ActivatedRoute,
  ) { }

  ngOnInit() {
    this.ngxUiLoaderService.start();
    this.dataService.geInfluencerDataList()
      .subscribe(
        dataList => {
          this.ubinfluencers = dataList.data.influencers;
          console.log(this.ubinfluencers);
          this.getInfluencers(this.ubinfluencers);
          this.ngxUiLoaderService.stop();


        });


  }
  getInfluencers(getInfluencers: any) {
    getInfluencers.forEach(function (item) {
      let newlabel = item.label;
      item.label = newlabel.split('-');
    });
    this.galleryItems = getInfluencers;
    // console.log(this.finalubinfluencers);
  }




}
