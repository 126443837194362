import { Component, OnInit, Input } from '@angular/core';
declare var $: any;

@Component({
  selector: 'app-map-view-layout',
  templateUrl: './map-view-layout.component.html',
  styleUrls: ['./map-view-layout.component.css']
})
export class MapViewLayoutComponent implements OnInit {
  @Input() sideNavActivated: boolean = false;
  isSidebarToggled: boolean = false;
  constructor() { }

  ngOnInit() {
  }
  sideBarToggle(isToggled: boolean) {    
    this.isSidebarToggled = isToggled;
  }
}