import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { Component, OnInit, ViewChild, ElementRef, NgZone, Output,Input, EventEmitter } from '@angular/core';
import { DatePipe } from '@angular/common'
import { MapsAPILoader, MouseEvent } from '@agm/core';
import { ToastrService } from 'ngx-toastr';
import { FlatpickrOptions } from 'ng2-flatpickr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { DataService } from '../../../../../services/user-data/data.service';
import { ManageProfileService } from '../../../../../services/ub-user/manage-profile/manage-profile.service';
import { NgbTypeahead } from '@ng-bootstrap/ng-bootstrap';
import { Observable, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map, merge } from 'rxjs/operators';
declare var $: any;


@Component({
  selector: 'app-location-info',
  templateUrl: './location-info.component.html',
  styleUrls: ['./location-info.component.css']
})
export class LocationInfoComponent implements OnInit {
  @Output() locationInfoUpdated: EventEmitter<boolean> = new EventEmitter();
  @Input() userProfileData:any;
  profileLocationInfoForm: FormGroup;
  isLocationInfoSubmitted:boolean = false;
  private geoCoder;
  latitude: number;
  longitude: number;
  zoom: number;
  address: string;
  zoomControlOptions: any;
  map: any;

  userProfileLocation: {
    address: string,
    city: string,
    state: string,
    country: string,
    zipCode: number
  }

  @ViewChild('searchLocation', { static: true }) public searchElementRef: ElementRef;

  constructor(private formBuilder: FormBuilder, private dataService: DataService, private toastrService: ToastrService, private ngxUiService: NgxUiLoaderService, private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone, private datePipe: DatePipe, private manageProfileService: ManageProfileService) {

  }

  ngOnInit() {
    this.profileLocationInfoForm = this.formBuilder.group({
        gmap_address: [''],
        ubu_address: [''],
        ubu_city: [''],
        ubu_state: [''],
        ubu_country: [''],
        ubu_zipcode: ['',[Validators.required, Validators.pattern(/^[1-9][0-9]{5}$/)]],
        ubu_latitude: ['', Validators.required],
        ubu_longitude: ['', Validators.required]
    });
   
   /* if (!this.latitude || !this.longitude) {
      var _self = this;
      $.getJSON('http://ip-api.com/json', function (data: any) {
        _self.latitude = data.lat;
        _self.longitude = data.lon;
        _self.getAddress(_self.latitude, _self.longitude);
      });
    }*/

  }
  
  mapReady(event: any) {
    this.map = event;
    this.mapsAPILoader.load().then(() => {
      this.setCurrentLocation();
      this.zoomControlOptions = { position: google.maps.ControlPosition.RIGHT_CENTER };
      this.geoCoder = new google.maps.Geocoder;

      let autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement);
      autocomplete.addListener("place_changed", () => {
        this.ngZone.run(() => {
          //get the place result
          let place: google.maps.places.PlaceResult = autocomplete.getPlace();

          //verify result
          if (place.geometry === undefined || place.geometry === null) {
            return;
          }
          //set latitude, longitude and zoom
          this.latitude = place.geometry.location.lat();
          this.longitude = place.geometry.location.lng();
         // console.log(this.latitude,  this.longitude );
          this.getAddress(this.latitude, this.longitude);
          this.zoom = 15;      

        });
      });
    });
    // this.map.controls[google.maps.ControlPosition.LEFT_TOP].push(document.getElementById("mapSearchLocationBox"));
    // this.map.setCenter(new google.maps.LatLng(this.latitude, this.longitude));
  }
  /**
   * 
   * @param $event 
   */
  markerDragEnd($event: MouseEvent) {
    this.latitude = $event.coords.lat;
    this.longitude = $event.coords.lng;
    this.getAddress(this.latitude, this.longitude);
  }

  // Get Current Location Coordinates
  public setCurrentLocation() {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.latitude = position.coords.latitude;
        this.longitude = position.coords.longitude;
        this.zoom = 15;
        this.getAddress(this.latitude, this.longitude);
      });
    }
  }

  /**
   * 
   * @param latitude 
   * @param longitude 
   */
  getAddress(latitude: number, longitude: number) {
    let self = this;
    this.geoCoder.geocode({ 'location': { lat: latitude, lng: longitude } }, (results: any, status: any) => {
      if (status === 'OK') {        
        if (results[0].formatted_address) {
          this.zoom = 12;
          self.profileLocationInfoForm.get('ubu_latitude').setValue('');
          self.profileLocationInfoForm.get('ubu_longitude').setValue('');
          self.profileLocationInfoForm.get('ubu_latitude').setValue(results[0].geometry.location.lat());
          self.profileLocationInfoForm.get('ubu_longitude').setValue(results[0].geometry.location.lng());
          //  this.address = results[0].formatted_address;
          this.profileLocationInfoForm.get('gmap_address').setValue(results[0].formatted_address);
         // console.log(results[0].formatted_address);
          this.getDetailedAddress(results[0].formatted_address);
        } else {
          window.alert('No results found');
        }
      } else {
        window.alert('Geocoder failed due to: ' + status);
      }

    });

  }

  getDetailedAddress(address: string) {
    let self = this;
    this.geoCoder.geocode({ 'address': address }, (results: any, status: any) => {
      if (status === 'OK') {
        this.resetLocationForm();
        let geo_address = "";
        if (results[0].address_components) {
          $.each(results[0].address_components, function (key: any, value: any) {
            //console.log(value.types[0]);
            if ((value.types[0]) == 'subpremise' || (value.types[0]) == 'premise' || (value.types[0]) == 'route' || (value.types[0]) == 'political' || (value.types[0]) == 'street_number' || (value.types[0]) == 'neighborhood' || (value.types[0]) == 'administrative_area_level_4') {
              if (geo_address) {
                geo_address += ', ' + value.long_name;
              } else {
                geo_address = value.long_name
              }
              self.profileLocationInfoForm.get('ubu_address').setValue(geo_address);
            }

            if ((value.types[0]) == 'locality') {
              self.profileLocationInfoForm.get('ubu_city').setValue(value.long_name);
            }

            if ((value.types[0]) == 'administrative_area_level_1') {
              self.profileLocationInfoForm.get('ubu_state').setValue(value.long_name);
            }

            if ((value.types[0]) == 'country') {
              self.profileLocationInfoForm.get('ubu_country').setValue(value.long_name);
            }

            if ((value.types[0]) == 'postal_code') {
              self.profileLocationInfoForm.get('ubu_zipcode').setValue(value.long_name);
            }
          });

        } else {
          this.toastrService.info('', "No results found");
        }
      } else {
       // this.toastrService.warning('', "Geocoder failed due to: " + status);

      }

    });
  }
  /**
   * 
   */
  resetLocationForm() {
    this.profileLocationInfoForm.get('ubu_address').setValue('');
    this.profileLocationInfoForm.get('ubu_city').setValue('');
    this.profileLocationInfoForm.get('ubu_state').setValue('');
    this.profileLocationInfoForm.get('ubu_country').setValue('');
    this.profileLocationInfoForm.get('ubu_zipcode').setValue('');
  }

  get ubuLocationProfileInfo() { 
    const invalid = [];
    const controls = this.profileLocationInfoForm.controls;
   // console.log(this.profileLocationInfoForm.controls);
    for (const name in controls) {
        if (controls[name].invalid) {
            invalid.push(name);

        }
      }
      console.log(invalid);
      return 
     // return this.profileLocationInfoForm.controls;
  
  }

  updateProfileLocationInfo(locationPost: any) {
    this.isLocationInfoSubmitted = true;
    
    // if (this.profileLocationInfoForm.invalid) {
    //     this.toastrService.info('', 'Enter the location or Drag the marker on map to select your location');
    //     return false;
    // }
    this.ngxUiService.start();
    if (locationPost) {
      let userLocationData = {
        "action": "address",
        "address": locationPost['ubu_address'],
        "city": locationPost['ubu_city'],
        "state": locationPost["ubu_state"],
        "country": locationPost["ubu_country"],
        "zipcode": locationPost["ubu_zipcode"],
        "latitude": locationPost["ubu_latitude"],
        "longitude": locationPost["ubu_longitude"]
      }
      this.manageProfileService.updateProfileData(userLocationData)
        .subscribe(
          response => {
            if (response['status_code'] == 200) {
              this.toastrService.success('', response['success'].message);
              $('#profileLocationInfoModal .close').trigger('click');
              this.locationInfoUpdated.emit(response['status_code']);
              this.ngxUiService.stop();
            } else {
              this.toastrService.info('', response['error'].message);
            }
            this.ngxUiService.stop();
          }, error => {
            this.toastrService.info('', "Some thing went wrong");
            this.ngxUiService.stop();
          });
      this.ngxUiService.stop();
    }
  }

}
