import { Component, OnInit, ComponentFactoryResolver } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Validators, ValidatorFn, AbstractControl, FormControl, FormGroup,FormArray, FormBuilder } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { InviteFriendsService } from '../../services/ub-misc/invite-friends/invite-friends.service';
//import { $ } from 'protractor';
declare var $:any;

@Component({
  selector: 'app-refer-a-friend',
  templateUrl: './refer-a-friend.component.html',
  styleUrls: ['./refer-a-friend.component.css']
})
export class ReferAFriendComponent implements OnInit {
  inviteFriendsForm: FormGroup;
  isRBFormSubmitted = false;
  isInviteRTEmail: boolean = true;
  isInviteRTSMS: boolean = false;
  isIRFormatCSVEmail: boolean = true;
  isIRFormatCSVSMS: boolean = true;
  isIRFormatCSVFileEmail: boolean = false;
  isIRFormatCSVFileSMS: boolean = false;
  isIRFormatSocialImpEmail: boolean = false;
  isIRFormatSocialImpSMS: boolean = false;
  referelCodde:any;
  referelMsg:any;
  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private toastr: ToastrService,
    private ngxService: NgxUiLoaderService,
    private inviteFriendsService: InviteFriendsService

  ) { }

  ngOnInit() {
    this.getUserDetails();
    this.inviteFriendsForm = this.formBuilder.group({
      'invitation':this.formBuilder.array([
        this.addInviteFormGroup()
      ])
    });   
 
  
    this.isInviteRTEmail = true;
  }
  
  refer_code(inputElement:any){
    $("#myTooltip").text("copied");
    inputElement.select();
    document.execCommand('copy',false);
    inputElement.setSelectionRange(0, 0);
  }
  leave(){
    $("#myTooltip").text("Copy Code");
  }
  addButtonInvite(): void{
    
    (<FormArray>this.inviteFriendsForm.get('invitation')).push(this.addInviteFormGroup());
  }

  addInviteFormGroup(): FormGroup{

    return this.formBuilder.group({
      "name": ['', Validators.required],
      "phone": ['',[Validators.required, Validators.pattern(/^[6-9]\d{9}$/)]],
      "email": ['', [
        //Validators.required,
        Validators.email,
        Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$')]],
    });
  }

  getUserDetails() {
    var baseUrl = window.location.origin;
    this.ngxService.start();
    this.inviteFriendsService.getUserData(JSON.parse(localStorage.getItem('ubu_auth_user')).user_id)
      .subscribe(
        (userProfileData: any) => {
          this.referelCodde = userProfileData['data'].referalcode;
          this.referelMsg = baseUrl+'/blood/donor-signup?referral-code='+this.referelCodde;
         // this.referelMsg = userProfileData['data'].referalcode_msg;
          this.ngxService.stop();
        });
  }

  get brInviteFriendsFields() { return this.inviteFriendsForm.controls; }

  onSubmit(post: any) {
    this.isRBFormSubmitted = true;
    if (this.inviteFriendsForm.invalid) {
      this.toastr.warning('', "Please enter all required fields with valid data");
      return false;
    }
    this.ngxService.start();
    post['user_id']=JSON.parse(localStorage.getItem('ubu_auth_user')).user_id;
  //  console.log(JSON.stringify(post));
    this.inviteFriendsService.inviteFriend(post).subscribe(
      (response: any) => {
       this.ngxService.stop();
        if (response['status_code'] == 200) {
          this.toastr.success('', response['success']['message']);  
          this.inviteFriendsForm.reset();
        }
        if (response['status_code'] == 201) {
          this.ngxService.stop();
          this.toastr.info('', response['error']['message']);
        }
      }, error => {
        this.ngxService.stop();
        this.toastr.info('', "Some thing went wrong");
      });

  }
  inviteTypeRequestHandler(event) {
    this.resetInviteFormatHandler();
    if (event.target.defaultValue === "email") {
      this.isInviteRTEmail = true;
      this.isInviteRTSMS = false;
      this.isIRFormatCSVEmail = true;
      this.isIRFormatCSVSMS = false;

    } else {
      this.isInviteRTSMS = true;
      this.isInviteRTEmail = false;
      this.isIRFormatCSVSMS = true;
      this.isIRFormatCSVEmail = false;
    }

  }

  inviteFormatRequestHandler(event) {

    this.resetInviteFormatHandler();

    console.log(event.target.defaultValue);
    if (event.target.defaultValue === "ecsv") {
      this.isIRFormatCSVEmail = true;
    }
    else if (event.target.defaultValue === "ecsvf") {
      this.isIRFormatCSVFileEmail = true;
    }

    else if (event.target.defaultValue === "esa") {
      this.isIRFormatSocialImpEmail = true;
    }


    if (event.target.defaultValue === "scsv") {
      this.isIRFormatCSVSMS = true;
    }
    else if (event.target.defaultValue === "scsvf") {
      this.isIRFormatCSVFileSMS = true;
    }

    else if (event.target.defaultValue === "ssa") {
      this.isIRFormatSocialImpSMS = true;
    }


  }

  resetInviteFormatHandler() {
    this.isIRFormatCSVEmail = false;
    this.isIRFormatCSVSMS = false;
    this.isIRFormatCSVFileEmail = false;
    this.isIRFormatCSVFileSMS = false;
    this.isIRFormatSocialImpEmail = false;
    this.isIRFormatSocialImpSMS = false;

  }
  
  remove(index){
    const control = <FormArray>this.inviteFriendsForm.get('invitation'); 
     if(control.length != 1){
         control.removeAt(index);
      }    
  }
  numberOnly(event:any): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }
}
