import { Injectable } from '@angular/core';
import { HttpClientModule, HttpParams,HttpClient,HttpHeaders} from '@angular/common/http';
import { Observable } from 'rxjs';
import { AppSettings } from '../../../../app.settings';
import { AppApiEndpoints } from '../../../../app.api-endpoints';
import {mapData} from '../../../models/map-data.model';
import { Router, NavigationEnd } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class BloodbankService {

  reqHeader:any;
  mapConfigData:mapData;
  auth_user_data = (localStorage.getItem("ubu_auth_user"))? JSON.parse(localStorage.getItem("ubu_auth_user")):0;
  private previousUrl: string;
  private currentUrl: string;
  constructor(private httpClient: HttpClient, private _appSettings: AppSettings, private appapiEndpoint: AppApiEndpoints,private router: Router) { 
    this.reqHeader = new HttpHeaders({ 
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + localStorage.getItem('ubu_auth_token')
    });
    
    this.currentUrl = this.router.url;
    router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {        
        this.previousUrl = this.currentUrl;
        this.currentUrl = event.url;
      };
    });
  }

  filterBloodBankDataList(filterData:any):Observable<any>{
    let apiUserDataList = this._appSettings.API_NAMESPACE + this.appapiEndpoint.serviceList;    
    return this.httpClient.post(apiUserDataList,filterData, { headers: this.reqHeader }); 
  }
}

