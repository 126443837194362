import { Component, OnInit, ViewChild, ElementRef, NgZone, Output, Input } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { MapsAPILoader, GoogleMapsAPIWrapper, AgmMap, LatLngBounds, LatLngBoundsLiteral } from '@agm/core';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Observable, Subject } from 'rxjs';
import { DataService } from '../../../services/dataService/data.service';
import { DatePipe } from '@angular/common'
import { FlatpickrOptions } from 'ng2-flatpickr';
import { Ubrequest } from '../../../models/request.model';
import { AuthenticationService } from '../../../services/authentication/authentication.service';
import { ReferrerCodeService } from '../../../services/referrer-code.service';
declare var $: any;

@Component({
  selector: 'app-covid-request',
  templateUrl: './covid-request.component.html',
  styleUrls: ['./covid-request.component.css']
})
export class CovidRequestComponent implements OnInit {

  @Input() request_data: Ubrequest;
  ubPlasmaRequestForm: FormGroup;
  sendotpForm: FormGroup;
  isSignUpDone: boolean = false;
  isAuthFormSubmitted: boolean = false;
  phone_number: number;
  otp_timer_start: boolean = false;
  isPlasmaRequestSubmit: boolean = false;
  bloodGroupDataList: any = [];
  referenceDataList:any=[];
  @ViewChild('ubr_location', { static: true }) public searchRequestElement: ElementRef;
  map: google.maps.Map;
  fitBounds: boolean = false;
  dlatitude: number;
  dlongitude: number;
  rlatitude: number;
  rlongitude: number;
  Donorlocation: any = {}
  DonorCity: string;
  DonorState: string;
  DonorCountry: string;
  DonorZip: string;
  Requestlocation: string;
  rcity: string;
  rstate: string;
  //public makeDate = new Date();
  dateTimeFormatConfig: any = {}
  dateTimeFormatConfigAge: any = {}
  public imgPath;
  imgURL: any;
  userId: any;
  message: string;
  plasmaRequestData:any;
  receiverMobile:any;
  isvalidRefCode:any;
  subject = new Subject<string>();
  private geoCoder;
  constructor(
    private formBuilder: FormBuilder,
    private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone,
    private ngZoneR: NgZone,
    private router: Router,
    private dataService: DataService,
    private toastrService: ToastrService,
    private ngxUiLoaderService: NgxUiLoaderService,
    private datePipe: DatePipe,
    private auth: AuthenticationService,
    private referrerCode : ReferrerCodeService) {

  }

  ngOnInit() {

    this.dataService.changeMessage("requester");
    this.dataService.currentMessage.subscribe(message => this.message = message);
    this.addPlasmaRequestForm();
    this.sendotpForm = this.formBuilder.group({
      uba_otp_number: ['', [Validators.required, Validators.pattern(/\d{4}/)]],
    });
    this.getBloodGroups();
    this.getReferencesList();
    this.userId = localStorage.getItem("ub-c19-userid");
    var makeDate = new Date();
    makeDate.setFullYear(makeDate.getFullYear() - 18);
    var newDate = makeDate;
    console.log(makeDate);
    this.dateTimeFormatConfigAge = {
      mode: 'single',
      enableTime: false,
      dateFormat: "d-m-Y",
      maxDate: new Date()
    };
    this.dateTimeFormatConfig = {
      mode: 'single',
      enableTime: false,
      dateFormat: "d-m-Y",
      minDate: new Date()
    }
    this.mapsAPILoader.load().then(() => {
      this.geoCoder = new google.maps.Geocoder;
      let autocomplete = new google.maps.places.Autocomplete(this.searchRequestElement.nativeElement);
      autocomplete.addListener("place_changed", () => {

        this.ngZoneR.run(() => {
          let placeR: google.maps.places.PlaceResult = autocomplete.getPlace();
          if (placeR.geometry === undefined || placeR.geometry === null) {
            return;
          }
          this.Requestlocation = placeR.name;
          console.log(this.Requestlocation);
          this.rlatitude = placeR.geometry.location.lat();
          this.rlongitude = placeR.geometry.location.lng();

          this.getAddress(this.rlatitude, this.rlongitude);
          console.log(this.rlongitude);
        });
      });

    });

  }

  /***
   * 
   */

  addPlasmaRequestForm() {
    this.ubPlasmaRequestForm = this.formBuilder.group({
      ubr_name: ['', [Validators.required]],
      ubr_lname : ['', [Validators.required]],
      ubr_attend_name: ['', [Validators.required]],
      ubr_attend_lname :['', [Validators.required]],
      ubr_attend_mobile: ['', [Validators.required, Validators.pattern(/^[6-9]\d{9}$/)]],
      ubr_blood_group: ['', [Validators.required]],
      ubr_request_age: ['', [Validators.required]],
      ubr_request_date: ['', [Validators.required]],
      ubr_donate_loc: ['', [Validators.required]],
      ubr_request_doc: ['', [Validators.required]],
      ubr_location: ['', [Validators.required]],
      ubr_city: [''],
      ubr_state: [''],
      ubr_pincode: [''],
      ubr_notes: [''],
      ubr_refcode: ['']
    });
  }

  getBloodGroups() {
    return this.bloodGroupDataList = [
      { id: 1, name: 'A+' },
      { id: 2, name: 'A-' },
      { id: 3, name: 'B+' },
      { id: 4, name: 'B-' },
      { id: 5, name: 'AB+' },
      { id: 6, name: 'AB-' },
      { id: 7, name: 'O+' },
      { id: 8, name: 'O-' },
      { id: 9, name: 'A1+' },
      { id: 10, name: 'A1-' },
      { id: 11, name: 'A2+' },
      { id: 12, name: 'A2-' },
      { id: 13, name: 'A1B+' },
      { id: 14, name: 'A1B-' },
      { id: 15, name: 'A2B+' },
      { id: 16, name: 'A2B-' },
      { id: 17, name: 'Bombay Blood Group' },
      { id: 18, name: 'INRA' }
    ];

  }

  
  getReferencesList() {
    return this.referenceDataList = [
      { id: 1, name: 'Hospital' },
      { id: 2, name: 'Advertisement' },
      { id: 3, name: 'Reference' },
      { id: 4, name: 'Others' }   
    ];

    
  }

  getAddress(latitude: number, longitude: number) {
    let self = this;
    this.geoCoder.geocode({ 'location': { lat: latitude, lng: longitude } }, (results: any, status: any) => {
      if (status === 'OK') {        
        if (results[0].formatted_address) {
          this.getDetailedAddress(results[0].formatted_address);
        } else {
          window.alert('No results found');
        }
      } else {
        window.alert('Geocoder failed due to: ' + status);
      }

    });

  }

  // get deatils Address

  getDetailedAddress(address: string) {
    let self = this;
    this.geoCoder.geocode({ 'address': address }, (results: any, status: any) => {
      if (status === 'OK') {
      
        let geo_address = "";
        if (results[0].address_components) {
          $.each(results[0].address_components, function (key: any, value: any) {
           
            if ((value.types[0]) == 'subpremise' || (value.types[0]) == 'premise' || (value.types[0]) == 'route' || (value.types[0]) == 'political' || (value.types[0]) == 'street_number' || (value.types[0]) == 'neighborhood' || (value.types[0]) == 'administrative_area_level_4') {
              if (geo_address) {
                geo_address += ', ' + value.long_name;
              } else {
                geo_address = value.long_name
              }
             
            }

            if ((value.types[0]) == 'locality') {
              self.ubPlasmaRequestForm.get('ubr_city').setValue(value.long_name);
             
            }

            if ((value.types[0]) == 'administrative_area_level_1') {
              self.ubPlasmaRequestForm.get('ubr_state').setValue(value.long_name);
             
            }

            if ((value.types[0]) == 'postal_code') {
              self.ubPlasmaRequestForm.get('ubr_pincode').setValue(value.long_name);
             
            }
          });

        } else {
          this.toastrService.info('', "No results found");
        }
      } else {
       // this.toastrService.warning('', "Geocoder failed due to: " + status);

      }

    });
  }


  docForm(event: any) {
    let files = event.target.files;
    if (files.length === 0) {
      return;
    }
    var sFileName = files[0].name;
    var sFileExtension = sFileName.split('.')[sFileName.split('.').length - 1].toLowerCase();
    var iFileSize = files[0].size;
    var iConvert = (iFileSize / 1048576).toFixed(2);
    if (!(sFileExtension === "png" ||
      sFileExtension === "jpeg" ||
      sFileExtension === "jpg") || iFileSize > 1048576) { /// 1 mb
      var txt = "File type : " + sFileExtension + "\n\n";
      txt += "Size: " + iConvert + " MB \n\n";
      txt += "Please make sure your file is in image format and less than 1 MB.\n\n";
      this.toastrService.warning('', txt);

    } else {
      var reader = new FileReader();
      this.imgPath = files;
      reader.readAsDataURL(files[0]);
      reader.onload = (_event) => {
        this.imgURL = reader.result;
      }

    }
  }

  /**
   * 
   * Refercode validation
   */
  checkRefercode(code:any){
    var str= code.target.value;
      if(str.length==6){  
        this.referrerCode.validRefCode(str).subscribe(data => {
          console.log(data);
        this.isvalidRefCode=data;
        });
      }else if(str.length<6){
            $('.valid_m1').removeClass('valid_val').addClass('not_valid_val');
      }
      if(str.length==0) {
       // console.log("innnnnn");
        $('.valid_m1').removeClass('not_valid_val valid_val');
      }
  }
  get ubrFormControl() { 
   
    return this.ubPlasmaRequestForm.controls;
  
  }

  covidPlasmaRequest(postRequest: any) {
    this.isPlasmaRequestSubmit = true;
    if (this.ubPlasmaRequestForm.invalid) {

     // const invalid = [];
       // const controls = this.ubPlasmaRequestForm.controls;
        // for (const name in controls) {
        //     if (controls[name].invalid) {
        //         console.log(name);
        //        // invalid.push(name);
        //     }
        // }
        //return invalid;
    
      console.log("invalid");
      return false;
    }
   /* if(postRequest["ubr_refcode"]){
      if(this.isvalidRefCode!=1){
        this.toastrService.error("Invalid referral code. Please enter valid code");
        $('.valid_m1').removeClass('valid_val').addClass('not_valid_val');
        return false;
      }
    }*/
    
    if ($("#ubpr_termsAndCondition").prop("checked") !== true) {
      this.toastrService.info("Please accept the terms and Conditions");
      return false;
    }

    if(this.rlatitude == undefined){
      this.toastrService.error("Invalid Address. Please enter google suggested locations");
      return false;
    }

    //console.log(postRequest);
    if (this.imgURL == undefined) {
      this.imgURL = '';
    }
    this.ngxUiLoaderService.start();
    if(postRequest){
      this.receiverMobile = postRequest["ubr_attend_mobile"];
      this.plasmaRequestData = {
        action: "create_service_request",
        is_request_for_other: 0,
        service_type: 3,
        blood_group: postRequest["ubr_blood_group"],
        contact_name: postRequest["ubr_attend_name"],
        contact_last_name: postRequest["ubr_attend_lname"],
        contact_number: postRequest["ubr_attend_mobile"],
        units: 1,
        required_date: this.datePipe.transform(postRequest["ubr_request_date"][0], "yyyy-MM-dd hh:mm"),
        location_for_donation: postRequest["ubr_donate_loc"],
        address: this.Requestlocation,
        latitude: this.rlatitude,
        longitude: this.rlongitude,
        is_emergency: true,
        is_share_on_sm: true,
        doctor_requisition_form: this.imgURL,
        patient_name: postRequest["ubr_name"],
        patient_last_name : postRequest["ubr_lname"],
        patient_age: this.datePipe.transform(postRequest["ubr_request_age"][0], "yyyy-MM-dd hh:mm"),
        is_plasma_request: 1,
        city: postRequest["ubr_city"],
        state: postRequest["ubr_state"],
        country: "India",
        zipcode: postRequest["ubr_pincode"], 
        comment: postRequest["ubr_notes"],
        referral_code: "",
        referred_from : postRequest["ubr_refcode"]
      }
    //  $('#myModal').modal('show');
   
    this.dataService.changeMobile(this.receiverMobile);
    this.dataService.changeName( postRequest["ubr_attend_name"]);
    this.dataService.changeEmail('');
      this.SendOtp(this.receiverMobile);
    }

  }

  get ubAuthFormControl() { return this.sendotpForm.controls; }

  SendOtp(mobile:any) {    
//   console.log(mobile);
    if (mobile>0) {
      const authUserData = {
        "action": "sendotp",
        "std_code": "+91", 
        "phone": mobile,        
      };      
     // console.log('jall',authUserData);
      this.ngxUiLoaderService.start();
      this.auth.ubuplasmaAutheticate(authUserData)
        .subscribe(
          response => {
            if (response['status_code'] == 200) {
              this.ngxUiLoaderService.stop();
              //$('#myModal').modal('hide');
              this.toastrService.success('', response['success']['message']);
              $('#otpModalUI').modal('show');
               this.otp_timer_start =true;    
            //  console.log(response.data);    
              this.ngxUiLoaderService.stop(); 
            }
          }, error => {
            this.ngxUiLoaderService.stop();
            this.toastrService.info('', "Some thing went wrong");
          });
    } else {
      this.toastrService.info('', "Please enter mobile number");
    }

  }

  numberOnly(event: any): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }
  keyPressAlphaNumeric(event) {
    var inp = String.fromCharCode(event.keyCode);

    if (/[a-zA-Z]/.test(inp)) {
      return true;
    } else {
      event.preventDefault();
      return false;
    }
  }

}
