import { Component, OnInit, ViewChild, ElementRef, NgZone,EventEmitter, Output } from '@angular/core';
import { DataService } from '../../services/user-data/data.service';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { MapsAPILoader, GoogleMapsAPIWrapper, AgmMap, LatLngBounds, LatLngBoundsLiteral } from '@agm/core';
import { Observable } from 'rxjs';
import { Routes,Router, RouterModule,ActivatedRoute } from '@angular/router';
import { debounceTime, distinctUntilChanged, filter, map, merge } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { NgbTypeahead } from '@ng-bootstrap/ng-bootstrap';
import { SwiperOptions } from 'swiper';
declare var $: any

@Component({
  selector: 'app-service-request-map-view',
  templateUrl: './service-request-map-view.component.html',
  styleUrls: ['./service-request-map-view.component.css']
})
export class ServiceRequestMapViewComponent implements OnInit {
  requestTransactionData:any;
  requestUserData:any;
  acceptedDonorsData:any
  isSidebarToggled = false;
  isSignUpSubmitted = false;
  latitude: number;
  longitude: number;
  zoom: number =12;
  userLocation: Location;
  mapCenter: any;
  userId: any;
  map: google.maps.Map;
  fitBounds: boolean = false;
  zoomControlOptions: any;
  phonenumber: any;
  noResult = false;

  userData: any;
  user_id: number;
  isToggled:boolean;


  @ViewChild('AgmMap', { static: true }) agmMap: AgmMap;
  @Output() sidebarToggleEvent: EventEmitter<boolean> = new EventEmitter();

  configRecentBloodRequest: SwiperOptions = {
    pagination: { el: '.swiper-pagination', clickable: true },
    slidesPerGroup: 1,
    slidesPerView: 1,
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev'
    },
    spaceBetween: 50
  };

  public origin: any;
  public destination: any;
  public renderOptions:any;
  public markerOptions:any
  public directionData:any=[];

  constructor(
    private dataService: DataService,
    private ngxUiLoaderService: NgxUiLoaderService,
    private toastrService: ToastrService,
    private router :Router ,
    private route: ActivatedRoute,    
    private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone,
    
  ) { }

  ngOnInit() {
    this.userData = JSON.parse(localStorage.getItem("ub_auth_user"));
    this.mapsAPILoader.load().then(() => {
      this.setCurrentLocation();
      this.zoomControlOptions = { position: google.maps.ControlPosition.RIGHT_CENTER };
    });
    this.route.params.subscribe(params => {
     this.ngxUiLoaderService.start();
      let request_id = params.requestId.split('_')[1];
      if(params.requestId.split('_')[0]=="MOR"){
      const otherRequestDataFilter = {"request_id": request_id};
      this.dataService.getRequestTransaction(otherRequestDataFilter)
          .subscribe(
           transactionData => {
            if (transactionData.status_code == 200) {
              if (transactionData.data.length > 0) {
             this.requestUserData = transactionData.data[0]; 
             this.acceptedDonorsData = this.requestUserData.accepted_donors;
             this.origin = { lat:this.requestUserData.latitude, lng: this.requestUserData.longitude };
             this.renderOptions = { polylineOptions: { strokeColor: '#f00' },suppressMarkers: true };
             if( this.acceptedDonorsData.length>0 ){
             this.acceptedDonorsData.forEach((donorData,index) => {
             let donordirectionData =[];
             donordirectionData['destination'] = { lat:donorData.latitude, lng: donorData.longitude };           
             donordirectionData['markerOptions'] = {
              origin: {
                  infoWindow: '<div class="ub-info-window-wrapper"><div class="donor-profile-content"><div class="donor-info-window-pic"><img src="'+this.requestUserData.profile_pic+'" class="info-window-profile-pic" alt="" width="50">'+
                          '</div><div class="donor-info-window-data"><div class="infowin-ub-name"><i class="fa fa-user" aria-hidden="true"></i><span style="font-size: 15px;margin-bottom:5px;padding-left: 6px;font-weight:500">'+this.requestUserData.contact_name+'</span></div><div class="infowin-ub-name  phone-icon"><a href="tel:'+this.requestUserData.contact_number+'">'+this.requestUserData.contact_number+'</a></div><div class="infowin-ub-name  address-icon text-ellipsis">'+this.requestUserData.address+'</div></div></div></div>',
                  icon: this.requestUserData.ublood_icon,
                  draggable: false,
              },
              destination: {
                infoWindow: '<div class="ub-info-window-wrapper"><div class="donor-profile-content"><div class="donor-info-window-pic"><img src="'+donorData.profile_pic+'" class="info-window-profile-pic"  alt="" width="50">'+
                '</div><div class="donor-info-window-data"><div class="infowin-ub-name"><i class="fa fa-user" aria-hidden="true"></i><span style="font-size: 15px;margin-bottom: 5px;padding-left: 6px;font-weight:500;">'+donorData.name+'</span></div><div class="infowin-ub-name  phone-icon"><a href="tel:'+donorData.phone+'">'+donorData.phone+'</a></div><div class="infowin-ub-name  address-icon text-ellipsis">'+donorData.address+'</div></div></div></div>',
                icon: donorData.ublood_icon,
                draggable: false,
              },
           }
           this.directionData.push(donordirectionData);           
        })
        }
             this.ngxUiLoaderService.stop();
        }else{
           this.ngxUiLoaderService.stop();
           this.router.navigate(["/my-request-list"]);
        }}
           });
          }
          else if(params.requestId.split('_')[0]=="OAR"){
            const otherRequestDataFilter = {"request_id": request_id};
            this.dataService.getDonorTransaction(otherRequestDataFilter)
                .subscribe(
                 transactionData => {
                  if (transactionData.status_code == 200) {
                    if (transactionData.data.length > 0) {
                   this.requestUserData = transactionData.data[0]; 
                   this.acceptedDonorsData = this.requestUserData.accepted_donors;
                   this.origin = { lat:this.requestUserData.latitude, lng: this.requestUserData.longitude };
                   this.renderOptions = { polylineOptions: { strokeColor: '#f00' },suppressMarkers: true };
                   if( this.acceptedDonorsData.length>0 ){
                   this.acceptedDonorsData.forEach((donorData,index) => {
                   let donordirectionData =[];
                   donordirectionData['destination'] = { lat:donorData.latitude, lng: donorData.longitude };           
                   donordirectionData['markerOptions'] = {
                    origin: {
                        infoWindow: '<div class="ub-info-window-wrapper"><div class="donor-profile-content"><div class="donor-info-window-pic"><img src="'+this.requestUserData.profile_pic+'" class="info-window-profile-pic" alt="" width="50">'+
                                '</div><div class="donor-info-window-data"><div class="infowin-ub-name"><i class="fa fa-user" aria-hidden="true"></i><span style="font-size: 15px;margin-bottom:5px;padding-left: 6px;font-weight:500">'+this.requestUserData.contact_name+'</span></div><div class="infowin-ub-name  phone-icon"><a href="tel:'+this.requestUserData.contact_number+'">'+this.requestUserData.contact_number+'</a></div><div class="infowin-ub-name  address-icon text-ellipsis">'+this.requestUserData.address+'</div></div></div></div>',
                        icon: this.requestUserData.ublood_icon,
                        draggable: false,
                    },
                    destination: {
                      infoWindow: '<div class="ub-info-window-wrapper"><div class="donor-profile-content"><div class="donor-info-window-pic"><img src="'+donorData.profile_pic+'" class="info-window-profile-pic"  alt="" width="50">'+
                      '</div><div class="donor-info-window-data"><div class="infowin-ub-name"><i class="fa fa-user" aria-hidden="true"></i><span style="font-size: 15px;margin-bottom: 5px;padding-left: 6px;font-weight:500;">'+donorData.name+'</span></div><div class="infowin-ub-name  phone-icon"><a href="tel:'+donorData.phone+'">'+donorData.phone+'</a></div><div class="infowin-ub-name  address-icon text-ellipsis">'+donorData.address+'</div></div></div></div>',
                      icon: donorData.ublood_icon,
                      draggable: false,
                    },
                 }
                 this.directionData.push(donordirectionData);           
              })
              }
                   this.ngxUiLoaderService.stop();
              }else{
                 this.ngxUiLoaderService.stop();
                 this.router.navigate(["/request-list"]);
              }}
                 });
          }
           this.ngxUiLoaderService.stop();
    });
    
        
  }


  // Get Current Location Coordinates
  private setCurrentLocation() {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.latitude = position.coords.latitude;
        this.longitude = position.coords.longitude;
        this.zoom = 20;
       
        
      });
    } else {
      this.toastrService.info("geolocation not enabled");

    }
  }

  


  mapReady(event: any) {
    this.map = event;
    //  this.map.controls[google.maps.ControlPosition.RIGHT_CENTER].push(document.getElementById("getCurrentLocation"));
    ///  this.map.controls[google.maps.ControlPosition.LEFT_TOP].push(document.getElementById("ubloodFilterSection"));
    //  this.map.controls[google.maps.ControlPosition.TOP_CENTER].push(document.getElementById("ubLogoUp"));
      this.map.controls[google.maps.ControlPosition.BOTTOM_CENTER].push(document.getElementById("requestTransactionOnMap"));
    //  this.map.controls[google.maps.ControlPosition.TOP_RIGHT].push(document.getElementById("headerMenu"));
    // this.map.setCenter(new google.maps.LatLng(this.latitude, this.longitude));
  }

  sideBarToggle(isToggled: boolean) { 
    this.isSidebarToggled = isToggled;
  }
  
}
interface Point {
	lat: number;
	lng: number;
}