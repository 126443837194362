import { Component, OnInit } from '@angular/core';
import { DataService } from '../../services/user-data/data.service';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.css']
})
export class NotificationComponent implements OnInit {
  userData: any;
  allnotifications:any;
  request_id:any;
  constructor( private dataService: DataService, private toastrService: ToastrService, private ngxUiLoaderService: NgxUiLoaderService) { }

  ngOnInit() {
    this.userData = JSON.parse(localStorage.getItem("ubu_auth_user"));

    const data = {
      "action":"all_notifications",
      "user_id":this.userData.user_id
      };  
        this.dataService.getNotifications(data)
        .subscribe(
          response=>{
            this.allnotifications = response['data']['notifications']; 
            console.log(this.allnotifications);
          },error =>{
            console.log("Some thing went wrong please try again");  
       }); 
  }

}
