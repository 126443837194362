import { Injectable } from '@angular/core';
import { HttpClientModule, HttpParams, HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AppSettings } from '../../../app.settings';
import { AppApiEndpoints } from '../../../app.api-endpoints';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DataService {
  getMetaDataList1() {
    throw new Error('Method not implemented.');
  }
  reqHeader: any;
  private messageSource = new BehaviorSubject('');
  private mobilenum = new BehaviorSubject(0);
  private nameSource = new BehaviorSubject('');
  private emailSource = new BehaviorSubject('');
  private userId = new BehaviorSubject(0);
  currentMessage = this.messageSource.asObservable();
  currentMobile = this.mobilenum.asObservable();
  currentName = this.nameSource.asObservable();
  currentEmail = this.emailSource.asObservable();
  currentUserId = this.userId.asObservable();

  constructor(private httpClient: HttpClient, private _appSettings: AppSettings, private appapiEndpoint: AppApiEndpoints) {
    this.reqHeader = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + localStorage.getItem('ubu_auth_token')
    });

    //  console.log(localStorage.getItem('ubu_auth_token'));

  }
  getMetaDataList(): Observable<any> {
    let apiUrlGetMetadataList = this._appSettings.API_NAMESPACE + this.appapiEndpoint.getMetaDataList;
    return this.httpClient.post(apiUrlGetMetadataList, { "metaData": ["categories"] });
  }
  getMetStateList1(): Observable<any> {
    let apiUrlGetMetadataList = this._appSettings.API_NAMESPACE + this.appapiEndpoint.getMetaDataList;
    console.log('API URL:', apiUrlGetMetadataList); // Log the API URL
    return this.httpClient.post(apiUrlGetMetadataList, { "metaData": ["states"] });
  }

  geInfluencerDataList(): Observable<any> {
    let apiUrlGetMetadataList = this._appSettings.API_NAMESPACE + this.appapiEndpoint.getMetaDataList;
    return this.httpClient.post(apiUrlGetMetadataList, { "metaData": ["influencers"] });
  }
  getTweetList(): Observable<any> {
    let apiUrlGetMetadataList = this._appSettings.API_NAMESPACE + this.appapiEndpoint.getMetaDataList;
    return this.httpClient.post(apiUrlGetMetadataList, { "metaData": ["tweets"] });
  }
  getUserDataList(): Observable<any> {
    let apiUrlGetMetadataList = this._appSettings.API_NAMESPACE + this.appapiEndpoint.serviceSearch;
    return this.httpClient.get(apiUrlGetMetadataList, { headers: this.reqHeader });
  }

  filterUserDataList(filterData: any): Observable<any> {
    let apiUserDataList = this._appSettings.API_NAMESPACE + this.appapiEndpoint.serviceSearch;
    return this.httpClient.post(apiUserDataList, filterData, { headers: this.reqHeader });
  }

  createRequest(requestData: any): Observable<any> {
    this.reqHeader = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + localStorage.getItem('ubu_auth_token')
    });
    let apiRequestService = this._appSettings.API_NAMESPACE + this.appapiEndpoint.requestService;
    return this.httpClient.post(apiRequestService, requestData, { headers: this.reqHeader });
  }

  createplasmaRequest(requestData: any): Observable<any> {
    this.reqHeader = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + localStorage.getItem('ubu_auth_token')
    });
    let apiRequestService = this._appSettings.API_NAMESPACE + this.appapiEndpoint.plasmaRequest;
    return this.httpClient.post(apiRequestService, requestData, { headers: this.reqHeader });
  }


  filterCovid19DataList(filterData: any): Observable<any> {
    let apiUserDataList = this._appSettings.API_NAMESPACE + this.appapiEndpoint.searchCovid19StatsData;
    return this.httpClient.post(apiUserDataList, filterData);
  }

  getBloodRequestDataList(filterData: any): Observable<any> {
    let apiUserDataList = this._appSettings.API_NAMESPACE + this.appapiEndpoint.getBloodRequestDetails;
    return this.httpClient.post(apiUserDataList, filterData);
  }


  saveUsaverFeedback(filterData: any): Observable<any> {
    let apiUserDataList = this._appSettings.API_NAMESPACE + this.appapiEndpoint.saveUsaverFeedback;
    return this.httpClient.post(apiUserDataList, filterData);
  }

  changeMessage(message: string) {
    this.messageSource.next(message)
  }

  changeMobile(mobile: number) {
    this.mobilenum.next(mobile);
  }

  changeName(name: string) {
    this.nameSource.next(name);
  }

  changeEmail(email: string) {
    this.emailSource.next(email);
  }

  changeUserId(userId: number) {
    this.userId.next(userId);
  }



}

