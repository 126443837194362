import { Component, OnInit, ViewChild, ElementRef, NgZone, Output, Input } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { MapsAPILoader, GoogleMapsAPIWrapper, AgmMap, LatLngBounds, LatLngBoundsLiteral } from '@agm/core';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { BehaviorSubject } from 'rxjs';
import { DataService } from '../../../services/dataService/data.service';
import { DatePipe } from '@angular/common'
import { FlatpickrOptions } from 'ng2-flatpickr';
import { Observable, Subject } from 'rxjs';
import { Ubrequest } from '../../../models/request.model';
import { AuthenticationService } from '../../../services/authentication/authentication.service';
import { ReferrerCodeService } from '../../../services/referrer-code.service';
declare var $: any;

@Component({
  selector: 'app-covid-donor',
  templateUrl: './covid-donor.component.html',
  styleUrls: ['./covid-donor.component.css']
})
export class CovidDonorComponent implements OnInit {
  @Input() request_data: Ubrequest;
  ubPlasmDonorForm: FormGroup;
  sendotpForm: FormGroup;
  isSignUpDone: boolean = false;
  isAuthFormSubmitted: boolean = false;
  phone_number: number;
  otp_timer_start: boolean = false;
  isPlasmaDonorSubmit: boolean = false;
  bloodGroupDataList: any = [];
  referenceDataList:any =[];
  @ViewChild('ubd_location', { static: true }) public searchElementRef: ElementRef;
  map: google.maps.Map;
  fitBounds: boolean = false;
  dlatitude: number;
  dlongitude: number;
  rlatitude: number;
  rlongitude: number;
  Donorlocation: any = {}
  DonorCity: string;
  DonorState: string;
  DonorCountry: string;
  DonorZip: string;
  Requestlocation: string;
  rcity: string;
  rstate: string;
  //public makeDate = new Date();
  dateTimeFormatConfig: any = {};
  dateTimeFormatConfigAge: any = {};
  dateTimeFormatConfigDose: any = {};
  public imgPath;
  imgURL: any;
  userId: any;
  message: string;
  private geoCoder;
  donorData: any;
  donorMobile: any;
  isValidDate: any;
  isValidCowinDate:any;
  isValidMobile: any;
  isValidemail: any;
  isvalidRefCode: any;
  cvDoseForm: boolean = false;
  subject = new Subject<string>();
  constructor(
    private formBuilder: FormBuilder,
    private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone,
    private ngZoneR: NgZone,
    private router: Router,
    private dataService: DataService,
    private toastrService: ToastrService,
    private ngxUiLoaderService: NgxUiLoaderService,
    private datePipe: DatePipe,
    private auth: AuthenticationService,
    private referrerCode: ReferrerCodeService) {

  }

  ngOnInit() {
    this.dataService.changeMessage("donor");
    this.dataService.currentMessage.subscribe(message => this.message = message);
    this.addPlasmaDonorForm();
    this.sendotpForm = this.formBuilder.group({
      uba_otp_number: ['', [Validators.required, Validators.pattern(/\d{4}/)]],
    });
    this.getBloodGroups();
    this.getReferencesList();
    // this.userId= localStorage.getItem("ub-c19-userid");
    var makeDate = new Date();
    makeDate.setFullYear(makeDate.getFullYear() - 18);
    var newDate = makeDate;
    this.dateTimeFormatConfigAge = {
      mode: 'single',
      enableTime: false,
      //  dateFormat: "Y-m-d H:i",
      dateFormat: "d-m-Y",
      maxDate: newDate
    };

    this.dateTimeFormatConfigDose = {
      mode: 'single',
      enableTime: false,
      dateFormat: "d-m-Y",
      maxDate: new Date()
    };

    this.dateTimeFormatConfig = {
      mode: 'single',
      enableTime: false,
      //  dateFormat: "Y-m-d H:i",
      dateFormat: "d-m-Y",
      maxDate: new Date()
    }
    this.mapsAPILoader.load().then(() => {
      this.geoCoder = new google.maps.Geocoder;
      let autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement);
      autocomplete.addListener("place_changed", () => {
        this.ngZone.run(() => {
          //get the place result
          let place: google.maps.places.PlaceResult = autocomplete.getPlace();
          // let placeR: google.maps.places.PlaceResult = autocomplete1.getPlace();
          //verify result
          if (place.geometry === undefined || place.geometry === null) {
            return;
          }
          //set latitude, longitude and zoom
          this.Donorlocation = place.name;
          //  this.DonorCity=;
          // this.DonorState:string;
          //this.DonorCountry:string;
          //this.DonorZip:string;
         // console.log(this.Donorlocation);
         // console.log(this.Donorlocation);
          this.dlatitude = place.geometry.location.lat();
          this.dlongitude = place.geometry.location.lng();
        });
      });


    });
  }



  /***
   * 
   */
  addPlasmaDonorForm() {
    this.ubPlasmDonorForm = this.formBuilder.group({
      ubd_positive_datetime: ['', [Validators.required]],
      ubd_negative_datetime: ['', [Validators.required]],
      ubd_name: ['', [Validators.required]],
      ubd_lname: ['', [Validators.required]],
      ubd_mobile: ['', [Validators.required, Validators.pattern(/^[6-9]\d{9}$/)]],
      ubd_email: ['', [
        Validators.required,
        Validators.email,
        Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
      ubd_age: ['', [Validators.required]],
      isCheck: ['1'],
      isCVCheck: ['0'],
      cv_dose_one: [''],
      cv_dose_two: [''],
      ubd_blood_group: ['', [Validators.required]],
      ubd_location: ['', [Validators.required]],
      ubd_refcode: ['']
    });
  }


  getBloodGroups() {
    return this.bloodGroupDataList = [
      { id: 1, name: 'A+' },
      { id: 2, name: 'A-' },
      { id: 3, name: 'B+' },
      { id: 4, name: 'B-' },
      { id: 5, name: 'AB+' },
      { id: 6, name: 'AB-' },
      { id: 7, name: 'O+' },
      { id: 8, name: 'O-' },
      { id: 9, name: 'A1+' },
      { id: 10, name: 'A1-' },
      { id: 11, name: 'A2+' },
      { id: 12, name: 'A2-' },
      { id: 13, name: 'A1B+' },
      { id: 14, name: 'A1B-' },
      { id: 15, name: 'A2B+' },
      { id: 16, name: 'A2B-' },
      { id: 17, name: 'Bombay Blood Group' },
      { id: 18, name: 'INRA' },
      { id: 19, name: "Don't Know" }
    ];

    
  }

  getReferencesList() {
    return this.referenceDataList = [
      { id: 1, name: 'Hospital' },
      { id: 2, name: 'Advertisement' },
      { id: 3, name: 'Reference' },
      { id: 4, name: 'Others' }   
    ];

    
  }

 setCVStatus(CVStatus: any) {
    if (CVStatus == 1) {
      this.cvDoseForm = true;
    } else {
      this.cvDoseForm = false;
    }
  }

  validMobile(event: any) {
    this.isValidMobile = false;
    //console.log("mobile", event.target.value);

    const verifyUser = {
      "action": "verify_user",
      "phone": event.target.value,
    };
    this.auth.ubuplasmaAutheticate(verifyUser).subscribe(
      response => {
        if (response['status_code'] == 201 && response['is_user_signup'] == true) {
          //  this.ngxUiLoaderService.stop();
          this.isValidMobile = true;
          this.toastrService.info('', 'You are already registered with us, Thank you');
          return false;
        }
      }, error => {
        this.ngxUiLoaderService.stop();
        this.toastrService.info('', "Some thing went wrong");
      });
  }
  validEmail(event: any) {
    this.isValidemail = false;
   // console.log("email", event.target.value);
    const verifyUser = {
      "action": "verify_email",
      "email": event.target.value,
    };
    this.auth.ubuplasmaAutheticate(verifyUser).subscribe(
      response => {
        if (response['status_code'] == 201 && response['is_user_signup'] == true) {
          //  this.ngxUiLoaderService.stop();
          this.isValidemail = true;
        //  console.log("else", this.isValidMobile);
          this.toastrService.info('', 'This email already exists');
          return false;
        }
      }, error => {
        this.ngxUiLoaderService.stop();
        this.toastrService.info('', "Some thing went wrong");
      });
  }

  /**
   * 
   */

  checkRefercode(code: any) {
    var str = code.target.value;
    if (str.length == 6) {
      this.referrerCode.validRefCode(str).subscribe(data => {
        //console.log(data);
        this.isvalidRefCode = data;
      });
    } else if (str.length < 6) {
      $('.valid_m1').removeClass('valid_val').addClass('not_valid_val');
    }
    if (str.length == 0) {
     // console.log("innnnnn");
      $('.valid_m1').removeClass('not_valid_val valid_val');
    }
  }

  get ubdFormControl() {

    const invalid = [];
    const controls = this.ubPlasmDonorForm.controls;
    for (const name in controls) {
      //console.log("controler"+name);
    }
    // return invalid;
   return this.ubPlasmDonorForm.controls;
  }

  covidPlasmaDonor(postDonor: any) {
    this.isPlasmaDonorSubmit = true;
    var _self = this;
    if (this.ubPlasmDonorForm.invalid) {
      console.log("invalid data");
      return false;
    }
    //  console.log(postDonor); //return false;
    let C19posDate = this.datePipe.transform(postDonor["ubd_positive_datetime"], "dd-MM-yyyy");
    let C19negDate = this.datePipe.transform(postDonor["ubd_negative_datetime"], "dd-MM-yyyy");
    //this.datePipe.transform(this.model.StartDate,"dd-MM-yyyy");
    //this.model.EndDate = this.datePipe.transform(this.model.EndDate,"dd-MM-yyyy");  
    let cdose1= this.datePipe.transform(postDonor["cv_dose_one"], "dd-MM-yyyy");
    let cdose2= this.datePipe.transform(postDonor["cv_dose_two"], "dd-MM-yyyy");
    if(C19posDate != null && C19negDate!= null){
      this.isValidDate= this.validateDates(C19posDate, C19negDate);
    }
    if(cdose1 != null && cdose2!= null){
      this.isValidCowinDate= this.validateCowinDates(cdose1, cdose2);
    }else {
      this.isValidCowinDate = true;
    }
      //console.log(this.isValidDate);
      //return false;
      if(this.cvDoseForm == true && cdose1 == null){
        this.toastrService.info("Please Enter First vaccine date");
        return false;
       }
    if (this.isValidDate !== true) {
          console.log("invlid date");
      return false;
    }
    if (this.isValidMobile || this.isValidemail == true) {
        this.toastrService.error("This User already exists");
      return false;

    }
    if (this.dlatitude == undefined) {
        this.toastrService.error("Invalid Location. Please enter google suggested locations");
      return false;
    }
    /* if (postDonor["ubd_refcode"]) {
      if (this.isvalidRefCode != 1) {
        this.toastrService.error("Invalid referral code. Please enter valid code");
        $('.valid_m1').removeClass('valid_val').addClass('not_valid_val');
        return false;
      }
    }*/
    if (this.isValidCowinDate !== true) {
          console.log("invalid cowin dates");
        return false;
    }
    if ($("#ubpd_termsAndCondition").prop("checked") !== true) {
        this.toastrService.info("Please accept the terms and Conditions");
      return false;
    }

    this.ngxUiLoaderService.start();
    if (postDonor) {
      this.donorMobile = postDonor["ubd_mobile"];
      this.donorData = {
        action: "donor",
        first_name: postDonor["ubd_name"],
        last_name: postDonor["ubd_lname"],
        email: postDonor["ubd_email"],
        blood_group: postDonor["ubd_blood_group"],
        address: this.Donorlocation,
        locality: this.Donorlocation,
        latitude: this.dlatitude,
        longitude: this.dlongitude,
        age: this.datePipe.transform(postDonor["ubd_age"][0], "yyyy-MM-dd"),
        is_plasma_donor: 1,
        last_covid_positive_test_date: this.datePipe.transform(postDonor["ubd_positive_datetime"][0], "yyyy-MM-dd"),
        date_of_recovery: this.datePipe.transform(postDonor["ubd_negative_datetime"][0], "yyyy-MM-dd"),
        have_covid_symptoms: postDonor["isCheck"], 
        have_covid_vaccine: postDonor["isCVCheck"],
        cv_dose_one : this.datePipe.transform(postDonor["cv_dose_one"][0], "yyyy-MM-dd"),
        cv_dose_two : this.datePipe.transform(postDonor["cv_dose_two"][0], "yyyy-MM-dd"),

        referral_code: "",
        referred_from : postDonor["ubr_refcode"]
      }
      this.dataService.changeMobile(postDonor["ubd_mobile"]);
      const verifyUser = {
        "action": "verify_user",
        "phone": postDonor["ubd_mobile"],
      };
      const isEmailAlreadyExist = {
        "action": "verify_email",
        "email": postDonor["ubd_email"],
      };
      this.auth.ubuplasmaAutheticate(verifyUser).subscribe(
        response => {
          if (response['status_code'] == 201 && response['is_user_signup'] == true) {
            this.ngxUiLoaderService.stop();
            this.toastrService.info('', 'You are already registered with us, Thank you');
            return false;
          } else {
            _self.auth.ubuplasmaAutheticate(isEmailAlreadyExist).subscribe(
              response => {
                if (response['status_code'] == 200 && response['is_user_signup'] == true) {
                  this.ngxUiLoaderService.stop();
                  this.toastrService.info('', 'Usaver with email id already exists, Thank you');
                  return false;
                } else {
                  _self.SendOtp(_self.donorMobile);
                }
              }, error => {
                this.ngxUiLoaderService.stop();
                this.toastrService.info('', "Oops, Something went wrong, Please try again");
              });
          }
        }, error => {
          this.ngxUiLoaderService.start();
          this.toastrService.info('', "Some thing went wrong");
        });
    }

  }

  get ubAuthFormControl() { return this.sendotpForm.controls; }

  SendOtp(mobile: any) {
    // console.log(mobile);
    if (mobile > 0) {
      const authUserData = {
        "action": "sendotp",
        "std_code": "+91",
        "phone": mobile,
      };
      // console.log('jall',authUserData);
      this.ngxUiLoaderService.start();
      this.auth.ubuplasmaAutheticate(authUserData)
        .subscribe(
          response => {
            if (response['status_code'] == 200) {
              this.ngxUiLoaderService.stop();
              this.toastrService.success('', response['success']['message']);
              $('#otpModalUI').modal('show');
              this.otp_timer_start = true;
            }
          }, error => {
            this.ngxUiLoaderService.stop();
            this.toastrService.info('', "Some thing went wrong");
          });
    } else {
      this.toastrService.info('', "Please enter mobile number");
    }

  }

  validateDates(sDate: string, eDate: string) {
    this.isValidDate = true;
    if ((sDate == null || eDate == null)) {
      this.toastrService.error('', 'Positive date and Negative date are required');
      this.isValidDate = false;
    }
   // console.log(sDate,'sdate');
   // console.log(eDate,'edate'); 
    if ((sDate != null && eDate != null) && (sDate > eDate)) {
      console.log("invalid");
       
      this.toastrService.error('C-19 Positive date should be less than C-19 Negative date.');
      this.isValidDate = false;
    }
    return this.isValidDate;

  }

  validateCowinDates(sDate: string, eDate: string) {
    this.isValidCowinDate = true;
    if ((sDate == null || eDate == null)) {
      this.toastrService.error('', 'First Dose and Second Dose dates are required');
      this.isValidCowinDate = false;
    }
   // console.log(sDate,'sdate');
   // console.log(eDate,'edate'); 
    if ((sDate != null && eDate != null) && (sDate > eDate)) {
      console.log("invalid");
       
      this.toastrService.error('Vaccine First dose date should be less than Second dose date.');
      this.isValidCowinDate = false;
    }
    return this.isValidCowinDate;

  }

  numberOnly(event: any): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  keyPressAlphaNumeric(event) {
    var inp = String.fromCharCode(event.keyCode);

    if (/[a-zA-Z]/.test(inp)) {
      return true;
    } else {
      event.preventDefault();
      return false;
    }
  }

}
