import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-map-marker',
  templateUrl: './map-marker.component.html',
  styleUrls: ['./map-marker.component.css']
})
export class MapMarkerComponent implements OnInit {
  @Input() ubUserList: any;
  @Input() latitude: number;
  @Input() longitude: number;
  previousInfoWindow: any;
  constructor() { }

  ngOnInit() {
  }


  /**
   * 
   * @param categoryName 
   * @param blood_group 
   */
  getMarkerIconURL(categoryName: string, blood_group: string) {
    if (categoryName === "Donor") {
      const donor_icon_path ="/assets/images/map_icons/donor/";
      switch (blood_group) {
        case "A+":
          return donor_icon_path+"a_positive_donor.png";
         
        case "A-":
          return donor_icon_path+"a_negative_donor.png";
          
        case "B+":
          return donor_icon_path+"b_positive_donor.png";
         
        case "B-":
          return donor_icon_path+"b_negative_donor.png";
          
        case "AB+":
          return donor_icon_path+"ab_positive_donor.png";
         
        case "AB-":
          return donor_icon_path+"ab_negative_donor.png";
        case "O+":
          return donor_icon_path+"o_positive_donor.png";
        case "O-":
          return donor_icon_path+"o_negative_donor.png";
        case "A1+":
          return donor_icon_path+"aone_positive_donor.png";
        case "A1-":
          return donor_icon_path+"aone_negative_donor.png";
        case "A2+":
          return donor_icon_path+"atwo_positive_donor.png";
          
        case "A2-":
          return donor_icon_path+"atwo_negative_donor.png";
        
        case "A1B+":
          return donor_icon_path+"aoneb_positive_donor.png";
         
        case "A1B-":
          return donor_icon_path+"aoneb_negative_donor.png";
         
        case "A2B+":
          return donor_icon_path+"atwob_positive_donor.png";
          
        case "A2B-":
          return donor_icon_path+"atwob_negative_donor.png";
         
        case "Bombay Blood Group":
          return donor_icon_path+"bombay_donor.png";
        case "INRA":
          return donor_icon_path+"inra_donor.png";
         
        default:
          return donor_icon_path+"donor_icon.png";
        
      }
    }
    if (categoryName === "Receiver") {
      switch (blood_group) {
        case "A+":
          return "/assets/images/map_icons/receiver/a_positive_receiver.png";
        
        case "A-":
          return "/assets/images/map_icons/receiver/a_positive_receiver.png";
        
        case "B+":
          return "/assets/images/map_icons/receiver/b_positive_receiver.png";
         
        case "B-":
          return "/assets/images/map_icons/receiver/b_positive_receiver.png";
          
        case "AB+":
          return "/assets/images/map_icons/receiver/ab_positive_receiver.png";
         
        case "AB-":
          return "/assets/images/map_icons/receiver/ab_negative_receiver.png";
         
        case "O+":
          return "/assets/images/map_icons/receiver/o_positive_receiver.png";
       
        case "O-":
          return "/assets/images/map_icons/receiver/o_negative_receiver.png";
         
        case "A1+":
          return "/assets/images/map_icons/receiver/aone_positive_receiver.png";
          
        case "A1-":
          return "/assets/images/map_icons/receiver/aone_negative_receiver.png";
          
        case "A2+":
          return "/assets/images/map_icons/receiver/atwo_positive_receiver.png";
         
        case "A2-":
          return "/assets/images/map_icons/receiver/atwo_negative_receiver.png";
        
        case "A1B+":
          return "/assets/images/map_icons/receiver/aoneb_positive_receiver.png";
          
        case "A1B-":
          return "/assets/images/map_icons/receiver/aoneb_negative_receiver.png";
          
        case "A2B+":
          return "/assets/images/map_icons/receiver/atwob_positive_receiver.png";
          
        case "A2B-":
          return "/assets/images/map_icons/receiver/atwob_negative_receiver.png";
        
        case "Bombay Blood Group":
          return "/assets/images/map_icons/receiver/bombay_receiver.png";
         

        case "INRA":
          return "/assets/images/map_icons/receiver/inra_receiver.png";
        
        default:
          return "/assets/images/map_icons/receiver/receiver_icon.png";
          
      }
    }
    else if (categoryName == "Ambulance") {
      return "/assets/images/map_icons/ambulance_icon.png";
    }
    else if (categoryName == "Receiver") {
      return "/assets/images/map_icons/receiver_icon.png";
    }
    else if (categoryName == "Blood Bank") {
      return "/assets/images/map_icons/blood_bank_icon.png";
    }

  }

  /**
  * 
  * @param infoWindow 
  */
  clickedMarker(infoWindow: any) {
    if (this.previousInfoWindow) {
      this.previousInfoWindow.close();
    }
    this.previousInfoWindow = infoWindow;
  }
  markerDragEnd($event: MouseEvent) {
    // this.latitude = $event.coords.lat;
    // this.longitude = $event.coords.lng;
    //  this.getAddress(this.latitude, this.longitude);
  }


}
