import { Component, OnInit } from '@angular/core';
declare var Swiper: any;
declare var $: any;
@Component({
  selector: 'app-usaver-home',
  templateUrl: './usaver-home.component.html',
  styleUrls: ['./usaver-home.component.css']
})
export class UsaverHomeComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    $(".form_link").click(function() {
      $('html,body').animate({
          scrollTop: $(".form_section").offset().top},
          'slow');
  });

    var swiper1 = new Swiper('.slider_m6', {
      slidesPerView: 1,
      spaceBetween: 1,
      pagination: {
        el: '.swiper-pagination',
        dynamicBullets: true,
      },
      autoplay: {
        delay: 2000,
      },
   
    });
  }

}
