import { Component, OnInit } from '@angular/core';
declare var $: any;
@Component({
  selector: 'app-main-footer',
  templateUrl: './main-footer.component.html',
  styleUrls: ['./main-footer.component.css']
})
export class MainFooterComponent implements OnInit {

  currentYear: number;
  constructor() {
    this.currentYear = new Date().getFullYear();
  }


  ngOnInit() {
    // $(".desk_links").clone().appendTo(".mobile_links");
    // $(window).ready(function(){
    //   if ($(window).width() <= 767) {  

    //        $('.desk_links').hide();
    //         $('.mobile_links .desk_links').show();    

    //        }     
    //        else
    //        {

    //         $('.desk_links').show();
    //         $('.mobile_links ').hide();   

    //        }
    //       });
  }

}
