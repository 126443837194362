import { Component, OnInit, Input, ViewChild, OnChanges, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { BehaviorSubject, interval, of, timer } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import { Ubrequest } from '../../../models/request.model';
import { DataService } from '../../../services/dataService/data.service';
import { AuthenticationService } from '../../../services/authentication/authentication.service';


declare var $: any;

@Component({
  selector: 'app-otp-modal',
  templateUrl: './otp-modal.component.html',
  styleUrls: ['./otp-modal.component.css']
})
export class OtpModalComponent implements OnChanges {

  @Input() phone_number: number;
  @Input() otp_timer_start: boolean;
  @Input() request_data: any;
  @ViewChild('ngOtpInput', { static: true }) ngOtpInput: any;
  ubOTPForm: FormGroup;
  isOTPFormSubmitted: boolean = false;
  verified_user: number = 0;
  isOTPtimerOn: boolean = false;
  isResendBtnEnabled: boolean = false;
  otpTimeCountinSec: number = 50;
  maskPhoneNumber: any;
 
  otpConfig = {
    allowNumbersOnly: true,
    length: 4,
    isPasswordInput: false,
    disableAutoFocus: false,
    placeholder: '',
    inputStyles: {
      'width': '50px',
      'height': '40px',
      'display': 'inline',
      'margin-right': '10px'
    }
  };

  constructor(
    private formBuilder: FormBuilder,
    private authentication: AuthenticationService,
    private dataService: DataService,
    private toastrService: ToastrService,
    private ngxUIService: NgxUiLoaderService,
    private router: Router
  ) {
    this.ubOTPForm = this.formBuilder.group({
      uba_otp_number: ['', [Validators.required, Validators.pattern(/\d{4}/)]],
    });

  }

  ngOnInit() {

  }

  ngOnChanges(timeOptionStart: SimpleChanges) {
    if (this.otp_timer_start) {
      if (this.request_data) {
        this.maskPhoneNumber = this.phone_number;
        this.maskPhoneNumber = this.maskPhoneNumber.substring(0, 2) + 'xxxxx' + this.maskPhoneNumber.substring(this.maskPhoneNumber.length - 3);
      }

      this.isOTPtimerOn = true;
      this.otpTimerOn(this.otpTimeCountinSec);

    }

  }

  get ubOTPFormControl() { return this.ubOTPForm.controls; }

  verifyOtp(otp: any) {
    this.isOTPFormSubmitted = true;
    localStorage.setItem("ubu_auth_token", '');
   // console.log(this.request_data);
    if (this.ubOTPForm.invalid) {
      this.toastrService.info('', "Please enter OTP");
      return false;
    }
    if (otp) {

      const authUserData = {
        "action": "verify",
        "phone": this.phone_number,
        "otp": otp.uba_otp_number
      };

      if (this.request_data['action'] == "donor") {
        authUserData["is_donor"] = 1;
      }

      if (this.request_data['action'] == "create_service_request") {
        authUserData["is_donor"] = 0;
      }
      this.ngxUIService.start();
      if(this.request_data['is_plasma_request'] == "1" || this.request_data['is_plasma_donor'] == "1"){
        const authUserData = {
          "action": "verify",
          "phone":  this.phone_number,
          "otp": otp.uba_otp_number,
          "is_plasma_donor":1        
         }
         this.authentication.ubuplasmaAutheticate(authUserData)
        .subscribe(
          response => { 
            if (response['status_code'] == 200) {
              this.request_data['user_id'] = response['data'].user_id;
             // changeEmail
              this.dataService.changeUserId(response['data'].user_id);
             // this.ngxUIService.start();
              if (this.request_data['is_plasma_request'] == "1" && this.request_data['service_type'] == "3") {
                  localStorage.setItem("ubu_auth_token", response['data']['access_token']);
                  this.savePlasmaRequest(this.request_data);
              }
              if (this.request_data['is_plasma_donor'] == "1" ) {
                localStorage.setItem("ubu_auth_token", response['data']['access_token']);
                this.savePlasmaDonor(this.request_data);
              }
              
            } else {
              this.toastrService.info('', response['error'].message);
              this.ngxUIService.stop();
            }
          }, error => {
            this.ngxUIService.stop();
            this.toastrService.info('', "Oops, Something went wrong, Please try again");
          });
      }else{
        this.authentication.ubuAuthenticate(authUserData)
        .subscribe(
          response => { 
            if (response['status_code'] == 200) {
              this.request_data['user_id'] = response['data'].user_id;
              this.dataService.changeUserId(response['data'].user_id);
              //this.ngxUIService.start();
              if (this.request_data['action'] == "donor") {
                this.signUpdataSubmit(this.request_data);
              }
              if (this.request_data['action'] == "create_service_request") {
                localStorage.setItem("ubu_auth_token", response['data']['access_token']);
                this.bloodRequestDataSubmit(this.request_data);
              }
              
            } else {
              this.toastrService.info('', response['error'].message);
              this.ngxUIService.stop();
            }
          }, error => {
            this.ngxUIService.stop();
            this.toastrService.info('', "Oops, Something went wrong, Please try again");
          });

      }
      
    } else {
      this.toastrService.info('', "Please enter OTP number");
    }

  }

  /**
   * 
   */
  resendOTP() {
    this.otp_timer_start = true;
    this.isOTPtimerOn = true;
    this.ngOtpInput.setValue('');
    const authUserData = {
      "action": "sendotp",
      "std_code": "+91",
      "phone": this.phone_number
    };
    this.ngxUIService.start();
    //console.log(this.request_data);
    if(this.request_data['is_plasma_request'] == "1" || this.request_data['is_plasma_donor'] == "1"){
      
      //console.log(authUserData); //return false;
      this.authentication.ubuplasmaAutheticate(authUserData)
      .subscribe(
        response => {
          if (response['status_code'] == 200) {
            this.ngxUIService.stop();
            this.otpTimerOn(this.otpTimeCountinSec);
            this.toastrService.success('', response['success']['message']);
          }
        }, error => {
          this.ngxUIService.stop();
          this.toastrService.info('', "Some thing went wrong");
        });

    }else{
      this.authentication.ubuAuthenticate(authUserData)
      .subscribe(
        response => {
          if (response['status_code'] == 200) {
            this.ngxUIService.stop();
            this.otpTimerOn(this.otpTimeCountinSec);
            this.toastrService.success('', response['success']['message']);
          }
        }, error => {
          this.ngxUIService.stop();
          this.toastrService.info('', "Some thing went wrong");
        });

    }
   

  }

  /**
   * 
   * @param timeLeft 
   */
  otpTimerOn(timeLeft: number) {
    let minute: any = Math.floor(timeLeft / 60);
    let second: any = timeLeft % 60;
    let _self = this;
    minute = minute < 10 ? '0' + minute : minute;
    second = second < 10 ? '0' + second : second;
    $('#otp_timer').html(minute + ':' + second);
    timeLeft -= 1;

    if (timeLeft >= 0 && this.isOTPtimerOn) {
      this.isResendBtnEnabled = false;
      setTimeout(function () {
        _self.otpTimerOn(timeLeft);
      }, 1000);
      return;
    } else {
      this.isResendBtnEnabled = true;
      $('#otp_timer').html('');
    }

    if (!this.isOTPtimerOn) {
      // Do validate stuff here
      return;
    }


  }
  /**
   * 
   * @param event 
   */
  isValidOtp(event: any) {
    this.ubOTPForm.controls['uba_otp_number'].setValue(event);
    /* const charCode = (event.which) ? event.which : event.keyCode;
     if (charCode > 31 && (charCode < 48 || charCode > 57)) {
       return false;
     }
     return true;*/
  }

  /**
   * 
   * @param requestData 
   */
  signUpdataSubmit(requestData: any) {
    //this.ngxUIService.start();
    this.authentication.ubuAuthenticate(requestData)
      .subscribe(
        response => {
          if (response['status_code'] == 200) {
            localStorage.setItem("userTypeMessage", "blood_donor");
            this.dataService.changeMessage("blood_donor");
            $('#otpModalUI').modal('hide');
            localStorage.removeItem("ubu_auth_token");
            this.ngxUIService.stop();            
            this.router.navigate(["/ubd-message"]);
          }
          if (response['status_code'] == 201) {
            this.ngxUIService.stop();
            this.toastrService.info('', response['error']['message']);
          }
        }, error => {
          this.ngxUIService.stop();
          this.toastrService.info('', "Some thing went wrong");
        });
  }

  /**
   * 
   * @param requestData 
   */
  bloodRequestDataSubmit(requestData: any) {
    if (requestData) {
      this.dataService.createRequest(requestData).subscribe(
        response => {
          if (response['status_code'] == 200) {
            localStorage.setItem("userTypeMessage", "blood_requester");
            this.dataService.changeMessage("blood_requester");
            $('#otpModalUI').modal('hide');
            localStorage.removeItem("ubu_auth_token");
            this.ngxUIService.stop();
            this.router.navigate(["/ubr-message"]);
          }
          if (response['status_code'] == 201) {
            this.ngxUIService.stop();
            this.toastrService.info('', response['error']['message']);
          }
        }, error => {
          this.ngxUIService.stop();
          this.toastrService.info('', "Some thing went wrong");
        });
    }
  }

  /**
   * 
   * @param plasamaRequest
   */

  savePlasmaRequest(data:any){
   // console.log(data); //return false;
    this.dataService.createplasmaRequest(data).subscribe(
        response => {
          if (response['status_code'] == 200) {
           // this.ngxUiLoaderService.stop();
            localStorage.setItem("userTypeMessage", "requester");
            this.dataService.changeMessage("requester");
            $('#otpModalUI').modal('hide');
            localStorage.removeItem("ubu_auth_token");
            this.ngxUIService.stop();  
            this.router.navigate(["/ubr-message"]);
          }
          if (response['status_code'] == 201) {
            this.ngxUIService.stop();
            this.toastrService.info('', response['error']['message']);
          }
        }, error => {
          this.ngxUIService.stop();
          this.toastrService.info('', "Some thing went wrong");
        });
    
  }

  savePlasmaDonor(data:any){
   // console.log(data); //return false;
    this.authentication.ubuplasmaAutheticate(data).subscribe(
        response => {
          if (response['status_code'] == 200) {
            localStorage.setItem("userTypeMessage","donor");
            localStorage.removeItem("ubu_auth_token");
            //console.log(data); return false;
            if(data.have_covid_vaccine=='1'){
              this.dataService.changeMessage("donor_vaccine");
            }else if(data.have_covid_vaccine=='0'){
              this.dataService.changeMessage("donor");
            }
              
            $('#otpModalUI').modal('hide');
            this.ngxUIService.stop(); 
            this.router.navigate(["/ubd-message"]);
          }
          if (response['status_code'] == 201) {
            this.ngxUIService.stop();
            this.toastrService.info('', response['error']['message']);
          }
        }, error => {
          this.ngxUIService.stop();
          this.toastrService.info('', "Some thing went wrong");
        });
    
  }


}
