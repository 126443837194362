import { Component, OnInit, Renderer2 } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Router, ActivatedRoute } from '@angular/router';
import { DataService } from '../../services/dataService/data.service';
import { Inject, AfterViewInit, ElementRef } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-message',
  templateUrl: './message.component.html',
  styleUrls: ['./message.component.css']
})
export class MessageComponent implements OnInit {
  textMessage = "";
  headerMessage = "";
  message: string;
  mobile: number;
  name: string;
  email: string;
  constructor(
    private toastrService: ToastrService,
    private ngxUiLoaderService: NgxUiLoaderService,
    private router: Router,
    private dataService: DataService,
    private _renderer2: Renderer2,
    @Inject(DOCUMENT) private _document
  ) {

  }

  ngOnInit() {

    this.dataService.currentMobile.subscribe(mobile => this.mobile = mobile);
    this.dataService.currentName.subscribe(name => this.name = name);
    this.dataService.currentEmail.subscribe(email => this.email = email);

    this.dataService.currentMessage.subscribe(message => this.message = message);

    if (this.message == "donor") {
      this.textMessage = "#UBlood  #CovidPlasma #Savinglives";
      this.headerMessage = "You are now a member of our fast growing USaver community as a C-19 Warrior on Ublood smart connect";
      localStorage.clear();

    } else if (this.message == "donor_vaccine") {
      this.textMessage = "#UBlood  #CovidPlasma #Savinglives";
      this.headerMessage = "Thank you for registering covid vaccinated willing donors are currently ineligible for donating plasma as per regulation. Our Ublood team shall verify with our health advisors and reach out to you.";
      localStorage.clear();

    } else if (this.message == "requester") {
      this.textMessage = "Our team will verify the details and notify willing donors to connect with you for Covid Plasma donation. We sincerely pray for the Speedy Recovery of Covid tested patient";
      this.headerMessage = "Thank you for Making a request for Covid Plasma Donors on UBlood.";
      localStorage.clear();

    } else if (this.message == "blood_donor") {
      this.textMessage = "#UBlood  #Donate2savelives #USaver";
      this.headerMessage = "Thank you for joining UBlood as a donor and becoming a member of our fast growing USaver Community to save live.";
      localStorage.clear();

    } else if (this.message == "blood_requester") {
      this.textMessage = "To know your request updates - download our app.";
      this.headerMessage = "UBlood is on the case! We're reaching out to nearby donors, we'll notify you. Stay Strong!.";
      localStorage.clear();


    } else if (this.message == "usaver_feedback") {
      this.textMessage = "Your input helps us improve. We appreciate your valuable contribution!";
      this.headerMessage = "Thank You for Your Valuable Feedback!.";
      localStorage.clear();

    } else {
      localStorage.clear();
      this.router.navigate(["/"]);
    }

    this.ngxUiLoaderService.stop();

  }


}
