import { Component, OnInit, ViewChild, ElementRef, NgZone, Input, Output, EventEmitter } from '@angular/core';
import { DataService } from '../../services/dataService/data.service';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { MapsAPILoader, GoogleMapsAPIWrapper, AgmMap, LatLngBounds, LatLngBoundsLiteral } from '@agm/core';
import { Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map, merge } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { NgbTypeahead } from '@ng-bootstrap/ng-bootstrap';
declare var $: any

@Component({
  selector: 'app-map-page',
  templateUrl: './map-page.component.html',
  styleUrls: ['./map-page.component.css']
})
export class MapPageComponent implements OnInit {

  @ViewChild('instance', { static: true }) instance: NgbTypeahead;
  isSignUpSubmitted = false;
  latitude: number;
  longitude: number;
  zoom: number;
  userLocation: Location;
  userData: any;
  userDataList: any;
  covidDataList: any;
  private searchTerm: string;
  mapCenter: any;
  userId: any;
  public geoCoder;
  map: google.maps.Map;
  fitBounds: boolean = false;
  zoomControlOptions: any;
  searchDonorListForm: FormGroup;
  phonenumber: any;
  noResult = false;
  is_mobile_verify: boolean;
  request_data: any;
  ubsp_mobile: number;
  ubsp_user_id: number;
  tourStepOne: boolean = true;
  tourStepTwo: boolean = false;
  tourStepThree: boolean = false;
  tourStep: number = 1;
  homeView: boolean = true;
  mapView: boolean = false;

  public address: string;
  public city: string;
  public state: string;
  public country: string;
  public zipcode: string;

  donorList: any;
  mapData: any;
  isDisctanceSelected: boolean = false;
  selectedBloodGroupList: any = [];
  serviceDataList: any;
  serviceFilterForm: FormGroup;
  @ViewChild("filterBloodGroupList", { static: true }) private bloodGroupListRef: ElementRef<HTMLElement>;
  @ViewChild('ubSearch', { static: true }) public searchElementRef: ElementRef;
  //@ViewChild('ubSearch', { static: true }) public searchElementRef: ElementRef;
  @ViewChild('AgmMap', { static: true }) agmMap: AgmMap;

  constructor(private router: Router, private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone, private dataService: DataService,
    private toastrService: ToastrService, private ngxUiLoaderService: NgxUiLoaderService,
    private UbSearchfb: FormBuilder, ) { }

  ngOnInit() {

    $(document).ready(function () {

      $('.panel-collapse').on('show.bs.collapse', function () {
        $(this).siblings('.panel-heading').addClass('active');
      });

      $('.panel-collapse').on('hide.bs.collapse', function () {
        $(this).siblings('.panel-heading').removeClass('active');
      });


      $('.blood_list1 li').on('click', function (event: any) {
        $(this).toggleClass('active')
      });

      $(".blood_filter_btn").click(function () {
        $(".blood_group_list_wrapper").animate({
          height: "toggle"
        });
      });

      $(document).click(function (e) {
        var container = $(".blood_group_list_wrapper");
        // if the target of the click isn't the container nor a descendant of the container
        if (!container.is(e.target) && container.has(e.target).length === 0) {
          container.hide();
        }
      });

    });







    //load Places Autocomplete
    //this.userData = localStorage.getItem("ubu_auth_token");
    if (localStorage.getItem("ubu_auth_token")) {
      this.router.navigate(["/donor-map-view"]);
    }

    this.mapsAPILoader.load().then(() => {
      this.setCurrentLocation();
      this.zoomControlOptions = { position: google.maps.ControlPosition.RIGHT_CENTER };
      this.geoCoder = new google.maps.Geocoder;

      let autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement);
      autocomplete.addListener("place_changed", () => {
        this.ngZone.run(() => {
          //get the place result
          let place: google.maps.places.PlaceResult = autocomplete.getPlace();
          //verify result
          if (place.geometry === undefined || place.geometry === null) {
            return;
          }
          //set latitude, longitude and zoom
          this.latitude = place.geometry.location.lat();
          this.longitude = place.geometry.location.lng();
          // this.mapData = {"latitude" :this.latitude,"longitude":this.longitude}
          // this.donorListChangeEvent.emit(this.mapData);
          this.getDonorList(this.latitude, this.longitude);

        });
      });
    });

    this.serviceFilterForm = this.UbSearchfb.group({
      map_search_location: ''
    });

  }


  goToMapView() {
    this.homeView = false;
    this.mapView = true;

  }

  goToHomeView() {
    this.homeView = true;
    this.mapView = false;
  }

  // Get Current Location Coordinates
  public setCurrentLocation() {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.latitude = position.coords.latitude;
        this.longitude = position.coords.longitude;
        this.zoom = 20;
        this.getAddress(this.latitude, this.longitude);
        this.getDonorList(this.latitude, this.longitude);
        //this.getCovid19StatList(this.latitude, this.longitude);
      });
    } else {
      this.toastrService.info("geo Location not enabled");

    }
    // IP based geo access.
    if (!this.latitude || !this.longitude) {
      var _self = this;
      $.getJSON('http://ip-api.com/json', function (data: any) {
        _self.latitude = data.lat;
        _self.longitude = data.lon;
        _self.getDonorList(_self.latitude, _self.longitude);
      });
    }
  }

  getAddress(latitude: number, longitude: number) {

    this.mapsAPILoader.load().then(() => {
      // this.map.setCenter(new google.maps.LatLng(latitude, longitude));  
      this.geoCoder.geocode({ 'location': { lat: latitude, lng: longitude } }, (results: any, status: any) => {
        if (status === 'OK') {
          if (results[0]) {
            this.zoom = 12;
            this.address = results[0].formatted_address;
          
            this.getDetailedAddress(this.address);
          } else {
            this.toastrService.info("No Data Found");
          }
        } else {
          this.toastrService.info('Geocoder failed due to: ' + status);
        }
      });
    });
  }

  get ubMapFormControl() { return this.serviceFilterForm.controls; }


  donorListChangeEventOnMapHandler(mapData: any) {
    this.userDataList = mapData.donorList;
    this.latitude = mapData.latitude;
    this.longitude = mapData.longitude;
    this.fitBounds = true;
  }
  requestTypeEventHandler(requestData: any) {
    this.request_data = requestData;
  }
  mapReady(event: any) {
    this.map = event;
    this.map.controls[google.maps.ControlPosition.RIGHT_CENTER].push(document.getElementById("getCurrentLocation"));
    this.map.controls[google.maps.ControlPosition.TOP_LEFT].push(document.getElementById("ubloodFilterSection"));
    //  this.map.controls[google.maps.ControlPosition.TOP_CENTER].push(document.getElementById("ubLogoUp"));
    //  this.map.controls[google.maps.ControlPosition.BOTTOM_CENTER].push(document.getElementById("ubLogoBtm"));
    //  this.map.controls[google.maps.ControlPosition.TOP_LEFT].push(document.getElementById("headerMenu"));


    // this.map.setCenter(new google.maps.LatLng(this.latitude, this.longitude));
  }

  /***
  * 
  */
  searchDonor(post: any) {
    this.getFilteredDonorList(this.latitude, this.longitude, 0, 0, 0);
  }

  /**
   * 
   */
  setFilterBloodgroup() {
    let _self = this;
    this.selectedBloodGroupList = [];
    const bloodGroupParentRef = this.bloodGroupListRef.nativeElement;
    const selectedbloodGroupElementList = bloodGroupParentRef.querySelectorAll(".active");
    selectedbloodGroupElementList.forEach(function (el) {
      _self.selectedBloodGroupList.push(el.id);
    });

    let distance: number = ($("#filterDistanceValue").val()) ? $("#filterDistanceValue").val() : 0;
    let measure: number = ($("#filterDistanceUnit").val()) ? $("#filterDistanceUnit").val() : 1;
    if (this.selectedBloodGroupList.length > 0) {
      this.getFilteredDonorList(this.latitude, this.longitude, this.selectedBloodGroupList, distance, measure);
    } else {
      this.toastrService.info("Please select the blood group");
      return false;
    }
  }

  /***
   * 
   */
  getDonorList(latitude: number, longitude: number) {
    if (latitude && longitude) {
      this.ngxUiLoaderService.start();
      this.getAddress(this.latitude, this.longitude);
      let filterData = { "latitude": latitude, "longitude": longitude }
      this.dataService.filterUserDataList(filterData).subscribe(
        (filterDataList: any) => {
          if (filterDataList.data.length == 0) {
            this.toastrService.info("No Donor Found");
          }
          this.userDataList = filterDataList.data;
          this.fitBounds = true;
          this.ngxUiLoaderService.stop();
        },
        (err) => {
          console.log(err)
          this.ngxUiLoaderService.stop();
        });
      this.ngxUiLoaderService.stop();
    }
    else {
      this.setCurrentLocation();
    }
  }

  /**
  * 
  * @param latitude 
  * @param longitude 
  * @param blood_group 
  * @param distance 
  * @param measure 
  */
  getFilteredDonorList(latitude: number, longitude: number, blood_group: any, distance: number, measure: number) {
    if (latitude && longitude) {
      this.ngxUiLoaderService.start();
      let filterData = { "latitude": latitude, "longitude": longitude };
      if (blood_group) {
        filterData["blood_groups"] = blood_group;
      }
      if (distance && measure) {
        filterData["distance"] = distance;
        filterData["measure"] = measure;
      }
      this.dataService.filterUserDataList(filterData).subscribe(
        (filterDataList: any) => {
          if (filterDataList.data.length == 0) {
            this.toastrService.info("No Donor Found");
          }
          this.mapData = { "latitude": this.latitude, "longitude": this.longitude, "donorList": filterDataList.data }
          this.userDataList = filterDataList.data;
          this.ngxUiLoaderService.stop();
        },
        (err) => {
          console.log(err)
          this.ngxUiLoaderService.start();
        });
      this.ngxUiLoaderService.stop();
    }
  }




  /***
   * 
   */
  getCovid19StatList(latitude: number, longitude: number) {
    if (latitude && longitude) {
      this.ngxUiLoaderService.start();
      this.getAddress(this.latitude, this.longitude);
      let filterData = { "latitude": latitude, "longitude": longitude }
      this.dataService.filterCovid19DataList(filterData).subscribe(
        (covidDataList: any) => {
          if (covidDataList.data.length == 0) {
            this.toastrService.info("No Donor Found");
          }
          this.covidDataList = covidDataList.data;
         
          this.fitBounds = true;
          this.ngxUiLoaderService.stop();
        },
        (err) => {
          console.log(err)
          this.ngxUiLoaderService.stop();
        });
      this.ngxUiLoaderService.stop();
    }
    else {
      this.setCurrentLocation();
    }
  }



  /***
   * 
   */
  clearBloodGroupFilter() {
    let _self = this;
    this.selectedBloodGroupList = [];
    const bloodGroupParentRef = this.bloodGroupListRef.nativeElement;
    const selectedbloodGroupElementList = bloodGroupParentRef.querySelectorAll(".active");
    selectedbloodGroupElementList.forEach(function (el) {
      el.classList.remove('active');
    });
    this.getFilteredDonorList(this.latitude, this.longitude, this.selectedBloodGroupList, 0, 0);
  }

  /**
   * 
   * @param address 
   */
  getDetailedAddress(address: string) {
    let self = this;
    this.geoCoder.geocode({ 'address': address }, (results: any, status: any) => {
      if (status === 'OK') {
        let geo_address = "";
        if (results[0].address_components) {
          $.each(results[0].address_components, function (key: any, value: any) {
            if ((value.types[0]) == 'subpremise' || (value.types[0]) == 'premise' || (value.types[0]) == 'route' || (value.types[0]) == 'political' || (value.types[0]) == 'street_number' || (value.types[0]) == 'neighborhood' || (value.types[0]) == 'administrative_area_level_4') {
              if (geo_address) {
                geo_address += ', ' + value.long_name;
              } else {
                geo_address = value.long_name
              }
              self.address = geo_address;
            }

            if ((value.types[0]) == 'locality') {
              self.city = value.long_name;
            }

            if ((value.types[0]) == 'administrative_area_level_1') {
              self.state = value.long_name;
            }

            if ((value.types[0]) == 'country') {
              self.country = value.long_name;
            }

            if ((value.types[0]) == 'postal_code') {
              self.zipcode = value.long_name;
            }
          });

        } else {
          this.toastrService.info('', "No results found");
        }
      } else {
        //this.toastrService.warning('', "Geocoder failed due to: " + status);

      }

    });
  }

  getCovidMarkerIconURL(iconName: string) {
    const donor_icon_path = "/assets/images/map_icons/";
    switch (iconName) {
      case "red":
        return donor_icon_path + "red-dot.png";
        break;
      case "green":
        return donor_icon_path + "green-dot.png";
        break;
      case "orange":
        return donor_icon_path + "orange-dot.png";
        break;
        return donor_icon_path + "blue-dot.png";
        break;
    }
  }




}
