import { Component, OnInit } from '@angular/core';
import { FlatpickrOptions } from 'ng2-flatpickr';
declare var $: any;
declare var Swiper: any;

@Component({
  selector: 'app-register-vaccination',
  templateUrl: './register-vaccination.component.html',
  styleUrls: ['./register-vaccination.component.css']
})
export class RegisterVaccinationComponent implements OnInit {
  
  currentDate = new Date();
  currentYear = this.currentDate.getFullYear();
  currentMonth =this.currentDate.getMonth();
  currentDay = this.currentDate.getDate();
  dateFormatConfig: FlatpickrOptions = {
    mode:'single',
    enableTime: false,
    dateFormat: "d-m-Y",  
    defaultDate: new Date(this.currentYear - 18, this.currentMonth, this.currentDay),
    maxDate: new Date(this.currentYear - 18, this.currentMonth, this.currentDay),
    minDate:new Date(this.currentYear - 99, this.currentMonth, this.currentDay),
  };
  showData:any;
  sourceValue:any
  constructor() { }

  ngOnInit() {
    let dateFormatConfig: FlatpickrOptions = {
      defaultDate: '2017-03-15'
    };
     $('#id_prof').change(function(){
    $('.colors').hide();
    $('#' + $(this).val()).show();
  });
  }
  // onOptionsSelected(event){
  //   let value = event.target.value;
  //   this.sourceValue=value;
  //   if(this.sourceValue==='aadhaar_card')
  //   {
  // this.showData=true;
  //   }
  //   else{
  //     this.showData=false;
  //   }
  // }
}
