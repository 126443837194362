import { Component, OnInit, Input, ViewChild, ElementRef, NgZone, Renderer2 } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { BehaviorSubject } from 'rxjs';
import { Ubrequest } from '../../../../models/request.model';
import { AuthenticationService } from '../../../../services/authentication/authentication.service';
import { ReferrerCodeService } from '../../../../services/referrer-code.service';
import { NgbTypeahead } from '@ng-bootstrap/ng-bootstrap';
import { Observable, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map, merge } from 'rxjs/operators';
import { Router, ActivatedRoute } from '@angular/router';
import { MapsAPILoader, GoogleMapsAPIWrapper, AgmMap, LatLngBounds, LatLngBoundsLiteral } from '@agm/core';
import { FlatpickrOptions } from 'ng2-flatpickr';
import { DataService } from '../../../../services/dataService/data.service';
import { DatePipe } from '@angular/common';
import { DOCUMENT } from '@angular/common';
import { Inject, AfterViewInit } from '@angular/core';
//import { ViralLoopsService } from './viral-loops/services/viral-loops.service';
declare var $: any
@Component({
  selector: 'app-donor-sign-up',
  templateUrl: './donor-sign-up.component.html',
  styleUrls: ['./donor-sign-up.component.css']
})
export class DonorSignUpComponent implements OnInit {

  request_data: any;
  ubSignUpForm: FormGroup;
  verifyOtpForm: FormGroup;
  isSignUpDone: boolean = false;
  isAuthFormSubmitted: boolean = false;
  @ViewChild('ubSLocation', { static: true }) public searchElementRef: ElementRef;
  @ViewChild('instance', { static: true }) instance: NgbTypeahead;
  focus$ = new Subject<string>();
  click$ = new Subject<string>();
  public model: any;
  noResult = false;
  public geoCoder;
  ubu_latitude: number;
  ubu_longitude: number;
  ubu_locality: string;
  ubu_address: string;
  ubu_state: string;
  ubu_country: string;
  ubu_zipcode: string;
  ubu_phonenumber: number;
  authUserData: any;
  donor_location: any;
  dateTimeFormatConfigAge: any = {};
  dateTimeFormatConfig: any = {};
  dateTimeFormatConfigDose: any = {};
  otp_timer_start: boolean;
  isvalidRefCode: any;
  referelcode: string;
  isValidCowinDate: any;
  cvDoseFormVal: boolean = false;
  subject = new Subject<string>();
  bloodGroupListData = [
    { id: 1, name: 'A+' },
    { id: 2, name: 'A-' },
    { id: 3, name: 'B+' },
    { id: 4, name: 'B-' },
    { id: 5, name: 'AB+' },
    { id: 6, name: 'AB-' },
    { id: 7, name: 'O+' },
    { id: 8, name: 'O-' },
    { id: 9, name: 'A1+' },
    { id: 10, name: 'A1-' },
    { id: 11, name: 'A2+' },
    { id: 12, name: 'A2-' },
    { id: 13, name: 'A1B+' },
    { id: 14, name: 'A1B-' },
    { id: 15, name: 'A2B+' },
    { id: 16, name: 'A2B-' },
    { id: 17, name: 'Bombay Blood Group' },
    { id: 18, name: 'INRA' },
    { id: 19, name: "Don't Know" }
  ];

  constructor(
    private formBuilder: FormBuilder,
    private authentication: AuthenticationService,
    private toastrService: ToastrService,
    private ngxUIService: NgxUiLoaderService,
    private router: Router,
    private dataService: DataService,
    private mapsAPILoader: MapsAPILoader,
    private datePipe: DatePipe,
    private ngZone: NgZone,
    private route: ActivatedRoute,
    private referrerCode: ReferrerCodeService,
    private _renderer2: Renderer2,

    @Inject(DOCUMENT) private _document) {
    this.route.queryParams.subscribe(params => {
      this.referelcode = params['referral-code'];
      // console.log(this.referelcode);
      if (this.referelcode) {
        this.checkRefercode(this.referelcode, 'url');
      }
    });

  }

  ngOnInit() {
    //  this.viralCode();
    this.dataService.changeMessage("blooddonor");
    var makeDate = new Date();
    makeDate.setFullYear(makeDate.getFullYear() - 18);
    var newDate = makeDate;
    // console.log(makeDate);
    this.dateTimeFormatConfigAge = {
      mode: 'single',
      enableTime: false,
      dateFormat: "d-m-Y",
      maxDate: newDate
    };

    this.dateTimeFormatConfigDose = {
      mode: 'single',
      enableTime: false,
      dateFormat: "d-m-Y",
      maxDate: new Date()
    };

    this.dateTimeFormatConfig = {
      mode: 'single',
      enableTime: false,
      //  dateFormat: "Y-m-d H:i",
      dateFormat: "d-m-Y",
      maxDate: new Date()
    }

    this.ubSignUpForm = this.formBuilder.group({
      ubsp_first_name: ['', Validators.required],
      ubsp_last_name: ['', Validators.required],
      ubsp_email: ['', [
        Validators.required,
        Validators.email,
        Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,3}$')]],

      ubsp_blood_group: ['', Validators.required],
      ubsp_location: ['', Validators.required],
      ubsp_phone: ['', [Validators.required, Validators.pattern(/^[6-9]\d{9}$/)]],
      ubsp_dob: ['', Validators.required],
      ubsp_istatooed: ['0'],
      ubsp_ishiv: ['0'],
      ubsp_transportation: ['0'],
      ubsp_refcode: [this.referelcode],
      isCVCheck: ['0'],
      cv_dose_one: [''],
      cv_dose_two: [''],
      last_donated: ['']
    });

    this.verifyOtpForm = this.formBuilder.group({
      ubsp_otp: ['', Validators.required],
    });

    //OnChange get location
    this.mapsAPILoader.load().then(() => {

      this.geoCoder = new google.maps.Geocoder;
      let autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement);
      autocomplete.addListener("place_changed", () => {
        this.ngZone.run(() => {
          //get the place result
          let place: google.maps.places.PlaceResult = autocomplete.getPlace();
          //verify result
          if (place.geometry === undefined || place.geometry === null) {
            return;
          }
          // console.log("placefrom google",place);
          //set latitude, longitude and zoom
          this.donor_location = place.name;
          this.ubu_latitude = place.geometry.location.lat();
          this.ubu_longitude = place.geometry.location.lng();
          // console.log( this.ubu_latitude,this.ubu_longitude);
          this.getAddress(this.ubu_latitude, this.ubu_longitude);
        });
      });
    });

  }
  checkRefercode(code: any, action: string) {
    if (action == 'url') {
      var refer = code;
    } else if (action == 'type') {
      refer = code.target.value;
    }
    if (refer.length == 6 || refer.length == 8) {
      //  console.log('test123',refer);
      this.referrerCode.validRefCode(refer).subscribe(data => {
        // console.log('test123',data);
        this.isvalidRefCode = data;
        //   console.log('test_refcode', this.isvalidRefCode);
      });
    } else if (refer.length < 6 || refer.length == 7 || refer.length > 8) {
      $('.valid_m1').removeClass('valid_val').addClass('not_valid_val');
    }
    if (refer.length == 0) {
      //console.log("innnnnn");
      $('.valid_m1').removeClass('not_valid_val valid_val');
    }
  }

  get ubspFormControl() { return this.ubSignUpForm.controls; }

  /**
   * 
   * @param signUpData 
   */
  signUpUser(signUpData: any) {
    var _self = this;
    this.isSignUpDone = true;
    let cdose1 = this.datePipe.transform(signUpData["cv_dose_one"], "dd-MM-yyyy");
    let cdose2 = this.datePipe.transform(signUpData["cv_dose_two"], "dd-MM-yyyy");
    if (cdose1 != null && cdose2 != null) {
      this.isValidCowinDate = this.validateCowinDates(cdose1, cdose2);
    } else {
      this.isValidCowinDate = true;
    }

    if (this.ubSignUpForm.invalid) {
      return false;
    } else {
      if (signUpData['ubsp_refcode']) {
        if (this.isvalidRefCode != 1) {
          this.toastrService.error("Invalid referral code. Please enter valid code");
          $('.valid_m1').removeClass('valid_val').addClass('not_valid_val');
          return false;
        }
      }
      if (this.ubu_latitude == undefined) {
        this.toastrService.error("Invalid Location. Please enter google suggested locations");
        return false;
      }
      if (this.cvDoseFormVal == true && cdose1 == null) {
        this.toastrService.info("Please Enter First vaccine date");
        return false;
      }
      if (this.isValidCowinDate !== true) {
        console.log("invalid cowin dates");
        return false;
      }

      if ($("#termsAndCondition").prop("checked") !== true) {
        this.toastrService.info("Please accept the terms and Conditions");
        return false;
      }

      this.ngxUIService.start();

      this.authUserData = {
        "action": "donor",
        "first_name": signUpData['ubsp_first_name'],
        "last_name": signUpData['ubsp_last_name'],
        "email": signUpData['ubsp_email'],
        "blood_group": signUpData['ubsp_blood_group'],
        "address": this.donor_location,
        "locality": this.ubu_locality,
        "city": this.ubu_locality,
        "state": this.ubu_state,
        "country": this.ubu_country,
        "zipcode": this.ubu_zipcode,
        "latitude": this.ubu_latitude,
        "longitude": this.ubu_longitude,
        "age": this.datePipe.transform(signUpData["ubsp_dob"][0], "yyyy-MM-dd"),
        "referral_code": signUpData['ubsp_refcode'],
        "is_tattoo_in_past": signUpData['ubsp_istatooed'],
        "have_hiv_tested_positive": signUpData['ubsp_ishiv'],
        "device_type": 1,
        "have_covid_vaccine": signUpData["isCVCheck"],
        "cv_dose_one": this.datePipe.transform(signUpData["cv_dose_one"][0], "yyyy-MM-dd"),
        "cv_dose_two": this.datePipe.transform(signUpData["cv_dose_two"][0], "yyyy-MM-dd"),
        "last_donated_date": this.datePipe.transform(signUpData["last_donated"][0], "yyyy-MM-dd"),
      };
      // console.log( this.authUserData); return false;
      this.ubu_phonenumber = signUpData['ubsp_phone'];
      this.dataService.changeMobile(this.ubu_phonenumber);
      this.dataService.changeName(signUpData['ubsp_first_name']);
      this.dataService.changeEmail(signUpData['ubsp_email']);
      const isPhoneNumberExist = {
        "action": "verify_user",
        "phone": signUpData['ubsp_phone'],
      };

      const isEmailAlreadyExist = {
        "action": "verify_email",
        "email": signUpData['ubsp_email'],
      };

      this.authentication.ubuAuthenticate(isPhoneNumberExist).subscribe(
        response => {
          if (response['status_code'] == 200 && response['is_user_signup'] == true) {
            this.ngxUIService.stop();
            this.toastrService.info('', 'Usaver with mobile number already exists, Thank you');
            return false;
          } else {
            _self.authentication.ubuAuthenticate(isEmailAlreadyExist).subscribe(
              response => {
                if (response['status_code'] == 200 && response['is_user_signup'] == true) {
                  this.ngxUIService.stop();
                  this.toastrService.info('', 'Usaver with email id already exists, Thank you');
                  return false;
                } else {

                  _self.SendOtp(_self.ubu_phonenumber);
                }
              }, error => {
                this.ngxUIService.stop();
                this.toastrService.info('', "Oops, Something went wrong, Please try again");
              });
          }
        }, error => {
          this.ngxUIService.stop();
          this.toastrService.info('', "Oops, Something went wrong, Please try again");
        });
    }
  }

  runRefcodescript(firstname, email, mobile) {
    let script = this._renderer2.createElement('script');
    script.type = 'text/javascript';
    script.text = "var ir = ir || function(){(window.ir.q = window.ir.q || []).push(arguments)}; \n"
      + "var invite_referrals = window.invite_referrals || {}; (function() { "
      + "invite_referrals.auth = { bid_e :'A3D09815C3D7032D2A3E2E74DB5CBBB9',"
      + "bid : '32063',"
      + "t : '420', "
      + "email : '', userParams : {'firstname': ''}}; "
      + "invite_referrals.async = false;"
    script.src = "//cdn.invitereferrals.com/js/invite-referrals-1.0.js";
    this._renderer2.appendChild(this._document.body, script);

    let newscript = this._renderer2.createElement('script');
    newscript.type = 'text/javascript';
    newscript.text = "$(function() {"
      + " var ir_fname ='" + firstname + "';\n "
      + " var ir_email  ='" + email + "';\n "
      + " var ir_mobile  ='" + mobile + "';\n "
      + "var ir_orderid = ir_email;"
      + "var ir_event = 'register'; "
      + " var ir_customval = ''; "
      + " var ir_amount = '';"
      + " var ir_coupon_code = ''; " // Personalized
      + " if( ir_email != '' && ir_fname != '' ) {"
      + "ir('track',{ orderID : ir_orderid , event: ir_event, fname: ir_fname , mobile: ir_mobile , email: ir_email , order_custom_val: ir_customval , purchaseValue: ir_amount, couponCode: ir_coupon_code });"
      + " } "
      + "});"

    this._renderer2.appendChild(this._document.body, newscript);

  }
  setCVStatus(CVStatus: any) {
    /* if (CVStatus == 1) {
       this.cvDoseFormVal = true;
     } else {
       this.cvDoseFormVal = false;
     }*/
  }

  SendOtp(phone: any) {
    if (phone) {
      const authUserData = {
        "action": "sendotp",
        "std_code": "+91",
        "phone": phone,
      };
      this.ngxUIService.start();
      this.authentication.ubuAuthenticate(authUserData)
        .subscribe(
          response => {
            if (response['status_code'] == 200) {
              this.ngxUIService.stop();
              this.toastrService.success('', response['success']['message']);
              $('#otpModalUI').modal('show');
              /* $('.re_mobile_set').hide();
               $('.opt_section').show();*/
              this.otp_timer_start = true;
            } else {
              this.toastrService.info('', response['error'].message);
              this.ngxUIService.stop();
            }
          }, error => {
            this.ngxUIService.stop();
            this.toastrService.info('', "Some thing went wrong");
          });
    } else {
      this.toastrService.info('', "Please enter mobile number");
    }

  }

  get ubAuthFormControl() { return this.verifyOtpForm.controls; }

  /**
  * 
  * @param latitude 
  * @param longitude 
  */
  getAddress(latitude: number, longitude: number) {
    let _self = this;
    this.mapsAPILoader.load().then(() => {
      _self.geoCoder.geocode({ 'location': { lat: latitude, lng: longitude } }, (results: any, status: any) => {
        if (status === 'OK') {
          if (results[0].formatted_address) {
            _self.getDetailedAddress(results[0].formatted_address);
          } else {
            _self.toastrService.info('', 'No results found');
          }
        } else {
          console.log('', 'Geocoder failed due to: ' + status);
        }

      });
    });

  }



  getDetailedAddress(address: string) {
    let self = this;
    this.geoCoder.geocode({ 'address': address }, (results: any, status: any) => {
      if (status === 'OK') {
        //this.resetLocationForm();
        let geo_address = "";
        let geo_locality = "";
        let geo_state = "";
        let geo_country = "";
        let geo_zipcode = "";
        if (results[0].address_components) {
          $.each(results[0].address_components, function (key: any, value: any) {
            //console.log(value.types[0]);
            if ((value.types[0]) == 'subpremise' || (value.types[0]) == 'premise' || (value.types[0]) == 'route' || (value.types[0]) == 'political' || (value.types[0]) == 'street_number' || (value.types[0]) == 'neighborhood' || (value.types[0]) == 'administrative_area_level_4') {
              if (geo_address) {
                geo_address += ', ' + value.long_name;
              } else {
                geo_address = value.long_name
              }
              //Set Address 
              self.ubu_address = geo_address;
              // self.profileLocationInfoForm.get('ubu_address').setValue(geo_address);
            }

            if ((value.types[0]) == 'locality') {

              geo_locality = value.long_name;

              //Set locality 
              self.ubu_locality = geo_locality;
            }

            if ((value.types[0]) == 'administrative_area_level_1') {

              geo_state = value.long_name
              //Set state 
              self.ubu_state = geo_state;

            }
            if ((value.types[0]) == 'country') {

              geo_country = value.long_name
              //Set country
              self.ubu_country = geo_country;

            }

            if ((value.types[0]) == 'postal_code') {

              geo_zipcode = value.long_name
              //Set zip
              self.ubu_zipcode = geo_zipcode;

            }

          });

        } else {
          this.toastrService.info('', "No results found");
        }
      } else {
        // this.toastrService.warning('', "Geocoder failed due to: " + status);

      }

    });
  }
  /**
   * 
   */
  getbloodGroup = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      // merge(this.focus$),
      // merge(this.click$.pipe(filter(() => !this.instance.isPopupOpen()))),
      map((term) => {
        console.log({ term });
        return term === ''
          ? this.bloodGroupListData
          : this.bloodGroupListData
            .filter(v => v.name.toLowerCase().indexOf(term.toLowerCase()) > -1)
            .slice(0, 10)
      })
    );

  bloodDataformatter = (x: { name: string }) => x.name;
  typeaheadNoResults(event: boolean): void {
    this.noResult = event;
  }

  validateCowinDates(sDate: string, eDate: string) {
    this.isValidCowinDate = true;
    if ((sDate == null || eDate == null)) {
      this.toastrService.error('', 'First Dose and  Second Dose dates are required');
      this.isValidCowinDate = false;
    }
    // console.log(sDate,'sdate');
    // console.log(eDate,'edate'); 
    if ((sDate != null && eDate != null) && (sDate > eDate)) {
      console.log("invalid");

      this.toastrService.error('Vaccine First dose date should be less than Second dose date.');
      this.isValidCowinDate = false;
    }
    return this.isValidCowinDate;

  }

  numberOnly(event: any): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }
  keyPressAlphaNumeric(event) {
    var inp = String.fromCharCode(event.keyCode);

    if (/[a-zA-Z]/.test(inp)) {
      return true;
    } else {
      event.preventDefault();
      return false;
    }
  }

}
