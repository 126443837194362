import { Component, OnInit, ViewChild, ElementRef, NgZone, Input, Output, EventEmitter } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { MapsAPILoader, GoogleMapsAPIWrapper, AgmMap, LatLngBounds, LatLngBoundsLiteral } from '@agm/core';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { FlatpickrOptions } from 'ng2-flatpickr';
import { DatePipe } from '@angular/common'
import { BehaviorSubject } from 'rxjs';
import { DataService } from '../../../services/user-data/data.service';
declare var $: any;

@Component({
  selector: 'app-service-request',
  templateUrl: './service-request.component.html',
  styleUrls: ['./service-request.component.css']
})
export class ServiceRequestComponent implements OnInit {
  request_user: number;
  request_phone: number;
  user_data:any;
  
  
  @ViewChild('ubr_location', { static: true }) public searchElementRef: ElementRef;
  ubrBloodRequestForm: FormGroup;
  
  isBloodRequestSubmit: boolean = false;
  dateTimeFormatConfig: FlatpickrOptions = {
    enableTime: false,
    //dateFormat: "Y-m-d H:i",
    dateFormat: "Y-m-d",
    minDate: new Date()
  };
  hospital_location: string;
  private searchTerm: string;
  address: string;
  private geoCoder;
  map: google.maps.Map;
  fitBounds: boolean = false;
  latitude: number;
  longitude: number;
  unitsIteratorArray = Array;
  bloodUnits : number = 10;
  public imgPath;
  imgURL: any;
  isdoc:boolean =false;
  bloodGroupDataList = [
    { value: 1, label: 'A+' },
    { value: 2, label: 'A-' },
    { value: 3, label: 'B+' },
    { value: 4, label: 'B-' },
    { value: 5, label: 'AB+' },
    { value: 6, label: 'AB-' },
    { value: 7, label: 'O+' },
    { value: 8, label: 'O-' },
    { value: 9, label: 'A1+' },
    { value: 10, label: 'A1-' },
    { value: 11, label: 'A2+' },
    { value: 12, label: 'A2-' },
    { value: 13, label: 'A1B+' },
    { value: 14, label: 'A1B-' },
    { value: 15, label: 'A2B+' },
    { value: 16, label: 'A2B-' },
    { value: 17, label: 'Bombay Blood Group' },
    { value: 18, label: 'INRA' },
    { value: 19, label: "Don't Know" }
  ];

  constructor(
    private formBuilder: FormBuilder,
    private dataService: DataService,
    private toastrService: ToastrService,
    private ngxUiLoaderService: NgxUiLoaderService,
    private router: Router,
    private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone,
    private datePipe: DatePipe
  ) {
    this.ubrBloodRequestForm = this.formBuilder.group({
    //  ubr_requested_for_other: ['', Validators.required],
      ubr_name: ['', Validators.required],
      ubr_lname: ['', Validators.required],
      uba_fname: ['', Validators.required],
      uba_lname: ['', Validators.required],
      uba_phone: ['',[Validators.required, Validators.pattern(/^[6-9]\d{9}$/)]],
      ubr_blood_group: ['', Validators.required],
      ubr_blood_type: ['', Validators.required],
      ubr_blood_units: ['', Validators.required],
      ubr_request_datetime: ['', Validators.required],
      ubr_request_location: ['', Validators.required],
      location_for_donation: ['', Validators.required],
      ubr_is_emergency: [''],
      ubr_requsitionfile: [''],
      // ubr_terms_and_conditions : ['', Validators.required]
    });

    this.ubrBloodRequestForm.controls['ubr_blood_group'].setValue('');
    this.ubrBloodRequestForm.controls['ubr_blood_type'].setValue('');
    this.ubrBloodRequestForm.controls['ubr_blood_units'].setValue('');
    this.ubrBloodRequestForm.controls['location_for_donation'].setValue('');
    
  }

  ngOnInit() {
    this.setDefaultValues();
    this.user_data =  JSON.parse(localStorage.getItem("ubu_auth_user"));
    //console.log(this.user_data);
    this.request_user = this.user_data.user_id;
    this.request_phone = this.user_data.phone;
    this.mapsAPILoader.load().then(() => {
      this.geoCoder = new google.maps.Geocoder;
      let autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement);
      autocomplete.addListener("place_changed", () => {
        this.ngZone.run(() => {
          //get the place result
          let place: google.maps.places.PlaceResult = autocomplete.getPlace();
          //verify result
          if (place.geometry === undefined || place.geometry === null) {
            return;
          }
          //set latitude, longitude and zoom
          this.hospital_location = place.name;
          this.latitude = place.geometry.location.lat();
          this.longitude = place.geometry.location.lng();

          // this.mapData = {"latitude" :this.latitude,"longitude":this.longitude}
          // this.donorListChangeEvent.emit(this.mapData);

        });
      });
    });
  }

  bloodUnitsCount(unit: number) {
    return new Array(unit);
  }

  docForm(event: any) {
    this.isdoc = false;
    let files = event.target.files;
    if (files.length === 0) {
      return;
    }
    var sFileName = files[0].name;
    var sFileExtension = sFileName.split('.')[sFileName.split('.').length - 1].toLowerCase();
    var iFileSize = files[0].size;
    var iConvert = (iFileSize / 1048576).toFixed(2);
    if (!(sFileExtension === "png" ||
      sFileExtension === "jpeg" ||
      sFileExtension === "jpg") || iFileSize > 1048576) { /// 1 mb
      var txt = "File type : " + sFileExtension + "\n\n";
      txt += "Size: " + iConvert + " MB \n\n";
      txt += "Please make sure your file is in image format and less than 1 MB.\n\n";
      this.toastrService.warning('', txt);
      this.isdoc = true;
    } else {
      var reader = new FileReader();
      this.imgPath = files;
      reader.readAsDataURL(files[0]);
      reader.onload = (_event) => {
        this.imgURL = reader.result;
      }
      this.isdoc = false;

    }
  }

  get ubrFormControl() { return this.ubrBloodRequestForm.controls; }

  setDefaultValues() {
    this.ubrBloodRequestForm.patchValue({ ubr_requested_for_other: "2" });
  }
  
  requestService(postRequestData: any) {
    this.isBloodRequestSubmit = true;
    let _self = this;
    if (this.ubrBloodRequestForm.invalid) {
      return false;
    }
    if(this.latitude == undefined){
      this.toastrService.error("Invalid Hospital Name/Location. Please enter google suggested locations");
      return false;
    }
    if ($("#termsAndCondition").prop("checked") !== true) {
      this.toastrService.info("Please accept the terms and Conditions");
      return false;
    }
    if(this.isdoc == true){
     // console.log(this.isdoc);
      this.toastrService.error("Please upload valid  doctor requisition document");
      return false;
    }
    if (this.imgURL == undefined) {
      this.imgURL = '';
    }
    this.ngxUiLoaderService.start();
    if (postRequestData) {
      const requestData = {
        "action": "create_service_request",
        "user_id": this.request_user,
        "patient_name": postRequestData["ubr_name"],
        "patient_last_name" : postRequestData["ubr_lname"],
        "contact_number": postRequestData["uba_phone"],
        "contact_name": postRequestData["uba_fname"],
        "contact_last_name": postRequestData["uba_lname"],  
        // "is_request_for_other": postRequestData["ubr_requested_for_other"],
        "service_type": postRequestData["ubr_blood_type"],
        "blood_group": postRequestData["ubr_blood_group"],
        "units": postRequestData["ubr_blood_units"],
        "required_date": this.datePipe.transform(postRequestData["ubr_request_datetime"][0], "yyyy-MM-dd hh:mm"),
        "address": this.hospital_location,
        "latitude": this.latitude,
        "longitude": this.longitude,
        "is_emergency": (postRequestData["ubr_is_emergency"]) ? true : false,
        "is_share_on_sm": false,
        "location_for_donation": postRequestData["location_for_donation"],
        "doctor_requisition_form": this.imgURL
      }
    //  {"action":"create_service_request","user_id":723,"is_request_for_other":1,"service_type":1,"blood_group":7,"contact_name":"teja tejatejA","contact_last_name":"","contact_number":7989698695,"units":1,"required_date":"2020-01-02","location_for_donation":1,"address":"Near Academic Heights School Lane, Satya Lakshmi Nagar Bollaram Rd Balaji Enclave, Sri Aurobindo Colony, Miyapur Hyderabad, Telangana 500049","latitude":17.4484363,"longitude":78.3741361,"is_emergency":true,"is_share_on_sm":true,"doctor_requisition_form":"","patient_name":"Ravi","patient_age":"1991-11-09","is_plasma_request":0,"city":"","state":"","country":"","zipcode":"","comment":"","referral_code":"M8YYUU"}
      this.dataService.createRequest(requestData).subscribe(
        response => {
          if (response['status_code'] == 200) {
            _self.sendNotification(response['request_id'], _self.request_user);
            $('#ubuBloodRequestFormModal').modal('hide');
            this.toastrService.success('', response['success']['message']);                        
            this.ngxUiLoaderService.stop();  
             this.router.navigate(['/my-request-list'])
              .then(() => {
                window.location.reload();
              });
            //this.router.navigate(['/my-request-list']);
          }
          if (response['status_code'] == 201) {
            this.ngxUiLoaderService.stop();
            this.toastrService.info('', response['error']['message']);
          }
        }, error => {
          this.ngxUiLoaderService.stop();
          this.toastrService.info('', "Some thing went wrong");
        });
    }
    this.ngxUiLoaderService.stop();
  }
  /**
   * 
   * @param request_id 
   * @param user_id 
   */
  sendNotification(request_id: number, user_id: number) {
    this.ngxUiLoaderService.start();
    const dataFilterAction = { "action": "send_notification", "user_id": user_id, "request_id": request_id }
    this.dataService.createRequest(dataFilterAction).subscribe(
      response => {
        this.ngxUiLoaderService.stop();
        this.toastrService.success('', response['success']['message']);
      }, error => {
        this.ngxUiLoaderService.stop();
        this.toastrService.info('', "Some thing went wrong");
      });

  }

  keyPressAlphaNumeric(event) {
    var inp = String.fromCharCode(event.keyCode);

    if (/[a-zA-Z]/.test(inp)) {
      return true;
    } else {
      event.preventDefault();
      return false;
    }
  }
  numberOnly(event:any): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }
}
