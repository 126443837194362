import { Component, OnInit,Output,EventEmitter } from '@angular/core';
declare var $: any;
@Component({
  selector: 'app-left-sidebar',
  templateUrl: './left-sidebar.component.html',
  styleUrls: ['./left-sidebar.component.css']
})
export class LeftSidebarComponent implements OnInit {
  @Output() toggleChanged: EventEmitter<boolean> = new EventEmitter();
  constructor() { }

  ngOnInit() {
  }

  openBloodRequestForm() {
    $('#ubuBloodRequestFormModal').modal('show');
  }

  toggleSidebar(){   
    this.toggleChanged.emit();
  }

}
