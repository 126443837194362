import { Component, OnInit } from '@angular/core';
import { SwiperOptions } from 'swiper';
import { Observable } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Router, ActivatedRoute } from '@angular/router';
import { DataService } from '../../../services/user-data/data.service';
declare var $: any;


@Component({
  selector: 'app-request-cancel-form',
  templateUrl: './request-cancel-form.component.html',
  styleUrls: ['./request-cancel-form.component.css']
})
export class RequestCancelFormComponent implements OnInit {
  currentRequestId:number;
  constructor(
    private dataService: DataService,
    private ngxUiLoaderService: NgxUiLoaderService,
    private toastrService: ToastrService
  ) { }

  ngOnInit() {
  }

  cancelMyRequest(request_id:number){   
    this.currentRequestId= request_id;
    $("#cancelReasonForm").modal('show');
   }
 /**
    * 
    * @param request_id 
    */
   sunmitCancelRequest(){    
        
   
     if($("input[name='cancelreason']:checked").val() == 4 && $("#cancelreasonComments").val().length < 3){
       this.toastrService.info('', 'Please enter the comments for cancel request');
       return false; 
     }
     const reason:number = ($("input[name='cancelreason']:checked").val())?$("input[name='cancelreason']:checked").val():1;
     const comments:string = ($("#cancelreasonComments").val())?$("#cancelreasonComments").val():"no comments";
     const dataFilterAction = { "action": "receiver_is_cancelled", "request_id": this.currentRequestId,"reason":reason,"comment":comments }
     this.ngxUiLoaderService.start();
     this.dataService.requestActionService(dataFilterAction).subscribe(
       response => {
         if (response.status_code == "200") {
           this.ngxUiLoaderService.stop();
           this.toastrService.success('', response['success']['message']);
           $("#cancelReasonForm").modal('hide');
         }
         if (response.status_code == "201") {
           this.ngxUiLoaderService.stop();
           this.toastrService.info('', response['error']['message']);
         }
 
       }, error => {
         this.ngxUiLoaderService.stop();
         this.toastrService.info('', "Some thing went wrong");
       });
   
   }
 
   closeCancelRequest(){
     $("#cancelReasonForm").modal('hide');
   }
 

}
