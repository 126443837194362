export class AppApiEndpoints {
    public userLogout = 'logOut';
    public getMetaDataList = 'getMetaDataList';
    //public getMetStateList = 'getMetStateList';
    public userProfileDetails = 'userProfileDetails';
    public updateUserDetails = 'updateUserDetails';
    public userDataSearch = 'usersSearch';
    public getUserDetails = 'getUserDetails';
    public sendOTP = 'sendOTP';
    public serviceSearch = "serviceSearch";
    public forgotPassword = "forgotPassword";
    public resetPassword = "resetPassword";
    public serviceRequests = "serviceRequests";
    public verifyPhoneNumber = "verifyPhoneNumber";
    public userAuthentication = "userAuthentication";
    public requestService = "requestService";
    public getUbList = "getUbList";
    public getRequestTransaction = "getRequestTransaction";
    public getRequestDetails = "getRequestDetails";
    public userStatsCount = "userStatsCount";
    public getDonorTransactions = "getDonorTransactions";
    public covid19StatDataService = "getCovid19StatsData";
    public searchCovid19StatsData = "searchCovid19StatsData";
    public verifyUsaver = "verifyToken";
    public plasmaUserAuth = "plasmaUserAuthentication";
    public plasmaRequest = "plasmaRequestService";
    public serviceList = "serviceList";
    public inviteSend = "inviteSend";
    public saveDonation = "addDonation";
    public getBloodRequestDetails = "getBloodRequestDetails";
    public saveUsaverFeedback = "addFeedback";
  getMetaStateList: string;
  static getMetStateList: any;


}