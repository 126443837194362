import { Component, OnInit } from '@angular/core';
import { ChangeDetectorRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { ExternalLibraryService } from './util';
import { NgxUiLoaderService } from 'ngx-ui-loader';
declare let Razorpay: any;
declare var $:any
@Component({
  selector: 'app-contributionform',
  templateUrl: './contributionform.component.html',
  styleUrls: ['./contributionform.component.css']
})
export class ContributionformComponent implements OnInit {

  constructor(
    private razorpayService: ExternalLibraryService, 
    private cd:  ChangeDetectorRef,
    private formBuilder: FormBuilder,
    private ngxUiLoaderService: NgxUiLoaderService,) { }
  name = 'Angular';
  response;
  razorpayResponse;
  showModal = false;
  ubContributeForm: FormGroup;
  isDonorSubmit: boolean = false;
  params:any;
  ngOnInit() {
    this.addContributionForm();
    this.razorpayService
    .lazyLoadLibrary('https://checkout.razorpay.com/v1/checkout.js')
    .subscribe();
  }

  addContributionForm() {
    this.ubContributeForm = this.formBuilder.group({
      ubd_name: ['', [Validators.required]],
      ubd_mobile: ['', [Validators.required, Validators.pattern(/^[6-9]\d{9}$/)]],
      ubd_email: ['', [
        Validators.required,
        Validators.email,
        Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
      ubd_amount: ['', [Validators.required]],
      ubd_notes :['']
    });
  }

  RAZORPAY_OPTIONS = {
    "key":"rzp_test_4VNGs8GjqQvx7P",//"rzp_live_0OhhivD3EhzTFM", //"rzp_test_4VNGs8GjqQvx7P",
    "amount":  1000 + "00",
    "name": "Ublood",
    "order_id": "",
    "description": "Noble TO Save Life",
    "image": "https://ublood.com/assets/images/logo/logo-h.png",
    "prefill": {
      "name": "Amar",
      "email": "amar@ublood.com",
      "contact": "9032308308",
      "method": ""
    },
    "modal": {},
    "theme": {
      "color": "#0096C5"
    }
  };
  
  
  get ubdFormControl() { return this.ubContributeForm.controls; }

  public contributeNow(postDonor: any){

  //  console.log(postDonor);
  this.params = postDonor;
    this.isDonorSubmit = true;
    var _self= this;
    if (this.ubContributeForm.invalid) {
     // console.log("invalid data");
      return false;
    }else {
      this.proceed(this.params);
    }
    

   // console.log(this.razorpayResponse);
  //  this.ngxUiLoaderService.start();
    
  //  if(this.razorpayResponse.razorpay_payment_id !=''){

       

     /* this.auth.ubuplasmaAutheticate(authUserData)
      .subscribe(
        response => {
          if (response['status_code'] == 200) {
            this.ngxUiLoaderService.stop();
            this.toastrService.success('', response['success']['message']);
            $('#otpModalUI').modal('show');
            this.otp_timer_start = true;
          }
        }, error => {
          this.ngxUiLoaderService.stop();
          this.toastrService.info('', "Some thing went wrong");
        });
  } else {
    this.toastrService.info('', "Please enter mobile number");
  }*/


    //}


  }
  public proceed(params) {
  //  console.log(params);
    this.RAZORPAY_OPTIONS.amount = params.ubd_amount+"00";
    this.RAZORPAY_OPTIONS.prefill.email = params.ubd_email;
    this.RAZORPAY_OPTIONS.prefill.name = params.ubd_name;
    this.RAZORPAY_OPTIONS.prefill.contact = params.ubd_mobile;
    // binding this object to both success and dismiss handler
    this.RAZORPAY_OPTIONS['handler'] = this.razorPaySuccessHandler.bind(this);
    
    // this.showPopup();
  // console.log(this.RAZORPAY_OPTIONS);
    let razorpay = new Razorpay(this.RAZORPAY_OPTIONS)
    razorpay.open();
  }

  public razorPaySuccessHandler(response) {
    console.log(response);
   alert(response.razorpay_payment_id);
    this.razorpayResponse = response;
    if(this.razorpayResponse.razorpay_payment_id !=''){

  //     this.auth.ubuplasmaAutheticate(authUserData)
  //     .subscribe(
  //       response => {
  //         if (response['status_code'] == 200) {
  //           this.ngxUiLoaderService.stop();
  //           this.toastrService.success('', response['success']['message']);
  //           $('#otpModalUI').modal('show');
  //           this.otp_timer_start = true;
  //         }
  //       }, error => {
  //         this.ngxUiLoaderService.stop();
  //         this.toastrService.info('', "Some thing went wrong");
  //       });
  // } else {
  //   this.toastrService.info('', "Please enter mobile number");
  // }
      
    }
    this.showModal = true;
    this.cd.detectChanges()
//    document.getElementById('razorpay-response').style.display = 'block';
  }
  


  numberOnly(event: any): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

history(e:Event){
     $('.donation_form,.fa-chevron-right.arrows').hide();
     $('.dont_history,.fa-chevron-down.arrows').show();
  }
  donation(e:Event){
    $('.donation_form,.fa-chevron-right.arrows').show();
    $('.dont_history,.fa-chevron-down.arrows').hide();
  }

  


}
