import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { Component, OnInit, ViewChild, ElementRef, NgZone, EventEmitter, Output, Input } from '@angular/core';
import { DatePipe } from '@angular/common'
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { DataService } from '../../../../../services/user-data/data.service';
import { ManageProfileService } from '../../../../../services/ub-user/manage-profile/manage-profile.service';
import { Observable, Subject } from 'rxjs';

declare var $: any;


@Component({
  selector: 'app-contact-info',
  templateUrl: './contact-info.component.html',
  styleUrls: ['./contact-info.component.css']
})
export class ContactInfoComponent implements OnInit {
  profileConatctInfoForm: FormGroup;
  isContactInfoSubmitted: boolean = false;

  @Input() userProfileData: any;
  @Output() contactInfoUpdated: EventEmitter<boolean> = new EventEmitter();


  constructor(private formBuilder: FormBuilder, private dataService: DataService, private toastrService: ToastrService, private ngxUiService: NgxUiLoaderService,
    private datePipe: DatePipe, private manageProfileService: ManageProfileService) {

  }

  ngOnInit() {
    this.profileConatctInfoForm = this.formBuilder.group({
      ubu_phone:  ['',[Validators.required, Validators.pattern(/^[6-9]\d{9}$/)]]          
    });

    this.profileConatctInfoForm.get('ubu_phone').setValue(this.userProfileData.phone);   

  }

  get ubuContactProfileInfo() { return this.profileConatctInfoForm.controls; }
  /***
  * Update Profile Basic Info
  */
  updateContactProfileInfo(contactProfilePost: any) {
    this.isContactInfoSubmitted = true;
    if (this.profileConatctInfoForm.invalid) {
      this.toastrService.info('', "Please enter valid phone number");
      return false;
    }
    if(this.userProfileData.is_blood_request_accepted){
      this.toastrService.info('', 'Unable to change contact details now, Because you have already accepted blood request');
      return false;
    }

    this.ngxUiService.start();
    if (contactProfilePost) {
      let userBasicData = {
        "action": "update_phone",
        "phone": contactProfilePost['ubu_phone']
      }

      this.manageProfileService.updateProfileData(userBasicData)
        .subscribe(
          response => {
            if (response['status_code'] == 200) {
              this.toastrService.success('', response['success'].message);
              $('#profileContactInfoModal .close').trigger('click');
              this.contactInfoUpdated.emit(response['status_code']);
              this.ngxUiService.stop();
            } else {
              this.toastrService.info('', response['error'].message);
            }
            this.ngxUiService.stop();
          }, error => {
            this.toastrService.info('', "Some thing went wrong");
            this.ngxUiService.stop();
          });
    }
  }

}
