import { Component, OnInit, ViewChild, ElementRef, NgZone, Input, Output, EventEmitter } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { MapsAPILoader, GoogleMapsAPIWrapper, AgmMap, LatLngBounds, LatLngBoundsLiteral } from '@agm/core';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { FlatpickrOptions } from 'ng2-flatpickr';
import { DatePipe } from '@angular/common'
import { BehaviorSubject } from 'rxjs';
import {Ubrequest} from '../../../../models/request.model';
import { DataService } from '../../../../services/dataService/data.service';
declare var $: any;

@Component({
  selector: 'app-blood-request-form',
  templateUrl: './blood-request-form.component.html',
  styleUrls: ['./blood-request-form.component.css']
})
export class BloodRequestFormComponent implements OnInit {
  @Input() requested_user: number;
  @Input() request_phone_number: number
  @Input() request_data: Ubrequest
  
  @ViewChild('ubr_location', { static: true }) public searchElementRef: ElementRef;
  ubrBloodRequestForm: FormGroup;
  
  isBloodRequestSubmit: boolean = false;
  dateTimeFormatConfig: FlatpickrOptions = {
    mode:'single',
    enableTime: false,
  //  dateFormat: "Y-m-d H:i",
    dateFormat: "Y-m-d",
    minDate: new Date()
  };
  hospital_location: string;
  private searchTerm: string;
  address: string;
  private geoCoder;
  map: google.maps.Map;
  fitBounds: boolean = false;
  latitude: number;
  longitude: number;
  unitsIteratorArray = Array;
  bloodUnits : number = 10;
  bloodGroupDataList = [
    { id: 1, name: 'A+' },
    { id: 2, name: 'A-' },
    { id: 3, name: 'B+' },
    { id: 4, name: 'B-' },
    { id: 5, name: 'AB+' },
    { id: 6, name: 'AB-' },
    { id: 7, name: 'O+' },
    { id: 8, name: 'O-' },
    { id: 9, name: 'A1+' },
    { id: 10, name: 'A1-' },
    { id: 11, name: 'A2+' },
    { id: 12, name: 'A2-' },
    { id: 13, name: 'A1B+' },
    { id: 14, name: 'A1B-' },
    { id: 15, name: 'A2B+' },
    { id: 16, name: 'A2B-' },
    { id: 17, name: 'Bombay Blood Group' },
    { id: 18, name: 'INRA' }
  ];

  constructor(
    private formBuilder: FormBuilder,
    private dataService: DataService,
    private toastrService: ToastrService,
    private ngxUiLoaderService: NgxUiLoaderService,
    private router: Router,
    private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone,
    private datePipe: DatePipe
  ) {
    this.ubrBloodRequestForm = this.formBuilder.group({
     // ubr_requested_for_other: ['', Validators.required],
      ubr_name: ['', Validators.required],
      ubr_blood_group: ['', Validators.required],
      ubr_blood_type: ['', Validators.required],
      ubr_blood_units: ['', Validators.required],
      ubr_request_datetime: ['', Validators.required],
      ubr_request_location: ['', Validators.required],
      ubr_is_emergency: [''],
      // ubr_terms_and_conditions : ['', Validators.required]
    });

    this.ubrBloodRequestForm.controls['ubr_blood_group'].setValue('');
    this.ubrBloodRequestForm.controls['ubr_blood_type'].setValue('');
    this.ubrBloodRequestForm.controls['ubr_blood_units'].setValue('');
  }

  ngOnInit() {
    this.setDefaultValues();

    this.mapsAPILoader.load().then(() => {
      this.geoCoder = new google.maps.Geocoder;
      let autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement);
      autocomplete.addListener("place_changed", () => {
        this.ngZone.run(() => {
          //get the place result
          let place: google.maps.places.PlaceResult = autocomplete.getPlace();
          //verify result
          if (place.geometry === undefined || place.geometry === null) {
            return;
          }
          //set latitude, longitude and zoom
          this.hospital_location = place.name;
          this.latitude = place.geometry.location.lat();
          this.longitude = place.geometry.location.lng();

          // this.mapData = {"latitude" :this.latitude,"longitude":this.longitude}
          // this.donorListChangeEvent.emit(this.mapData);

        });
      });
    });
  }
  
  bloodUnitsCount(unit: number) {
    return new Array(unit);
  }


  get ubrFormControl() { return this.ubrBloodRequestForm.controls; }

  setDefaultValues() {
    this.ubrBloodRequestForm.patchValue({ ubr_requested_for_other: "2" });
  }

  requestService(postRequestData: any) {
    this.isBloodRequestSubmit = true;
    let _self = this;
    if (this.ubrBloodRequestForm.invalid) {
      return false;
    }

    if ($("#termsAndCondition").prop("checked") !== true) {
      this.toastrService.info("Please accept the terms and Conditions");
      return false;
    }
    this.ngxUiLoaderService.start();
    if (postRequestData) {
      const requestData = {
        "action": "create_service_request",
        "user_id": this.request_data.request_user,
        "contact_number": this.request_data.request_phone,
        "contact_name": postRequestData["ubr_name"],
      //  "is_request_for_other": postRequestData["ubr_requested_for_other"],
        "service_type": postRequestData["ubr_blood_type"],
        "blood_group": postRequestData["ubr_blood_group"],
        "units": postRequestData["ubr_blood_units"],
        "required_date": this.datePipe.transform(postRequestData["ubr_request_datetime"][0], "yyyy-MM-dd hh:mm"),
        "address": this.hospital_location,
        "latitude": this.latitude,
        "longitude": this.longitude,
        "is_emergency": (postRequestData["ubr_is_emergency"]) ? true : false,
        "is_share_on_sm": false
      }
      this.dataService.createRequest(requestData).subscribe(
        response => {
          if (response['status_code'] == 200) {
            _self.sendNotification(response['request_id'], _self.request_data.request_user);
            $('#ubuBloodRequestFormModal').modal('hide');
            this.toastrService.success('', response['success']['message']);            
            this.ngxUiLoaderService.stop();
            this.router.navigate(["/donor-map-view"]);
          }
          if (response['status_code'] == 201) {
            this.ngxUiLoaderService.stop();
            this.toastrService.info('', response['error']['message']);
          }
        }, error => {
          this.ngxUiLoaderService.stop();
          this.toastrService.info('', "Some thing went wrong");
        });
    }
    this.ngxUiLoaderService.stop();
  }
  /**
   * 
   * @param request_id 
   * @param user_id 
   */
  sendNotification(request_id: number, user_id: number) {
    this.ngxUiLoaderService.start();
    const dataFilterAction = { "action": "send_notification", "user_id": user_id, "request_id": request_id }
    this.dataService.createRequest(dataFilterAction).subscribe(
      response => {
        this.ngxUiLoaderService.stop();
        this.toastrService.success('', response['success']['message']);
      }, error => {
        this.ngxUiLoaderService.stop();
        this.toastrService.info('', "Some thing went wrong");
      });

  }
}
