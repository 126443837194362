import { Component, OnInit } from '@angular/core';
import { ChangeDetectorRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { ExternalLibraryService } from './util'
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { DataService } from '../../services/user-data/data.service';
import { ToastrService } from 'ngx-toastr';
import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';

import {
  Amountvalidate,
} from "./customValidation.validator";
declare let Razorpay: any;
declare var $: any
@Component({
  selector: 'app-dontations',
  templateUrl: './dontations.component.html',
  styleUrls: ['./dontations.component.css']
})
@Injectable()
export class DontationsComponent implements OnInit {
  constructor(
    private dataService: DataService,
    private toastr: ToastrService,
    private razorpayService: ExternalLibraryService,
    private cd: ChangeDetectorRef,
    private formBuilder: FormBuilder,
    private ngxUiLoaderService: NgxUiLoaderService,
    @Inject(DOCUMENT) private document: Document,

  ) {

  }
  name = 'Angular';
  response;
  razorpayResponse;
  showModal = false;
  ubContributeForm: FormGroup;
  isDonorSubmit: boolean = false;
  params: any;
  totalAmount: number = 0;
  storeData: any;
  donateHistory: any;
  ubevents: any;
  userData: any;
  ngOnInit() {
    this.userData = JSON.parse(localStorage.getItem("ubu_auth_user"));
    this.getMyDonations();
    this.getEvents();
    this.addContributionForm();

    this.razorpayService
      .lazyLoadLibrary('https://checkout.razorpay.com/v1/checkout.js')
      .subscribe();

    this.ubContributeForm.patchValue({ ubd_name: this.userData.first_name, ubd_mobile: this.userData.phone, ubd_email: this.userData.email });
  }
  addContributionForm() {
    this.ubContributeForm = this.formBuilder.group({
      ubd_name: ['', [Validators.required]],
      ubd_mobile: ['', [Validators.required, Validators.pattern(/^[6-9]\d{9}$/)]],
      ubd_email: ['', [
        Validators.required,
        Validators.email,
        Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
      ubd_amount: ['', [Validators.required, Validators.min(100), Validators.max(5000)
      ]],
      ubd_event: ['', [Validators.required]],
      ubd_notes: ['']
    },
      {
        // Used custom form validator amount
        validator: Amountvalidate("ubd_amount")
      }
    );
  }

  RAZORPAY_OPTIONS = {
    "key": "rzp_live_VrH0Iv0rqChCT4", //"rzp_live_0OhhivD3EhzTFM", //"rzp_test_4VNGs8GjqQvx7P",
    "amount": 1000 + "00",
    "name": "Ublood",
    "order_id": "",
    "description": "Noble TO Save Life",
    "image": "https://ublood.com/assets/images/logo/logo-h.png",
    "prefill": {
      "name": "Usaver",
      "email": "support@ublood.com",
      "contact": "",
      "method": ""
    },
    "modal": {},
    "theme": {
      "color": "#0096C5"
    }
  };


  get ubdFormControl() {

    return this.ubContributeForm.controls;

  }

  public contributeNow(postDonor: any) {

    //  console.log(postDonor);
    this.params = postDonor;
    this.isDonorSubmit = true;
    var _self = this;
    //$('#input_id').val() % 20 != 0
    /* if(postDonor.ubd_amount % 100 != 0){
       this.toastr.error('Please enter amount multiply by 100');   
       return false;
     }*/
    if (this.ubContributeForm.invalid) {
      // console.log("invalid data");
      return false;
    } else {
      this.proceed(this.params);
    }

    // console.log(this.razorpayResponse);
    //  this.ngxUiLoaderService.start();

  }
  public proceed(params) {
    //  console.log(params);
    this.RAZORPAY_OPTIONS.amount = params.ubd_amount + "00";
    this.RAZORPAY_OPTIONS.prefill.email = params.ubd_email;
    this.RAZORPAY_OPTIONS.prefill.name = params.ubd_name;
    this.RAZORPAY_OPTIONS.prefill.contact = params.ubd_mobile;
    // binding this object to both success and dismiss handler
    this.RAZORPAY_OPTIONS['handler'] = this.razorPaySuccessHandler.bind(this);
    // this.showPopup();
    // console.log(this.RAZORPAY_OPTIONS);
    let razorpay = new Razorpay(this.RAZORPAY_OPTIONS)
    razorpay.open();
  }

  public razorPaySuccessHandler(response) {
    // console.log(response);
    // alert(response.razorpay_payment_id);
    this.razorpayResponse = response;
    if (this.razorpayResponse.razorpay_payment_id != '') {

      this.storeData = {
        user_id: JSON.parse(localStorage.getItem('ubu_auth_user')).user_id,
        name: this.params.ubd_name,
        phone: this.params.ubd_mobile,
        email: this.params.ubd_email,
        event_id: this.params.ubd_event,
        amount: this.params.ubd_amount,
        status: 1,
        transaction_id: this.razorpayResponse.razorpay_payment_id,
        comment: this.params.ubd_notes
      }
      if (this.razorpayResponse.razorpay_payment_id != '') {

        this.dataService.saveDonation(this.storeData).subscribe(
          (response: any) => {
            this.ngxUiLoaderService.stop();
            if (response['status_code'] == 200) {
              this.toastr.success('', response['success']['message']);
              this.ubContributeForm.reset();
              // this.document.location.reload();
              // this.ngxUiLoaderService.stop();
              $('#thankyou_msg').modal('show');
            }
            if (response['status_code'] == 201) {
              this.ngxUiLoaderService.stop();
              this.toastr.info('', response['error']['message']);
            }
          }, error => {
            this.ngxUiLoaderService.stop();
            this.toastr.info('', "Some thing went wrong");
          });

      }


    }
    this.showModal = true;
    this.cd.detectChanges()
    //    document.getElementById('razorpay-response').style.display = 'block';
  }
  getEvents() {
    this.ngxUiLoaderService.start();
    this.dataService.getMetaDataEventList()
      .subscribe(
        dataList => {
          this.ubevents = dataList.data.events;
          console.log(this.ubevents);
          this.ngxUiLoaderService.stop();
        });

  }

  getMyDonations() {
    // this.totalAmount=0;
    this.ngxUiLoaderService.start();
    const myRequestDataFilter = { "action": "funding_history" };
    this.dataService.getMyDonations(myRequestDataFilter)
      .subscribe(
        dataList => {
          this.donateHistory = dataList.data;
          this.totalAmount = dataList.data.total;
          // console.log(this.totalAmount);
          this.ngxUiLoaderService.stop();
        });
  }


  numberOnly(event: any): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  keyPressAlphaNumeric(event) {
    var inp = String.fromCharCode(event.keyCode);
    if (/[a-zA-Z]/.test(inp)) {
      return true;
    } else {
      event.preventDefault();
      return false;
    }
  }

  history(e: Event) {
    $('.donation_form,.fa-chevron-right.arrows').hide();
    $('.dont_history,.fa-chevron-down.arrows').show();
  }
  donation(e: Event) {
    $('.donation_form,.fa-chevron-right.arrows').show();
    $('.dont_history,.fa-chevron-down.arrows').hide();
  }

  reloadPage() {
    this.ngxUiLoaderService.start();
    this.document.location.reload();
    this.ngxUiLoaderService.stop();
  }



}
