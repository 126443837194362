import { BrowserModule, Title } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { UiSwitchModule } from 'ngx-toggle-switch';
import { FormsModule } from '@angular/forms';
import { FlatpickrModule } from 'angularx-flatpickr';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SharedModule } from './shared/shared.module';
import { UserModule } from './user/user.module';
import { AgmCoreModule } from '@agm/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ToastrModule } from 'ngx-toastr';
import { NgxUiLoaderModule } from 'ngx-ui-loader';
import { AppSettings } from './app.settings';
import { AppApiEndpoints } from './app.api-endpoints';
import { ImageProcessor } from './helpers/image-processor';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';



@NgModule({
  declarations: [
    AppComponent,
    PageNotFoundComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    UserModule,
    SharedModule,
    NgxUiLoaderModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    UiSwitchModule,
    NgbModule,
    ToastrModule.forRoot({
      closeButton: true, timeOut: 5000,
      positionClass: 'toast-center-center',
      preventDuplicates: true,
    }),
    AgmCoreModule.forRoot({
      //apiKey: 'AIzaSyC7OWx24R-7m2k34jNRcMfTDxT-MSehrFc' //AIzaSyC7OWx24R-7m2k34jNRcMfTDxT-MSehrFc,
      apiKey: 'AIzaSyDfUl7G2CIfkJdCRwakYUQeen2o5cCzcVE',
      libraries: ['places', 'geometry'],

    }),
    FlatpickrModule.forRoot(),

  ],
  providers: [ToastrModule, AppSettings, AppApiEndpoints, ImageProcessor, Title],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }
