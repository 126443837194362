import { Component, OnInit, Input, ViewChild, OnChanges, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { BehaviorSubject, interval, of, timer } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import { Ubrequest } from '../../../models/request.model';
import { AuthenticationService } from '../../../services/authentication/authentication.service';


declare var $: any;

@Component({
  selector: 'app-otp-form',
  templateUrl: './otp-form.component.html',
  styleUrls: ['./otp-form.component.css']
})
export class OtpFormComponent implements OnChanges {
  @Input() phone_number: number;
  @Input() otp_timer_start: boolean;
  @Input() request_data: Ubrequest;
  @ViewChild('ngOtpInput', { static: true }) ngOtpInput: any;
  ubOTPForm: FormGroup;
  isOTPFormSubmitted: boolean = false;
  verified_user: number = 0;
  isOTPtimerOn: boolean = false;
  isResendBtnEnabled: boolean = false;
  otpTimeCountinSec: number = 50;
  maskPhoneNumber: any;

  otpConfig = {
    allowNumbersOnly: true,
    length: 4,
    isPasswordInput: false,
    disableAutoFocus: false,
    placeholder:'*',
    inputStyles: {
      'width': '50px',
      'height': '40px',
      'display':'inline',
      'margin-right':'10px'
    }
  };

  constructor(
    private formBuilder: FormBuilder,
    private authentication: AuthenticationService,
    private toastrService: ToastrService,
    private ngxUIService: NgxUiLoaderService,
    private router: Router
  ) {
    this.ubOTPForm = this.formBuilder.group({
      uba_otp_number: ['', [Validators.required, Validators.pattern(/\d{4}/)]],
    });

  }

  ngOnChanges(timeOptionStart: SimpleChanges) {
    if (this.otp_timer_start) {      
      if(this.request_data){
        this.maskPhoneNumber = this.request_data.request_phone;
        this.maskPhoneNumber = this.maskPhoneNumber.substring(0, 2) + 'xxxxx' + this.maskPhoneNumber.substring(this.maskPhoneNumber.length - 3);
        }

      this.isOTPtimerOn = true;
      this.otpTimerOn(this.otpTimeCountinSec);

    }

  }

  get ubOTPFormControl() { return this.ubOTPForm.controls; }

  /**
   * 
   * @param post 
   */
  verifyUser(post: any) {
    this.isOTPFormSubmitted = true;
    if (this.ubOTPForm.invalid) {
      this.toastrService.info('', "Please enter valid OTP",{
        tapToDismiss: false,
        positionClass:'toast-top-right',
      
      });
      return false;
    }
    if (post) {
      const authUserData = {
        "action": "verify",
        "phone": this.request_data.request_phone,
        "otp": post['uba_otp_number'],
      };
      this.ngxUIService.start();
      this.authentication.ubuAuthenticate(authUserData)
        .subscribe(
          response => {
            if (response['status_code'] == 200) {
              this.toastrService.success('', response['success']['message'],{
                tapToDismiss: false,
                positionClass:'toast-top-right',
              
              });
              this.request_data.request_user = response['data']['user_id'];
               if (this.request_data.request_type) {
                if (this.request_data.request_type == "signup_request") {
                  if (!response['is_user_signup']) {
                    this.ngxUIService.stop();
                    $('#otpModalForm').modal('hide');
                    $('#signUpModalForm').modal('show');
                  }
                  else {
                    localStorage.setItem("ubu_auth_token", response['data']['access_token']);
                    localStorage.setItem("ubu_auth_user", JSON.stringify(response['data']));                    
                    $('#otpModalForm').modal('hide');
                    this.ngxUIService.stop();
                    this.router.navigate(["/donor-map-view"]);
                  }
                }                
                else if (this.request_data.request_type == "blood_request") {
                  $('#otpModalForm').modal('hide');
                  $('#ubuBloodRequestFormModal').modal('show');
                }
                this.ngxUIService.stop();
              }
            }
            if (response['status_code'] == 201) {
              this.ngxUIService.stop();
              this.toastrService.info('', response['error']['message'],{
                tapToDismiss: false,
                positionClass:'toast-top-right',
              
              });

            }

          }, error => {
            this.ngxUIService.stop();
            this.toastrService.info('', "Some thing went wrong",{
              tapToDismiss: false,
              positionClass:'toast-top-right',
            
            });
          });
    } else {
      this.ngxUIService.stop();
      this.toastrService.info('', "Please enter mobile number",{
        tapToDismiss: false,
        positionClass:'toast-top-right',
      
      });
    }

  }

  /**
   * 
   */
  resendOTP() {
    this.otp_timer_start = true;
    this.isOTPtimerOn = true;
    const authUserData = {
      "action": "sendotp",
      "std_code": "+91", 
      "phone": this.request_data.request_phone
    };
    this.ngxUIService.start();
    this.authentication.ubuAuthenticate(authUserData)
      .subscribe(
        response => {
          if (response['status_code'] == 200) {
            this.ngxUIService.stop();
            this.otpTimerOn(this.otpTimeCountinSec);
            this.toastrService.success('', response['success']['message'],{
              tapToDismiss: false,
              positionClass:'toast-top-right',
            
            });
          }
        }, error => {
          this.ngxUIService.stop();
          this.toastrService.info('', "Some thing went wrong",{
            tapToDismiss: false,
            positionClass:'toast-top-right',
          
          });
        });
  }

  /**
   * 
   * @param timeLeft 
   */
  otpTimerOn(timeLeft: number) {
    let minute: any = Math.floor(timeLeft / 60);
    let second: any = timeLeft % 60;
    let _self = this;
    minute = minute < 10 ? '0' + minute : minute;
    second = second < 10 ? '0' + second : second;
    $('#otp_timer').html(minute + ':' + second);
    timeLeft -= 1;

    if (timeLeft >= 0 && this.isOTPtimerOn) {
      this.isResendBtnEnabled = false;
      setTimeout(function () {
        _self.otpTimerOn(timeLeft);
      }, 1000);
      return;
    } else {
      this.isResendBtnEnabled = true;
      $('#otp_timer').html('');
    }

    if (!this.isOTPtimerOn) {
      // Do validate stuff here
      return;
    }


  }
  /**
   * 
   * @param event 
   */
  isValidOtp(event: any) {
    this.ubOTPForm.controls['uba_otp_number'].setValue(event);
   /* const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;*/

  }

  backToAuthenticateForm() {
    this.ngxUIService.start();
    $('#otpModalForm').modal('hide');
    $('#authenticateModalForm').modal('show');
    this.ngxUIService.stop();
  }

}
