import { Injectable } from '@angular/core';
import { HttpClientModule, HttpParams,HttpClient,HttpHeaders} from '@angular/common/http';
import { Observable } from 'rxjs';
import { Router, NavigationEnd } from '@angular/router';
import { AppSettings } from '../../../../app.settings';
import { AppApiEndpoints } from '../../../../app.api-endpoints';

@Injectable({
  providedIn: 'root'
})
export class InviteFriendsService {
  reqHeader:any;
  constructor(private httpClient: HttpClient, private _appSettings: AppSettings, 
    private appapiEndpoint: AppApiEndpoints,private router: Router) { 
      this.reqHeader = new HttpHeaders({ 
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('ubu_auth_token')
      });
    }

    inviteFriend(data:any):Observable<any>{
      let apiUserDataList = this._appSettings.API_NAMESPACE + this.appapiEndpoint.inviteSend;    
      return this.httpClient.post(apiUserDataList,JSON.stringify(data), { headers: this.reqHeader }); 
    }

    getUserData(userID: any) : Observable<any>{
      const dataOption = {
        user_id: userID
      };
      let apiUrlGetuserProfileDetails = this._appSettings.API_NAMESPACE + this.appapiEndpoint.getUserDetails;
      return this.httpClient.post(apiUrlGetuserProfileDetails, JSON.stringify(dataOption), { headers: this.reqHeader });
    }
    
}
