import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from '../guards/auth/auth.guard';
import { RoleGuard } from '../guards/role/role.guard';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { UserLayoutComponent } from './components/user-layout/user-layout.component';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { BloodDonationComponent } from './components/blood-donation/blood-donation.component';
import { ServiceRequestListComponent } from './components/service-request-list/service-request-list.component';
import { ReferADonorComponent } from './components/forms/refer-a-donor/refer-a-donor.component';
import { ServiceMapViewComponent } from './components/service-map-view/service-map-view.component';
import { MyServiceRequestListComponent } from './components/my-service-request-list/my-service-request-list.component';
import { ReferAFriendComponent } from './components/refer-a-friend/refer-a-friend.component';
import { ServiceRequestViewComponent } from './components/service-request-view/service-request-view.component';
import { ManageProfileComponent } from './components/manage-profile/manage-profile.component';
import { AboutUsComponent } from '../shared/pages/misc/about-us/about-us.component';
import { ContactUsComponent } from '../shared/pages/misc/contact-us/contact-us.component';
import { PrivacyPolicyComponent } from '../shared/pages/misc/privacy-policy/privacy-policy.component';
import { TermsOfServiceComponent } from '../shared/pages/misc/terms-of-service/terms-of-service.component';
import { MapViewLayoutComponent } from './components/map-view-layout/map-view-layout.component';
import { ServiceRequestMapViewComponent } from './components/service-request-map-view/service-request-map-view.component';
import { NotificationComponent } from './components/notification/notification.component';
import { HospitalListComponent } from './pages/services/hospital-list/hospital-list.component';
import { BloodbankListComponent } from './pages/services/bloodbank-list/bloodbank-list.component';
import { AmbulanceComponent } from './pages/services/ambulance/ambulance.component';
//import { VideogalleryComponent } from '../pages/videogallery/videogallery.component'; 
import { VideogalleryComponent } from '../shared/pages/videogallery/videogallery.component';
import { LayoutComponent } from '../shared/components/layout/layout.component';
import { CowinLoginComponent } from './cowin/cowin-login/cowin-login.component';
import { RegisterVaccinationComponent } from './cowin/register-vaccination/register-vaccination.component';
import { AccountDetailsComponent } from './cowin/account-details/account-details.component';
import { BookAppointmentComponent } from './cowin/book-appointment/book-appointment.component';
import { AppointmentConfirmationComponent } from './cowin/appointment-confirmation/appointment-confirmation.component';
import { AppointmentSuccessfulComponent } from './cowin/appointment-successful/appointment-successful.component';
import { DontationsComponent } from './components/dontations/dontations.component';
const userRoutes: Routes = [
  {

    path: '', component: UserLayoutComponent, canActivate: [AuthGuard],

    children: [
      /* { path: 'contribute', canActivate: [AuthGuard], component: DontationsComponent },
       { path: 'dashboard', canActivate: [AuthGuard], component: DashboardComponent },
       { path: 'manage-profile', canActivate: [AuthGuard], component: ManageProfileComponent },
       { path: 'request-list', canActivate: [AuthGuard], component: ServiceRequestListComponent },
       { path: 'my-request-list', canActivate: [AuthGuard], component: MyServiceRequestListComponent },
       { path: 'request-details/:requestId', canActivate: [AuthGuard], component: ServiceRequestViewComponent },
       { path: 'refer-a-friend', canActivate: [AuthGuard], component: ReferAFriendComponent },
       { path: 'about-us', component: AboutUsComponent },
       { path: 'contact-us', component: ContactUsComponent },
       { path: 'terms-of-service', component: TermsOfServiceComponent },
       { path: 'privacy-policy', component: PrivacyPolicyComponent },
       { path: 'notifications', canActivate: [AuthGuard], component: NotificationComponent },
       { path: 'terms-', canActivate: [AuthGuard], component: NotificationComponent },
       { path: 'hospital-list', canActivate: [AuthGuard], component: HospitalListComponent },
       { path: 'bloodbank-list', canActivate: [AuthGuard], component: BloodbankListComponent },
       { path: 'ambulance-list', canActivate: [AuthGuard], component: AmbulanceComponent },*/


      // {path: 'page-not-found',   component: PageNotFoundComponent},
      // {path: '**', pathMatch :'full', redirectTo: '/page-not-found'} 
    ]

  }, {
    path: '', component: MapViewLayoutComponent, canActivate: [AuthGuard],
    children: [
      /* { path: 'donor-map-view', canActivate: [AuthGuard], component: ServiceMapViewComponent },
       { path: 'request-map-view/:requestId', canActivate: [AuthGuard], component: ServiceRequestMapViewComponent },*/
    ]
  },
  {
    path: '', component: LayoutComponent,
    children: [
      { path: 'video-gallery', component: VideogalleryComponent },
    ]
  },

  {
    path: 'cowin', component: UserLayoutComponent, canActivate: [AuthGuard],
    children: [
      { path: 'login', component: CowinLoginComponent },
      { path: 'register', component: RegisterVaccinationComponent },
      { path: 'dashboard', component: AccountDetailsComponent },
      { path: 'appointment', component: BookAppointmentComponent },
      { path: 'appointment-confirmation', component: AppointmentConfirmationComponent },
      { path: 'appointment-success', component: AppointmentSuccessfulComponent },

    ]
  }

];

@NgModule({
  imports: [RouterModule.forChild(userRoutes)],
  exports: [RouterModule]
})
export class UserRoutingModule { }
