import { Injectable } from '@angular/core';
import { HttpClientModule, HttpParams,HttpClient,HttpHeaders} from '@angular/common/http';
import { Observable } from 'rxjs';
import { AppSettings } from '../../../app.settings';
import { AppApiEndpoints } from '../../../app.api-endpoints';
import { mapData } from '../../models/map-data.model';
import { Router, NavigationEnd } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import {formatDate } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class CowinServiceService {
  reqHeader:any;
   public coWinURL = "https://cdn-api.co-vin.in/api/v2/admin/location/states";
  constructor(private httpClient: HttpClient, private _appSettings: AppSettings, private appapiEndpoint: AppApiEndpoints,private router: Router) { 
    
    this.reqHeader = new HttpHeaders({ 
      'Content-Type': 'application/json',
    });
   
  }
  getStateList(): Observable<any>{
    let apiUrlGetStateList = this._appSettings.API_NAMESPACE + this.appapiEndpoint.getMetaDataList;  

    return this.httpClient.get(this.coWinURL,{ headers: this.reqHeader });
    }
getDistricts(stateId:any): Observable<any>{
  let apiurl= "https://cdn-api.co-vin.in/api/v2/admin/location/districts/";
  return this.httpClient.get(apiurl+stateId,{ headers: this.reqHeader });
}

getCentersByDistrict(distId:any) : Observable<any>{
 // let todayDate= "";
  let currentDate=new Date();
  let curentDate = formatDate(currentDate, 'dd-MM-yyyy', 'en-US', '+0530');
  let apiUrl= "https://cdn-api.co-vin.in/api/v2/appointment/sessions/public/calendarByDistrict?district_id="+distId+"&date="+curentDate; //512&date=18-06-2021";
  return this.httpClient.get(apiUrl,{ headers: this.reqHeader });
}
getCentersByPin(pinNum:any) : Observable<any>{
  // let todayDate= "";
   let currentDate=new Date();
   let curentDate = formatDate(currentDate, 'dd-MM-yyyy', 'en-US', '+0530');
   let apiUrl= "https://cdn-api.co-vin.in/api/v2/appointment/sessions/public/calendarByPin?pincode="+pinNum+"&date="+curentDate;
   //let apiUrl= "https://cdn-api.co-vin.in/api/v2/appointment/sessions/public/calendarByDistrict?district_id="+distId+"&date="+curentDate; //512&date=18-06-2021";
   return this.httpClient.get(apiUrl,{ headers: this.reqHeader });
 }

}
