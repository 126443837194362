import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { Component, OnInit, ViewChild, ElementRef, NgZone,EventEmitter,Output,Input } from '@angular/core';
import { DatePipe } from '@angular/common'
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { DataService } from '../../../../../services/user-data/data.service';
import { ManageProfileService } from '../../../../../services/ub-user/manage-profile/manage-profile.service';
import { Observable, Subject } from 'rxjs';

declare var $: any;


@Component({
  selector: 'app-basic-info',
  templateUrl: './basic-info.component.html',
  styleUrls: ['./basic-info.component.css']
})
export class BasicInfoComponent implements OnInit {
  profileBasicInfoForm: FormGroup;
  isBasicInfoSubmitted:boolean =false;
   public bloodGroup:string;
   public UserData:any;
   public selectBloodGroup:any
  @Input() userProfileData:any;
  @Output() basicInfoUpdated: EventEmitter<boolean> = new EventEmitter();
  
  bloodGroupListData = [
    { id: 1, name: 'A+' },
    { id: 2, name: 'A-' },
    { id: 3, name: 'B+' },
    { id: 4, name: 'B-' },
    { id: 5, name: 'AB+' },
    { id: 6, name: 'AB-' },
    { id: 7, name: 'O+' },
    { id: 8, name: 'O-' },
    { id: 9, name: 'A1+' },
    { id: 10, name: 'A1-' },
    { id: 11, name: 'A2+' },
    { id: 12, name: 'A2-' },
    { id: 13, name: 'A1B+' },
    { id: 14, name: 'A1B-' },
    { id: 15, name: 'A2B+' },
    { id: 16, name: 'A2B-' },
    { id: 17, name: 'Bombay Blood Group' },
    { id: 18, name: 'INRA' },
    { id: 19, name: "Don't Know" },
  ];

   constructor(private formBuilder: FormBuilder, private dataService: DataService, private toastrService: ToastrService, private ngxUiService: NgxUiLoaderService,
     private datePipe: DatePipe, private manageProfileService: ManageProfileService) {

  }

  ngOnInit() {
    this.UserData = JSON.parse(localStorage.getItem('ubu_auth_user'));
    this.bloodGroup = this.UserData.blood_group.blood_group;
    this.profileBasicInfoForm = this.formBuilder.group({
      ubu_first_name: ['',[Validators.required, Validators.pattern(/^[a-zA-Z\s]+$/)]],
      ubu_last_name: ['',[Validators.required, Validators.pattern(/^[a-zA-Z\s]+$/)]],
      ubu_email: ['', [
        Validators.required,
        Validators.email,
        Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],   
      ubu_blood_group: ['', Validators.required],    
    });
    this.profileBasicInfoForm.get('ubu_first_name').setValue(this.userProfileData.first_name);
    this.profileBasicInfoForm.get('ubu_last_name').setValue(this.userProfileData.last_name);
    this.profileBasicInfoForm.get('ubu_email').setValue(this.userProfileData.email);
    this.profileBasicInfoForm.get('ubu_blood_group').setValue(this.userProfileData.blood_group.id);    

     console.log(this.bloodGroup);
  }

  get ubuBasicProfileInfo() { return this.profileBasicInfoForm.controls; }


  /**
   get selected blood group
   */
   handleChange(event:any) {
    this.bloodGroupListData.find(item => item.id == event);
    this.selectBloodGroup= this.bloodGroupListData.find(c => c.id == event) ;
   } 
   /***
   * Update Profile Basic Info
   */
  updateBasicProfileInfo(basicProfilePost: any) {  
     this.isBasicInfoSubmitted =true;  
     if (this.profileBasicInfoForm.invalid) {
      this.toastrService.info('', 'Invalid request');
      return false;
    }
    if(this.userProfileData.is_blood_request_accepted){
      this.toastrService.info('', 'Unable to change basic details now, Because you have already accepted blood request');
      return false;
    }
    
    this.ngxUiService.start();
    if (basicProfilePost) {
      let userBasicData = {
        "action": "basic_info",
        "first_name": basicProfilePost['ubu_first_name'],
        "last_name": basicProfilePost['ubu_last_name'],
        "email": basicProfilePost['ubu_email'],
        "category_id": 3,
        "blood_group": basicProfilePost["ubu_blood_group"],        
      }
      
     
      this.manageProfileService.updateProfileData(userBasicData)
        .subscribe(
          response => {
            if (response['status_code'] == 200) {
              this.toastrService.success('', response['success'].message);
              $('#profileBasicInfoModal .close').trigger('click');
              this.basicInfoUpdated.emit(response['status_code']);
             
              this.ngxUiService.stop();
            } else {
              this.toastrService.info('', response['error'].message);
            }
            this.ngxUiService.stop();
          }, error => {
            this.toastrService.info('', "Some thing went wrong");
            this.ngxUiService.stop();
          });
    }
  }

  keyPressAlphaNumeric(event) {
    var inp = String.fromCharCode(event.keyCode);

    if (/[a-zA-Z]/.test(inp)) {
      return true;
    } else {
      event.preventDefault();
      return false;
    }
  }


}
