import { Component, OnInit, ViewChild, ElementRef, NgZone, Input, Output, EventEmitter } from '@angular/core';
import {Ubrequest} from '../../../models/request.model';
@Component({
  selector: 'app-ubu-request',
  templateUrl: './ubu-request.component.html',
  styleUrls: ['./ubu-request.component.css']
})
export class UbuRequestComponent implements OnInit {
 @Input() request_data : Ubrequest;

  constructor() { }

  ngOnInit() {
  }

}
