import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import {Ubrequest} from '../../models/request.model';
declare var $: any;

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
  request_data: Ubrequest;
  userData: any;
  userId: any;
  @Output() requestTypeEvent = new EventEmitter<any>();
  constructor() { 
    this.request_data = new Ubrequest();
  }

  ngOnInit() {
    $('.footer_link_show .up_down_link').click(function () {
      $('.footer_set').slideToggle();
      $('.footer_parent').toggleClass('active_footer');
      $('.up_down_link i').toggleClass('fa-angle-up fa-angle-down')
    })
    this.userData = JSON.parse(localStorage.getItem("ubu_auth_new_user"));
    this.userId = JSON.parse(localStorage.getItem("ubu_auth_user_id"));
  }
  /**
   * 
   */
  ubuRequest() {
    this.request_data.request_type = "blood_request";
    this.requestTypeEvent.emit(this.request_data);
    $('#authenticateModalForm').modal('show');
  }

}
