import { Component, OnInit, Input, ViewChild, ElementRef, NgZone, Renderer2 } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { MapsAPILoader, GoogleMapsAPIWrapper, AgmMap, LatLngBounds, LatLngBoundsLiteral } from '@agm/core';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { FlatpickrOptions } from 'ng2-flatpickr';
import { DatePipe } from '@angular/common'
import { BehaviorSubject } from 'rxjs';
import { Observable, Subject } from 'rxjs';
import { Ubrequest } from '../../../../models/request.model';
import { DataService } from '../../../../services/dataService/data.service';
import { AuthenticationService } from '../../../../services/authentication/authentication.service';
import { ReferrerCodeService } from '../../../../services/referrer-code.service';
import { DOCUMENT } from '@angular/common';
import { Inject, AfterViewInit } from '@angular/core';
declare var $: any;


@Component({
  selector: 'app-request-blood',
  templateUrl: './request-blood.component.html',
  styleUrls: ['./request-blood.component.css']
})
export class RequestBloodComponent implements OnInit {
  @Input() requested_user: number;
  @Input() request_phone_number: number
  @Input() request_data: any
  @ViewChild('ubr_location', { static: true }) public searchElementRef: ElementRef;
  ubrBloodRequestForm: FormGroup;
  verifyOtpForm: FormGroup;

  isBloodRequestSubmit: boolean = false;
  hospital_location: string;
  private searchTerm: string;
  address: string;
  private geoCoder;
  map: google.maps.Map;
  fitBounds: boolean = false;
  latitude: number;
  longitude: number;
  unitsIteratorArray = Array;
  bloodUnits: number = 10;
  requestData: any;
  public imgPath;
  imgURL: any;
  otp_timer_start: boolean = false;
  isAuthFormSubmitted: boolean = false;
  ubr_phonenumber: number;
  dateTimeFormatConfigAge: any = {}
  dateTimeFormatConfig: any = {}
  isvalidRefCode: any;
  isdoc: boolean = false;
  subject = new Subject<string>();
  bloodGroupDataList = [
    { id: 1, name: 'A+' },
    { id: 2, name: 'A-' },
    { id: 3, name: 'B+' },
    { id: 4, name: 'B-' },
    { id: 5, name: 'AB+' },
    { id: 6, name: 'AB-' },
    { id: 7, name: 'O+' },
    { id: 8, name: 'O-' },
    { id: 9, name: 'A1+' },
    { id: 10, name: 'A1-' },
    { id: 11, name: 'A2+' },
    { id: 12, name: 'A2-' },
    { id: 13, name: 'A1B+' },
    { id: 14, name: 'A1B-' },
    { id: 15, name: 'A2B+' },
    { id: 16, name: 'A2B-' },
    { id: 17, name: 'Bombay Blood Group' },
    { id: 18, name: 'INRA' },
    { id: 19, name: "Don't Know" }
  ];


  constructor(
    private formBuilder: FormBuilder,
    private dataService: DataService,
    private toastrService: ToastrService,
    private ngxUiLoaderService: NgxUiLoaderService,
    private router: Router,
    private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone,
    private datePipe: DatePipe,
    private authentication: AuthenticationService,
    private referrerCode: ReferrerCodeService,
    private _renderer2: Renderer2,
    @Inject(DOCUMENT) private _document

  ) {
    this.ubrBloodRequestForm = this.formBuilder.group({
      ubr_name: ['', Validators.required],
      ubr_lname: ['', Validators.required],
      ubr_attendee_name: ['', Validators.required],
      ubr_attendee_lname: ['', Validators.required],
      ubr_phone: ['', [Validators.required, Validators.pattern(/^[6-9]\d{9}$/)]],
      ubr_blood_group: ['', Validators.required],
      location_for_donation: [''],
      ubr_blood_units: ['', [Validators.required, Validators.pattern(/^[1-9]\d{0}$/)]],
      ubr_requsitionfile: [''],
      ubr_blood_type: ['', Validators.required],
      ubr_address: ['', Validators.required],
      ubr_dob_date: [''],
      ubr_note: [''],
      ubr_request_datetime: ['', Validators.required],
      ubr_is_emergency: [''],
      ubr_terms_and_conditions: [''],
      ubr_refcode: ['']
    });

    this.ubrBloodRequestForm.controls['ubr_blood_group'].setValue('');

    this.verifyOtpForm = this.formBuilder.group({
      ubr_otp: ['', Validators.required],
    });

  }


  ngOnInit() {

    this.setDefaultValues();
    this.dataService.changeMessage("bloodrequester");
    localStorage.setItem("ubu_auth_token", '');
    var makeDate = new Date();
    makeDate.setFullYear(makeDate.getFullYear() - 18);
    var newDate = makeDate;
    //console.log(makeDate);


    this.dateTimeFormatConfigAge = {
      mode: 'single',
      enableTime: false,
      //  dateFormat: "Y-m-d H:i",
      dateFormat: "d-m-Y",
      maxDate: new Date()
    };

    this.dateTimeFormatConfig = {
      mode: 'single',
      enableTime: false,
      dateFormat: "d-m-Y",
      minDate: new Date()
    }
    this.mapsAPILoader.load().then(() => {
      this.geoCoder = new google.maps.Geocoder;
      let autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement);
      autocomplete.addListener("place_changed", () => {
        this.ngZone.run(() => {
          //get the place result
          let place: google.maps.places.PlaceResult = autocomplete.getPlace();
          //verify result
          if (place.geometry === undefined || place.geometry === null) {
            return;
          }
          //set latitude, longitude and zoom
          this.hospital_location = place.name;
          this.latitude = place.geometry.location.lat();
          this.longitude = place.geometry.location.lng();
        });
      });
    });
  }


  bloodUnitsCount(unit: number) {
    return new Array(unit);
  }


  get ubrFormControl() { return this.ubrBloodRequestForm.controls; }

  setDefaultValues() {
    this.ubrBloodRequestForm.patchValue({ ubr_requested_for_other: "2" });
  }

  docForm(event: any) {
    this.isdoc = false;
    let files = event.target.files;
    if (files.length === 0) {
      return;
    }
    var sFileName = files[0].name;
    var sFileExtension = sFileName.split('.')[sFileName.split('.').length - 1].toLowerCase();
    var iFileSize = files[0].size;
    var iConvert = (iFileSize / 1048576).toFixed(2);
    if (!(sFileExtension === "png" ||
      sFileExtension === "jpeg" ||
      sFileExtension === "jpg") || iFileSize > 1048576) { /// 1 mb
      var txt = "File type : " + sFileExtension + "\n\n";
      txt += "Size: " + iConvert + " MB \n\n";
      txt += "Please make sure your file is in image format and less than 1 MB.\n\n";
      this.toastrService.warning('', txt);
      this.isdoc = true;
    } else {
      var reader = new FileReader();
      this.imgPath = files;
      reader.readAsDataURL(files[0]);
      reader.onload = (_event) => {
        this.imgURL = reader.result;
      }
      this.isdoc = false;

    }

  }

  checkRefercode(code: any) {
    var str = code.target.value;
    if (str.length == 6 || str.length == 8) {
      this.referrerCode.validRefCode(str).subscribe(data => {
        //  console.log(data);
        this.isvalidRefCode = data;
      });
    } else if (str.length < 6 || str.length == 7 || str.length > 8) {
      $('.valid_m1').removeClass('valid_val').addClass('not_valid_val');
    }
    if (str.length == 0) {
      //  console.log("innnnnn");
      $('.valid_m1').removeClass('not_valid_val valid_val');
    }
  }

  get ubAuthFormControl() { return this.verifyOtpForm.controls; }



  requestService(postRequestData: any) {
    this.isBloodRequestSubmit = true;
    let _self = this;
    if (this.ubrBloodRequestForm.invalid) {
      for (let el in this.ubrBloodRequestForm.controls) {
        if (this.ubrBloodRequestForm.controls[el].errors) {
          console.log(el)
        }
      }
      return false;
    } else {
      if (postRequestData["ubr_refcode"]) {
        if (this.isvalidRefCode != 1) {
          this.toastrService.error("Invalid referral code. Please enter valid code");
          $('.valid_m1').removeClass('valid_val').addClass('not_valid_val');
          return false;
        }
      }

      if ($("#termsAndCondition").prop("checked") !== true) {
        this.toastrService.info("Please accept the terms and Conditions");
        return false;
      }
      if (this.isdoc == true) {
        console.log(this.isdoc);
        this.toastrService.error("Please upload valid  doctor requisition document");
        return false;
      }
      if (this.imgURL == undefined) {
        this.imgURL = '';
      }
      //  this.runRefcodescript(postRequestData["ubr_name"],postRequestData["ubr_phone"],postRequestData["ubr_attendee_name"]);
      this.requestData = {
        "action": "create_service_request",
        "patient_name": postRequestData["ubr_name"],
        "patient_last_name": postRequestData["ubr_lname"],
        "contact_number": postRequestData["ubr_phone"],
        "contact_name": postRequestData["ubr_attendee_name"],
        "contact_last_name": postRequestData["ubr_attendee_lname"],
        "blood_group": postRequestData["ubr_blood_group"],
        "units": postRequestData["ubr_blood_units"],
        "location_for_donation": postRequestData["location_for_donation"],
        "doctor_requisition_form": this.imgURL,
        "patient_age": this.datePipe.transform(postRequestData["ubr_dob_date"][0], "yyyy-MM-dd"),
        "address": this.hospital_location,
        "latitude": this.latitude,
        "longitude": this.longitude,
        "is_emergency": (postRequestData["ubr_is_emergency"]) ? true : false,
        "is_share_on_sm": false,
        "service_type": postRequestData["ubr_blood_type"],
        "comment": postRequestData["ubr_note"],
        "required_date": this.datePipe.transform(postRequestData["ubr_request_datetime"][0], "yyyy-MM-dd"),
        "referral_code": postRequestData["ubr_refcode"],
        "device_type": 1
      }
      this.ubr_phonenumber = postRequestData['ubr_phone'];
      this.dataService.changeMobile(this.ubr_phonenumber);
      this.dataService.changeName(postRequestData["ubr_attendee_name"]);
      this.dataService.changeEmail('');
      // console.log(this.requestData); return false;
      this.SendOtp(this.ubr_phonenumber);

    }
  }

  runRefcodescript(firstname, mobile, attendeename) {
    let script = this._renderer2.createElement('script');
    script.type = 'text/javascript';
    script.text = "var ir = ir || function(){(window.ir.q = window.ir.q || []).push(arguments)}; \n"
      + "var invite_referrals = window.invite_referrals || {}; (function() { "
      + "invite_referrals.auth = { bid_e :'A3D09815C3D7032D2A3E2E74DB5CBBB9',"
      + "bid : '32063',"
      + "t : '420', "
      + "email : '', userParams : {'firstname': ''}}; "
      + "invite_referrals.async = false;"
    script.src = "//cdn.invitereferrals.com/js/invite-referrals-1.0.js";
    this._renderer2.appendChild(this._document.body, script);

    let newscript = this._renderer2.createElement('script');
    newscript.text = "$(function() {"

      + " var ir_fname ='" + firstname + "';\n "
      + " var ir_email ='';\n"
      + " var ir_mobile  ='" + mobile + "';\n "
      + "var ir_orderid = ir_mobile;"
      + "var ir_event = 'register'; "
      + " var ir_customval  ='" + attendeename + "';\n "
      + " var ir_amount = '';"
      + " var ir_coupon_code = ''; " // Personalized
      + " if( ir_email != '' && ir_fname != '' ) {"
      + "ir('track',{ orderID : ir_orderid , event: ir_event, fname: ir_fname , mobile: ir_mobile , email: ir_email , order_custom_val: ir_customval , purchaseValue: ir_amount, couponCode: ir_coupon_code });"
      + " }  "
      + " });"
    this._renderer2.appendChild(this._document.body, newscript);
  }

  SendOtp(phone: any) {
    if (phone) {
      const authUserData = {
        "action": "sendotp",
        "std_code": "+91",
        "phone": phone,
      };
      this.ngxUiLoaderService.start();
      this.authentication.ubuAuthenticate(authUserData)
        .subscribe(
          response => {
            if (response['status_code'] == 200) {
              this.ngxUiLoaderService.stop();
              this.toastrService.success('', response['success']['message']);
              $('#otpModalUI').modal('show');
              this.otp_timer_start = true;
            } else {
              this.toastrService.info('', response['error'].message);
              this.ngxUiLoaderService.stop();
            }
          }, error => {
            this.ngxUiLoaderService.stop();
            this.toastrService.info('', "Some thing went wrong");
          });
    } else {
      this.toastrService.info('', "Please enter mobile number");
    }

  }

  numberOnly(event: any): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }
  keyPressAlphaNumeric(event) {
    var inp = String.fromCharCode(event.keyCode);

    if (/[a-zA-Z]/.test(inp)) {
      return true;
    } else {
      event.preventDefault();
      return false;
    }
  }

}
