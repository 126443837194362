import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-copyright-footer',
  templateUrl: './copyright-footer.component.html',
  styleUrls: ['./copyright-footer.component.css']
})
export class CopyrightFooterComponent implements OnInit {

  currentYear: number;
  constructor() {
    this.currentYear = new Date().getFullYear();
  }

  ngOnInit() {
  }

}
