import { Injectable } from '@angular/core';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { BehaviorSubject, Subject} from 'rxjs';
import { AuthenticationService } from '.././services/authentication/authentication.service';
import { ToastrService } from 'ngx-toastr';
declare var $: any
@Injectable({
  providedIn: 'root'
})

export class ReferrerCodeService {
  
  constructor(
    private ngxUIService: NgxUiLoaderService,
    private authentication: AuthenticationService,
    private toastrService: ToastrService,
  ) {
    var subject = new Subject<string>();
   }

  public validRefCode(code:any){
    var str= code;
    var subject = new Subject<string>();
    let isvalidRefCode:any;
    if(str.length==6 || str.length==8 ){
      let dataOption = {"action":"validate_code","referral_code":str}
      this.ngxUIService.start();
      this.authentication.ubuAuthenticate(dataOption).subscribe(
        response => {
          if (response['status_code'] == 200) {
            isvalidRefCode = 1;
            subject.next(isvalidRefCode);
            this.ngxUIService.stop();
            $('.valid_m1').removeClass('not_valid_val').addClass('valid_val');
          }else if(response['status_code'] == 201){
            this.ngxUIService.stop();
            $('.valid_m1').removeClass('valid_val').addClass('not_valid_val');
            isvalidRefCode = 2;
            subject.next(isvalidRefCode);
          }
        }, error => {
          this.ngxUIService.stop();
          this.toastrService.info('', "Oops, Something went wrong, Please try again");
        });  
      return subject.asObservable();
    }   
  }
}
