import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { Component, OnInit, ViewChild, ElementRef, NgZone } from '@angular/core';
import { DatePipe } from '@angular/common'
import { MapsAPILoader, MouseEvent } from '@agm/core';
import { ToastrService } from 'ngx-toastr';
import { FlatpickrOptions } from 'ng2-flatpickr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { DataService } from '../../services/user-data/data.service';
import { ManageProfileService } from '../../services/ub-user/manage-profile/manage-profile.service';
import { NgbTypeahead } from '@ng-bootstrap/ng-bootstrap';
import { Observable, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map, merge } from 'rxjs/operators';
import { Router, ActivatedRoute } from '@angular/router';
declare var $: any;

@Component({
  selector: 'app-manage-profile',
  templateUrl: './manage-profile.component.html',
  styleUrls: ['./manage-profile.component.css']
})
export class ManageProfileComponent implements OnInit {
  userProfileData: any;

  userProfileDetails: any;
  userMoreDetails: any;
  profilePicUrl = new Array<string>();
  phoneVerifyForm: FormGroup;
  profilepicUploadForm: FormGroup;
  cvDoseFormVal: boolean = false;
  last_donated_date: any;
  cv_mindate: any;
  covidInfoForm: FormGroup;
  iscovidInfoSubmitted: boolean = false;
  haveVaccinated: any;
  ldonateDate: any;
  @ViewChild('instance', { static: true }) instance: NgbTypeahead;
  @ViewChild('searchLocation', { static: true }) public searchElementRef: ElementRef;

  donatedDate: FlatpickrOptions = {
    mode: 'single',
    enableTime: false,
    dateFormat: "Y-m-d",
    maxDate: "today",
    onChange: (selectedDates: any) => {
      //  console.log(selectedDates[0]);
      this.last_donated_date = selectedDates[0];
      this.updateDonate();
    }
  };

  dateTimeFormatConfigDose: FlatpickrOptions = {
    mode: 'single',
    enableTime: false,
    dateFormat: "d-m-Y",
    maxDate: "today",
    onChange: (selectedDates: any) => {
      this.cv_mindate = selectedDates[0];
      this.updateCovidDate();
    }
  };

  dateTimeFormatConfigDose1: FlatpickrOptions = {
    mode: 'single',
    enableTime: false,
    dateFormat: "d-m-Y"

  };
  constructor(private formBuilder: FormBuilder, private dataService: DataService, private toastrService: ToastrService, private ngxUiService: NgxUiLoaderService, private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone, private datePipe: DatePipe, private manageProfileService: ManageProfileService,
    private route: Router) {


  }

  ngOnInit() {

    this.getUserDetails();
    // this.datePipe.transform(this.userProfileData.last_blood_donated_date, 'dd-MM-yyyy');
    this.covidInfoForm = this.formBuilder.group({
      isCVCheck: [this.haveVaccinated],
      cv_dose_one: [''],
      cv_dose_two: ['']
    });
    // console.log(this.userProfileData);
    //  this.haveVaccinated=this.userProfileData.have_covid_vaccine;
    //console.log(this.haveVaccinated);  
  }

  setCVStatus(CVStatus: any) {
    if (CVStatus == 1) {
      this.cvDoseFormVal = true;
      this.haveVaccinated = "1";
      this.covidInfoForm.patchValue({
        isCVCheck: this.haveVaccinated
      });
      console.log("apply validation");
      this.covidInfoForm.get('cv_dose_one').setValidators(Validators.required);

    } else if (CVStatus == 0) {
      this.cvDoseFormVal = false;
      this.haveVaccinated = "0";
      this.covidInfoForm.patchValue({
        isCVCheck: this.haveVaccinated,
      });
      console.log("clear validation");
      //this.covidInfoForm.get('cv_dose_one').clearValidators();
      this.covidInfoForm.clearValidators();
    }
  }
  updateDonate() {
    let lddate: any;
    lddate = this.datePipe.transform(this.last_donated_date, 'yyyy-MM-dd');
    // console.log(lddate);
    this.ngxUiService.start();
    let userBasicData = {
      "action": "donated_date",
      "user_id": JSON.parse(localStorage.getItem('ubu_auth_user')).user_id,
      "donated_date": lddate
    }
    this.manageProfileService.updateProfileData(userBasicData)
      .subscribe(
        response => {
          if (response['status_code'] == 200) {
            this.toastrService.success('', response['success'].message);
            this.donatedDate.defaultDate = this.datePipe.transform(lddate, 'dd-MM-yyyy');
            this.getUserDetails();
          } else {
            this.toastrService.warning('', response['error'].message);
          }
          this.ngxUiService.stop();
        }, error => {
          this.toastrService.info('', "Some thing went wrong");
          this.ngxUiService.stop();
        });
  }
  updateCovidDate() {
    this.dateTimeFormatConfigDose1.minDate = this.datePipe.transform(this.cv_mindate, 'dd-MM-yyyy');
  }

  getUserDetails() {
    this.ngxUiService.start();
    this.dataService.getUserData(JSON.parse(localStorage.getItem('ubu_auth_user')).user_id)
      .subscribe(
        (userProfileData: any) => {
          this.userProfileData = userProfileData.data;

          console.log(userProfileData);
          this.haveVaccinated = this.userProfileData.have_covid_vaccine == true ? "1" : "0";
          this.covidInfoForm.patchValue({
            isCVCheck: this.haveVaccinated
          });
          /* if(userProfileData.data.have_covid_vaccine == true){
             this.cvDoseFormVal = true;
           }else{
             this.cvDoseFormVal = false;
           }*/
          if (userProfileData.data.last_blood_donated_date != "") {
            //  console.log(this.userProfileData.last_blood_donated_date);

            this.donatedDate.defaultDate = this.datePipe.transform(this.userProfileData.last_blood_donated_date, 'dd-MM-yyyy');//this.userProfileData.last_blood_donated_date;
            // console.log(this.donatedDate.defaultDate);

          }
          /* if( userProfileData.data.have_covid_vaccine==true ){
             if(this.userProfileData.cv_firstdose_date!=""){
               this.dateTimeFormatConfigDose.defaultDate = this.datePipe.transform(this.userProfileData.cv_firstdose_date, 'dd-MM-yyyy');// this.userProfileData.cv_firstdose_date;
               this.dateTimeFormatConfigDose1.minDate =  this.datePipe.transform(this.userProfileData.cv_firstdose_date, 'dd-MM-yyyy');
             }
 
             if(this.userProfileData.cv_seconddose_date!=""){
               this.dateTimeFormatConfigDose1.defaultDate = this.datePipe.transform(this.userProfileData.cv_seconddose_date, 'dd-MM-yyyy');// this.userProfileData.cv_firstdose_date;
             }
 
           } */

          this.userProfileDetails = userProfileData.data.details;
          this.userMoreDetails = userProfileData.data.more_details;
          this.ngxUiService.stop();
        });
  }


  updateStatus(statusData: any) {
    this.ngxUiService.start();
    let userBasicData = {
      "action": "is_available",
      "is_available": (statusData) ? 1 : 0
    }

    this.manageProfileService.updateProfileData(userBasicData)
      .subscribe(
        response => {
          if (response['status_code'] == 200) {
            this.toastrService.success('', response['success'].message);
            this.getUserDetails();
          } else {
            this.toastrService.warning('', response['error'].message);
          }
          this.ngxUiService.stop();
        }, error => {
          this.toastrService.info('', "Some thing went wrong");
          this.ngxUiService.stop();
        });

  }

  profileInfoUpdated() {
    this.ngxUiService.start();
    this.getUserDetails();
    this.ngxUiService.stop();
  }

  get cowinFormControl() { return this.covidInfoForm.controls; }

  updateCovidInfo(covidProfilePost: any) {
    this.covidInfoForm.clearValidators();
    this.iscovidInfoSubmitted = true;

    this.ngxUiService.start();
    if (covidProfilePost) {
      // this.cv_mindate=  
      let userBasicData = {
        "action": "cowin_info",
        "user_id": JSON.parse(localStorage.getItem('ubu_auth_user')).user_id,
        "have_covid_vaccine": this.cvDoseFormVal ? 1 : 0,
        "cv_dose_one": null,
        "cv_dose_two": null
      }
      console.log(userBasicData);
      // return false;

      this.manageProfileService.updateProfileData(userBasicData)
        .subscribe(
          response => {
            if (response['status_code'] == 200) {
              this.toastrService.success('', response['success'].message);
              this.getUserDetails();
              this.route.routeReuseStrategy.shouldReuseRoute = () => false;
              this.ngxUiService.stop();
            } else {
              this.toastrService.info('', response['error'].message);
            }
            this.ngxUiService.stop();
          }, error => {
            this.toastrService.info('', "Some thing went wrong");
            this.ngxUiService.stop();
          });
    }
  }

}
