import {Component, OnInit, Input, ViewChild, ElementRef, NgZone,Renderer2 } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Validators, ValidatorFn, AbstractControl, FormControl, FormGroup,FormArray, FormBuilder } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import {formatDate } from '@angular/common';
import { BehaviorSubject } from 'rxjs';

import { CowinServiceService } from '../../services/cowin/cowin-service.service';
@Component({
  selector: 'app-appointment-confirmation',
  templateUrl: './appointment-confirmation.component.html',
  styleUrls: ['./appointment-confirmation.component.css']
})
export class AppointmentConfirmationComponent implements OnInit {
  public stateList:any=[];
  public districtList:any=[];
  public centerDataList:any=[];
  public selectStateId:any;
  public centerCount=0;
  public selectDistrictId:any;
  public selectedPin:any;
  public final_dates=[];
  public final_centerData:any;
  @ViewChild('pin_code', { static: false }) public pinElementRef: ElementRef;
  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private toastr: ToastrService,
    private ngxService: NgxUiLoaderService,
    private cowinService: CowinServiceService
  ) {
      this.getDateArray();
   }

  ngOnInit() {
    this.getStates();
   // this.getDateArray();
  }

  getStates() {
    this.ngxService.start();
    this.cowinService.getStateList()
      .subscribe(
        (statesData: any) => {  
          this.stateList = statesData.states;
        //  console.log(this.stateList);
          this.ngxService.stop();
        }); 
  }

  getStateId(event:any) {
    this.selectStateId= event;
    this.districtList= [];
   // this.selectBloodGroup= this.bloodGroupListData.find(c => c.id == event) ;
   //console.log(event);
   this.ngxService.start();
   this.cowinService.getDistricts(event)
     .subscribe(
       (districtsData: any) => {
         this.districtList = districtsData.districts;
      //   console.log(this.districtList);
         this.ngxService.stop();
       }); 
   }
   
  getDistrictId(event:any){
    this.selectDistrictId= event;
    console.log(this.selectDistrictId);
   // this.getCenterData(this.selectDistrictId);
  }

  getCenterData(){
   this.centerDataList =[];
   let ditrictId= this.selectDistrictId;
    if(ditrictId>0){
      this.ngxService.start();
      this.cowinService.getCentersByDistrict(ditrictId)
        .subscribe(
          (centerData: any) => {
            this.centerDataList = centerData.centers;
            this.final_centerData=this.centerDataList;
            this.formatCenterData(this.centerDataList);
            this.centerCount= Object.keys(this.final_centerData).length;
            console.log(this.centerCount);
            console.log(this.centerDataList);
          //  this.centerCount= this.centerDataList.lenght;
            //console.log(this.centerCount);
            this.ngxService.stop();
          });
    }
  }
  getPin() {
    let pinCode= this.pinElementRef.nativeElement.value;
    this.getCenterByPin(pinCode);
    }
  getCenterByPin(pinNum:any){
    //console.log(this.selectDistrictId);
    this.centerDataList =[];
    this.ngxService.start();
   this.cowinService.getCentersByPin(pinNum)
     .subscribe(
       (centerData: any) => {
        this.centerDataList = centerData.centers;
        console.log(this.centerDataList);
        this.final_centerData=this.centerDataList;
        this.centerCount= Object.keys(this.final_centerData).length;
        console.log(this.centerCount);
         this.ngxService.stop();
       });

   }
  // const groupBy = (x, f) => x.reduce((a, b) => ((a[f(b)] ||= []).push(b), a), {});
  groupByArr(arr, prop) {
    const map = new Map(Array.from(arr, obj => [obj[prop], []]));
    arr.forEach(obj => map.get(obj[prop]).push(obj));
    return Array.from(map.values());
}

  formatCenterData(data:any){
        data.forEach((item, index) => {
         });
      this.final_centerData= data;
  }
  
  /* --------- array restruct --- */
  assignprev(array1:any, array2:any){
      array2.forEach((item, index) => {
        let merged:any[];
      array1.forEach(x=> {
       if(item.date == x.dt){
          merged.push(item);
       }else{
        merged.push("NA");
       }
      });
      item['get_sessions']=merged;   
     });
     return array2;
   }

  getDateArray () {
    let selectedWeekDates: Date[] = new Array(7).fill(new Date('dd-MM-yyyy'));
    let selectedWeek: Date = new Date();
    let dte: Date = new Date(selectedWeek);  
    selectedWeekDates[0]= new Date(dte.setDate(dte.getDate() + 0));      
      for (let i = 2; i < 8; i++) {
        selectedWeekDates[i-1]= new Date(dte.setDate(dte.getDate() + 1));  
    }
   // let final_dates=[];
    let sess_obj= selectedWeekDates;
    sess_obj.forEach(dt => {
      this.final_dates.push({"dt": formatDate(dt, 'dd-MM-yyyy', 'en-US', '+0530')});
    });
   
  }

  /* ---------------  Filters -------------  */

public filterArray=[];
public isChecked$ = new BehaviorSubject(false);

toggleChecked(item:any) {
 // this.isChecked$.next(!this.isChecked$.value);
 // console.log(this.isChecked$.value);
 // console.log(item);
  // if(this.isChecked$.value){
  //  // this.filterArray.push({"min_age_limit": 18});
  //  var filter = [45];
  // this.centerDataList.map(result=>{

  //   result.sessions = result.sessions.filter(age=>(filter.includes(age.min_age_limit)))
  //   return result;
  // })

  // this.centerDataList = this.centerDataList.filter(function (currentElement) {
  //   // the current value is an object, so you can check on its properties
  //   return currentElement.fee_type === "Paid" || currentElement.fee_type === "Free";
  // });

  //}
 
  
  
 // console.log(this.centerDataList);
  //if(!this.isChecked$.value){
   // var filter = [0];
  //this.centerDataList.map(result=>{
   // result.sessions = result.sessions;
   // return result;
 // })
  //}

  
 // console.log( this.filterArray);
 const filters = {
  fee_type: [],
  vaccine :['COVISHIELD']

};
 let data=this.centerDataList;
 //this.centerDataList= this.multiFilter(this.centerDataList,filters);
 //this.final_centerData= this.filter_function(data,filters);
 this.final_centerData= this.filter_function(data, 'vaccine');
console.log( this.final_centerData);


  
}
// multiFilter (arr, filters)  {
//   const filterKeys = Object.keys(filters);

//   return arr.filter(eachObj => {
//       return filterKeys.every(eachKey => {

//           if (!filters[eachKey].length) {
//               return true;
//           }

//           if (eachObj[eachKey].id) {
//               return filters[eachKey].includes(eachObj[eachKey].vaccine );
//           } else {
//               return filters[eachKey].some(f => eachObj[eachKey].some(v => v.vaccine === f));
//           }
//       });
//   });
// }
filter_fun(array,filters){
  const filterKeys = Object.keys(filters);
  return array.filter(item => {
    // validates all filter criteria
    return filterKeys.every(key => {
      // ignores non-function predicates
      if (typeof filters[key] !== 'function') return true;
      return filters[key](item[key]);
    });
  });
}

filter_function (data,keyToFind){

 return data.find(product => product.sessions.some(item => item.vaccine === 'COVISHIELD'));
  
    //return data.filter(eachVal => {
    //let opt = eachVal.sessions({
     

    //});
    

  //});

  

//  return data.filter((arr) => {
    
  //  return Object.keys(keep_these_data).some((key) => {
      // if (key === 'fee_type'){
      //   return keep_these_data.fee_type.some((fee_type) => fee_type === arr.fee_type);
      // }
      // if (key === 'fee_type'){
       
      //     return keep_these_data.fee_type.some((fee_type) => fee_type === arr.fee_type);
        
      //   //return keep_these_data.fee_type.some((fee_type) => fee_type === arr.fee_type);
      // }
    //  if(key === 'vaccine'){
      //  console.log(arr.sessions);

      //return arr.sessions.filter((sess)=>{
        //return keep_these_data.vaccine.some((vaccine) => vaccine === sess.vaccine);

      //})

      //}

    //})
  //})
  
}
 
  onItemage(item:any){
   // console.log(item);
   if(item=="rad1"){
   // this.filterArray.push({"min_age_limit": 30});
   }else if(item=="rad2"){
    //this.filterArray.push({"min_age_limit": 45});
  //  this.centerDataList= this.centerDataList.filter(obj => (obj['sessions']['min_age_limit'] == 45));
    //console.log(this.centerDataList);
    //this.centerCount= Object.keys(this.centerDataList).length;
  // let searchText = 45;
    //return (searchText) => 
    //this.centerDataList.filter(item => item.sessions.min_age.limit.toLowerCase().includes(searchText.toLowerCase()));

  //  const r = data.filter(d => d.courses.every(c => courses.includes(c.id)));
console.log(item);
var filter = [45];
console.log(this.centerDataList.map(result=>{
      result.sessions = result.sessions.filter(age=>(filter.includes(age.min_age_limit)))
      return result
    }))
   }

  }
}
