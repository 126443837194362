import { Component, OnInit } from "@angular/core";
import * as Hammer from "hammerjs";
//  import { PageFlip } from 'page-flip';

declare var $: any; // Declare jQuery for use in the component
@Component({
  selector: "app-founder",
  templateUrl: "./founder.component.html",
  styleUrls: ["./founder.component.css"],
})
export class FounderComponent implements OnInit {
  constructor() {}

  ngOnInit() {
    $(document).ready(function () {
      // DUE TO THE LACK OF THE COMPACTABILITY PLUG-IN FOR BOTH MOBILE AND DESKTOP RESOLUTIONS, WE HAD TO ADD TWO PLUGINS FOR THE CAROUSEL EFFECT.

      // BELLOW CODE FOR MOBILE RESOLUTION
      $(".carousel").on("slid.bs.carousel", function () {
        var carouselData = $(this).data("bs.carousel"); // Get carousel data
        var currentIndex = carouselData.getItemIndex(
          carouselData.$element.find(".item.active") // Find the active item
        );
        var totalItems = carouselData.$items.length; // Get total number of items

        // Show/hide the left control based on the current index
        if (currentIndex === 0) {
          $(".left.carousel-control").hide();
        } else {
          $(".left.carousel-control").show();
        }

        // Show/hide the right control based on the current index
        if (currentIndex === totalItems - 1) {
          $(".right.carousel-control").hide();
        } else {
          $(".right.carousel-control").show();
        }
      });
    });

    //   const pageFlip = new PageFlip(document.getElementById('book'), {
    //     width: 400,
    //     height: 600,
    // });

    // pageFlip.loadFromHTML(document.querySelectorAll('.my-page'));
    // Initialize carousel with no wrap around
    $(".carousel").carousel({
      wrap: false,
    });
    var currentPage = 0; // Initialize current page variable

    // BELLOW CODE FOR DESKTOP RESOLUTION
    // EVENT LISTENERS FOR BOOK PAGE NAVIGATION
    $(".book")
      .on("click", ".active1", nextPage)
      .on("click", ".flipped", prevPage);

    var hammertime = new Hammer($(".book")[0]);
    hammertime.on("swipeleft", nextPage);
    hammertime.on("swiperight", prevPage);

    // FUNCTION TO NAVIGATE TO THE PREVIOUS PAGE
    function prevPage() {
      $(".flipped")
        .last()
        .removeClass("flipped")
        .addClass("active1")
        .siblings(".page")
        .removeClass("active1");
      if ($(".cover_head").hasClass("flipped")) {
        $(".desktop_book").addClass("thankyou-page");
      } else {
        $(".desktop_book").removeClass("thankyou-page");
      }

      if ($(".cover_page_head ").hasClass("active1")) {
        $(".desktop_book").addClass("cover-page-set");
      } else {
        $(".desktop_book").removeClass("cover-page-set");
      }
    }
    // FUNCTION TO NAVIGATE TO THE NEXT PAGE
    function nextPage() {
      $(".active1")
        .removeClass("active1")
        .addClass("flipped")
        .next(".page")
        .addClass("active1")
        .siblings();
      if ($(".cover_head").hasClass("flipped")) {
        $(".desktop_book").addClass("thankyou-page");
      } else {
        $(".desktop_book").removeClass("thankyou-page");
      }

      if ($(".cover_page_head ").hasClass("active1")) {
        $(".desktop_book").addClass("cover-page-set");
      } else {
        $(".desktop_book").removeClass("cover-page-set");
      }
    }
  }
}
