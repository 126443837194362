import { Component, OnInit, EventEmitter, Output, Input} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { ToastrService } from 'ngx-toastr'; 
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { BehaviorSubject } from 'rxjs';
import {Ubrequest} from '../../../models/request.model';
import { AuthenticationService } from '../../../services/authentication/authentication.service';
declare var $: any;


@Component({
  selector: 'app-authenticate-form',
  templateUrl: './authenticate-form.component.html',
  styleUrls: ['./authenticate-form.component.css']
})
export class AuthenticateFormComponent implements OnInit {
  @Input() request_data:Ubrequest;
  ubAuthForm: FormGroup;
  isAuthFormSubmitted:boolean = false;
  phone_number:number;
  otp_timer_start:boolean =false;
  
  

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private authentication: AuthenticationService,
    private toastrService: ToastrService,
    private ngxUIService: NgxUiLoaderService) { }

  ngOnInit() {      
    this.ubAuthForm = this.formBuilder.group({
      uba_std_code: [Validators.required, Validators.min(1)],
      //uba_phone_number: ['',[Validators.required, Validators.pattern(/^(?:\d{10}|\w+@\w+\.\w{2,3})$/)]],    
      uba_phone_number: ['',[Validators.required, Validators.pattern(/^[6-9]\d{9}$/)]],    
     
    });

    this.ubAuthForm.controls['uba_std_code'].setValue('+91');  
  }

  get ubAuthFormControl() { return this.ubAuthForm.controls; }

  authenticateUser(post: any) {    
    this.request_data.request_phone = post['uba_phone_number'];
    this.isAuthFormSubmitted = true;
    if (this.ubAuthForm.invalid) {
       this.toastrService.info('', "Please enter valid phone number",{
        tapToDismiss: false,
        positionClass:'toast-top-right',
      
      });
       return false;
    }
    if (post) {
      const authUserData = {
        "action": "sendotp",
        "std_code": post['uba_std_code'], 
        "phone": post['uba_phone_number'],        
      };      
      this.ngxUIService.start();
      this.authentication.ubuAuthenticate(authUserData)
        .subscribe(
          response => {
            if (response['status_code'] == 200) {
              this.ngxUIService.stop();
              this.toastrService.success('', response['success']['message'],{
                tapToDismiss: false,
                positionClass:'toast-top-right',
              
              });
              this.phone_number = post['uba_phone_number'];
              this.request_data.request_phone = post['uba_phone_number'];
              $('#authenticateModalForm').modal('hide');
              $('#otpModalForm').modal('show');
              this.otp_timer_start =true;             
            }
          }, error => {
            this.ngxUIService.stop();
            this.toastrService.info('', "Some thing went wrong",{
              tapToDismiss: false,
              positionClass:'toast-top-right',
            
            });
          });
    } else {
      this.toastrService.info('', "Please enter mobile number",{
        tapToDismiss: false,
        positionClass:'toast-top-right',
      
      });
    }

  }



}
