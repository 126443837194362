import { Component, OnInit, Input, ViewChild, OnChanges, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { BehaviorSubject, interval, of, timer } from 'rxjs';
import 'rxjs/add/operator/filter';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthenticationService } from '../../services/authentication/authentication.service';

@Component({
  selector: 'app-verify-user',
  templateUrl: './verify-user.component.html',
  styleUrls: ['./verify-user.component.css']
})
export class VerifyUserComponent implements OnInit {
  isUsaverVerified:boolean;
  constructor( private route:ActivatedRoute,  private toastrService: ToastrService,
    private ngxUIService: NgxUiLoaderService,    private authentication: AuthenticationService,
    private router: Router) { }

  ngOnInit() {

    this.route.params.subscribe(params => {    
     if(params.verify_token){
      this.ngxUIService.start();
      const verifyUserData = {
        "verify_token": params.verify_token,     
      };
      this.ngxUIService.start();
      this.authentication.verifyUsaver(verifyUserData)
        .subscribe(
          response => {
            if (response['status_code'] == 200) {
              this.ngxUIService.stop();
              this.isUsaverVerified= true; 
                 
            }
            if (response['status_code'] == 201) {
              this.ngxUIService.stop();
              this.isUsaverVerified= false;
              this.toastrService.info('', response['error']['message']);
            }
            if(response['status_code'] == 202){
              this.ngxUIService.stop();
              this.isUsaverVerified= false;
              this.toastrService.info('', response['error']['message']);
            }
          }, error => {
            this.ngxUIService.stop();
            this.isUsaverVerified= false;
            this.toastrService.info('', "Some thing went wrong");
          });
    } 

    });
  }

}
