import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LayoutComponent } from './components/layout/layout.component';
import { LandingPageComponent } from './components/landing-page/landing-page.component';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { AboutUsComponent } from './pages/misc/about-us/about-us.component';
import { ContactUsComponent } from './pages/misc/contact-us/contact-us.component';
import { FeedbackComponent } from './pages/misc/feedback/feedback.component';
import { PrivacyPolicyComponent } from './pages/misc/privacy-policy/privacy-policy.component';
import { TermsOfServiceComponent } from './pages/misc/terms-of-service/terms-of-service.component';
import { LayoutWithHeaderComponent } from './components/layout-with-header/layout-with-header.component';
import { LayoutMobileHeaderComponent } from './components/layout-mobile-header/layout-mobile-header.component';
import { MapPageComponent } from './components/map-page/map-page.component';
import { Covid19StatsComponent } from './components/covid19-stats/covid19-stats.component';
import { VerifyUserComponent } from './components/verify-user/verify-user.component';

import { LoginComponent } from './pages/login/login.component';
import { VerifyComponent } from './pages/verify/verify.component';
import { CovidDonorComponent } from './pages/covid/covid-donor/covid-donor.component';
import { CovidRequestComponent } from './pages/covid/covid-request/covid-request.component';

import { HomePageComponent } from './pages/home/home-page/home-page.component'
import { MessageComponent } from './pages/message/message.component';
import { DonorHomeComponent } from './pages/home/blood/donor/donor-home/donor-home.component';
import { RequestHomeComponent } from './pages/home/blood/request/request-home/request-home.component';
import { RequestBloodComponent } from './pages/blood/request/request-blood/request-blood.component';
import { DonorSignUpComponent } from './pages/blood/donor/donor-sign-up/donor-sign-up.component';
import { UsaverHomeComponent } from './pages/home/usaver-home/usaver-home.component';

import { InfluencerComponent } from './pages/influencer/influencer.component';
import { CovidInfoComponent } from './pages/mobile/info/covid-info/covid-info.component';
import { VideogalleryComponent } from './pages/videogallery/videogallery.component';
import { ContributionsComponent } from './pages/contributions/contributions.component';
import { ContributionformComponent } from './pages/contributionform/contributionform.component';
import { SharebloodComponent } from './pages/shareblood/shareblood.component';
import { PartnerHomePageComponent } from './pages/home/partner-home-page/partner-home-page.component';
import { RequestmapviewComponent } from './pages/requestmapview/requestmapview.component';
import { UsaverFeedbackComponent } from './pages/usaver-feedback/usaver-feedback.component';
import { FounderComponent } from './pages/founder/founder.component';
import { IndiaMapComponent } from './pages/india-map/india-map.component';
import { AdvisorComponent } from './pages/advisor/advisor.component';
const sharedRoutes: Routes = [
  {
    path: '', component: LayoutComponent,

    children: [

      { path: '', component: HomePageComponent, pathMatch: 'full' },
      // { path: 'login', component: LoginComponent, pathMatch: 'full' },
      { path: 'blood/become-a-donor', component: DonorHomeComponent, pathMatch: 'full' },
      { path: 'blood/find-blood-donors', component: RequestHomeComponent, pathMatch: 'full' },
      { path: 'blood/donor-signup', component: DonorSignUpComponent, pathMatch: 'full' },
      { path: 'blood/donor-signup', component: DonorSignUpComponent, pathMatch: 'full' },
      { path: 'blood/blood-request', component: RequestBloodComponent, pathMatch: 'full' },
      { path: 'covid/donor-signup', component: CovidDonorComponent, pathMatch: 'full' },
      { path: 'covid/plasma-request', component: CovidRequestComponent, pathMatch: 'full' },
      { path: 'usaver', component: UsaverHomeComponent, pathMatch: 'full' },

      { path: 'ubd-message', component: MessageComponent, pathMatch: 'full' },
      { path: 'ubr-message', component: MessageComponent, pathMatch: 'full' },
      { path: 'verify-usaver/:verify_token', component: VerifyUserComponent },
      { path: 'page-not-found', component: PageNotFoundComponent },
      { path: 'blood/donor-signup?referral-code=', component: DonorSignUpComponent, pathMatch: 'full' },
      { path: 'influencer', component: InfluencerComponent, pathMatch: 'full' },
      { path: 'influencer/video-code:', component: InfluencerComponent, pathMatch: 'full' },
      { path: 'video-gallery', component: VideogalleryComponent, pathMatch: 'full' },
      { path: 'contribution', component: ContributionsComponent, pathMatch: 'full' },
      { path: 'contribution-form', component: ContributionformComponent, pathMatch: 'full' },
      { path: 'request-map-view/:reqid', component: RequestmapviewComponent, pathMatch: 'full' },
      { path: 'share/:info/:ubr', component: SharebloodComponent, pathMatch: 'full' },
      { path: 'scsc', component: PartnerHomePageComponent, pathMatch: 'full' },
      { path: 'usaver-feedback', component: UsaverFeedbackComponent, pathMatch: 'full' },
   
      

    ]
  },
  {
    path: 'misc', component: LayoutWithHeaderComponent,
    children: [
      { path: 'about-us', component: AboutUsComponent, pathMatch: 'full' },
      { path: 'founder', component: FounderComponent, pathMatch: 'full' },
      { path: 'map', component: IndiaMapComponent, pathMatch: 'full' },
      { path: 'contact-us', component: ContactUsComponent, pathMatch: 'full' },
      { path: 'terms-of-service', component: TermsOfServiceComponent, pathMatch: 'full' },
      { path: 'privacy-policy', component: PrivacyPolicyComponent, pathMatch: 'full' },
      { path: 'advisor', component: AdvisorComponent, pathMatch: 'full' }
      
    ]
  },
  {
    path: 'm', component: LayoutMobileHeaderComponent,
    children: [
      { path: 'about-us', component: AboutUsComponent },
      { path: 'founder', component: FounderComponent, pathMatch: 'full' },
      { path: 'contact-us', component: ContactUsComponent },
      { path: 'terms-of-service', component: TermsOfServiceComponent },
      { path: 'privacy-policy', component: PrivacyPolicyComponent },
      { path: 'info/covid-19', component: CovidInfoComponent },
      { path: 'advisor', component: AdvisorComponent, pathMatch: 'full' }
    ]
  }


];

@NgModule({
  imports: [RouterModule.forChild(sharedRoutes)],
  exports: [RouterModule]
})
export class SharedRoutingModule { }
