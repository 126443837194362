   import { Component, OnInit } from '@angular/core';
import { connectableObservableDescriptor } from 'rxjs/internal/observable/ConnectableObservable';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Router, ActivatedRoute } from '@angular/router';
import { DataService } from '../../services/dataService/data.service';
//import { EmbedVideoService } from 'ngx-embed-video';

@Component({
  selector: 'app-influencer',
  templateUrl: './influencer.component.html',
  styleUrls: ['./influencer.component.css']
})
export class InfluencerComponent implements OnInit {
ubinfluencer:any;
videoURL:any;
videoCode:any;
yt_iframe_html: any;
  constructor(
    private dataService: DataService   ,
    private toastrService: ToastrService,
    private ngxUiLoaderService: NgxUiLoaderService,
    private router: Router,
    private route: ActivatedRoute,
   // private embedService: EmbedVideoService
  ){
    this.route.queryParams.subscribe(params => {
      this.videoCode =  params['video-code'];
    // console.log(this.videoCode);
    
    });  

  }

  ngOnInit() {
    if(this.videoCode){
      // this.checkRefercode(this.videoCode,'url');
      this.getInfluencers(this.videoCode);
    }else{
      this.videoCode='hY8CqLp'
      this.getInfluencers(this.videoCode);
    }
     
  }

  getInfluencers(videoCode){
    this.ngxUiLoaderService.start();
    this.dataService.geInfluencerDataList()
      .subscribe(
        dataList => {
          this.ubinfluencer = dataList.data.influencers;
          //console.log(this.ubinfluencer);
          for (let videos of this.ubinfluencer) {
            if (videos.video_code ==this.videoCode) {
                   this.videoURL= videos.video_url;
             }
          }
          this.ngxUiLoaderService.stop();
        });
  }

}
